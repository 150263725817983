<template>
  <div>
    <div class="el-header-box" v-if="!isMobile()">
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="el-flex">
            <div class="el-header-logo el-align-center">
              <img
                class="el-header-img-box"
                src="https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/logo.png"
                alt=""
              />
            </div>
            <div class="el-nav-box el-flex">
              <div
                class="el-nav-items"
                :class="
                  hoverCurrent == index || current == index
                    ? 'nav-active-color'
                    : ''
                "
                v-for="(item, index) in menuList"
                :key="index"
                @mouseenter="getHoverCurrent(index)"
                @mouseleave="leave"
                @click="getCurrent(index)"
              >
                {{ item }}
                <div
                  :class="
                    hoverCurrent == index || current == index
                      ? 'nav-active'
                      : ''
                  "
                ></div>
              </div>
            </div>
          </div>
          <div
            class="el-demo-box"
            @click="toHref('https://docs.foxonline.net/')"
          >
            文档中心
          </div>
        </div>
      </div>
      <div
        class="el-help-box product-active"
        v-show="helpShow"
        @mouseenter="contrlShow('help')"
        @mouseleave="closeShow('help')"
      >
        <div class="el-content-width el-flex">
          <div
            class="el-flex-column el-help-items"
            v-for="(item, index) in helpList"
            :key="index"
            @click="toHelpPage(index)"
          >
            <img class="icon-width-help" :src="item.icon" alt="" />
            <span class="icon-font">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div
        class="el-product-box"
        :class="productShow ? ' product-active' : 'product-leave'"
        v-if="productShow"
        @mouseenter="contrlShow('product')"
        @mouseleave="closeShow('product')"
      >
        <div class="el-content-width el-flex">
          <div
            class="el-prodect-item"
            v-for="(item, index) in productList"
            :key="index"
          >
            <ul>
              <li>
                <div style="padding-left: 24px">{{ item.name }}</div>
              </li>
              <li
                class="li-hover"
                v-for="(items, idx) in item.items"
                :key="idx"
                @click="toProduct(index, idx)"
                @mouseenter="getProCurrent(items.id)"
              >
                <div
                  class="el-align-center"
                  style="position: relative; padding-left: 20px"
                >
                  <img
                    class="icon-width"
                    :src="items.icon"
                    alt=""
                    :class="proIdx == items.id ? 'shake-center' : ''"
                  />
                  <span class="icon-font">{{ items.name }}</span>
                  <img
                    v-if="items.tip == 'hot'"
                    :class="proIdx == items.id ? 'shake-center' : ''"
                    class="pro-tip"
                    src="../../assets/common/pro_hot.png"
                    alt=""
                  />
                  <img
                    v-if="items.tip == 'open'"
                    class="pro-tip"
                    :class="proIdx == items.id ? 'shake-center' : ''"
                    style="margin-bottom: 0"
                    src="../../assets/common/pro_open.png"
                    alt=""
                  />
                  <img
                    v-if="items.tip == 'new'"
                    style="height: 26px; margin-bottom: 25px"
                    class="pro-tip"
                    :class="proIdx == items.id ? 'shake-center' : ''"
                    src="../../assets/common/pro_new.png"
                    alt=""
                  />
                </div>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mob-content" v-else>
      <div class="mob-header-box">
        <div class="el-flex-between el-align-center">
          <div class="el-align-center">
            <img
              src="https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/logo.png"
              alt=""
            />
          </div>
          <div class="el-align-center">
            <div
              class="mob-el-demo-box"
              @click="toHref('https://docs.foxonline.net/')"
            >
              文档中心
            </div>
            <i class="el-icon-more" @click="drawer = true"></i>
          </div>
        </div>
        <div class="mob-nav-box">
          <ul class="el-flex-between">
            <li
              v-for="(item, index) in mobNavList"
              :key="index"
              @click="getMobCurrent(index)"
              :class="mobCurrent == index ? 'mob-nav-li-active' : ''"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mob-el-product-nav" v-if="mobCurrent == 1">
        <ul class="el-flex-between">
          <li
            :class="selectIndex == index ? 'li-active' : ''"
            v-for="(item, index) in productList"
            :key="index"
            @click="getSelectList(item.items, index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="el-flex-cloumn product-list">
          <p
            v-for="(item, index) in selectList"
            :key="index"
            @click="toMobProduct(index)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <el-drawer
        title="我是标题"
        direction="rtl"
        :visible.sync="drawer"
        :with-header="false"
        size="38%"
      >
        <div class="drawer-box">
          <div
            class=""
            v-for="(item, index) in cnavList"
            :key="index"
            @click="getIndex(item, index)"
          >
            <h3 :class="mobCurrent == index ? 'mobile-cnav-clicked' : ''">
              {{ item.name }}
            </h3>
            <ul class="el-drawer-ul" v-if="item.proList">
              <li
                :class="proCurrent == idx ? 'mobile-cnav-clicked' : ''"
                v-for="(items, idx) in item.proList"
                :key="idx"
                @click="toProNav(items, idx)"
              >
                {{ items.name }}
              </li>
            </ul>
          </div>
        </div>
      </el-drawer>
    </div>
    <div style="height: 80px; width: 100%"></div>
  </div>
</template>
  
  <script>
export default {
  name: "LayoutHeader",
  props: {
    num: { type: Number, required: 0 },
    mobNum: { type: Number, required: 0 },
  },
  data() {
    return {
      menuList: ["首页", "产品中心", "客户案例", "行业资讯", "帮助与支持"],
      current: -1,
      hoverCurrent: 0,
      productShow: false,
      helpShow: false,
      cnavList: [
        { name: "首页", href: "/" },
        {
          name: "产品中心",
          proList: [
            {
              name: "零售商城系统",
              href: "/mall",
            },
            {
              name: "会员美业系统",
              href: "/vip",
            },
            {
              name: "生鲜商城系统",
              href: "/fresh",
            },
            {
              name: "社区团购系统",
              href: "/storeGroup",
            },
            {
              name: "外卖跑腿系统",
              href: "/wm",
            },
            {
              name: "同城信息发布",
              href: "/cityProduct",
            },
            {
              name: "招聘平台",
              href: "/job",
            },
            {
              name: "到家服务平台",
              href: "/cityHome",
            },
            {
              name: "企业CRM系统",
              href: "/crm",
            },
            {
              name: "进销存系统",
              href: "/jxc",
            },
						{
							name: "办公OA系统",
							href: "/oa",
						},
            {
              name: "财务管理系统",
              href: "/fc",
            },
						{
							name: "SAAS服务",
							href: "/service",
						},
          ],
        },
        { name: "行业资讯", href: "/tradeInfo" },
        { name: "关于我们", href: "/aboutUs" },
      ],
      mobNavList: ["首页", "产品中心", "行业资讯", "关于我们"],
      mobCurrent: 0,
      helpList: [
        {
          name: "功能列表",
          icon: require("../../assets/icon/f_function.png"),
        },
        {
          name: "更新日志",
          icon: require("../../assets/icon/f_updatelog.png"),
        },
        {
          name: "关于我们",
          icon: require("../../assets/icon/f_aboutus.png"),
        },
        {
          name: "常见问题",
          icon: require("../../assets/icon/f_question.png"),
        },
        {
          name: "知识库",
          icon: require("../../assets/icon/f_bookbase.png"),
        },
      ],
      drawer: false,
      productList: [
        {
          name: "商城系统",
          items: [
            {
              id: 1,
              icon: require("../../assets/icon/store_retail.png"),
              name: "零售商城系统",
              tip: "hot",
            },
            {
              id: 2,
              icon: require("../../assets/icon/store_vipcard.png"),
              name: "美业会员系统",
              tip: "hot",
            },
            {
              id: 3,
              icon: require("../../assets/icon/store_fresh.png"),
              name: "生鲜商城系统",
            },
            {
              id: 4,
              icon: require("../../assets/icon/store_group.png"),
              name: "社区团购系统",
            },
          ],
        },
        {
          name: "同城系统",
          items: [
            {
              id: 5,
              icon: require("../../assets/icon/waimai_deliver.png"),
              name: "外卖跑腿",
              tip: "hot",
            },
						{
							id: 6,
							icon: require("../../assets/icon/city_house.png"),
							name: "到家服务",
							tip: "new",
						},
            {
              id: 7,
              icon: require("../../assets/icon/city_send.png"),
              name: "信息发布",
              tip: "",
            },
            {
              id: 8,
              icon: require("../../assets/icon/city_recruit.png"),
              name: "同城招聘",
              tip: "new",
            },
          ],
        },
        {
          name: "企业系统",
          items: [
            {
              id: 9,
              icon: require("../../assets/icon/com_enterprise.png"),
              name: "CRM系统",
              tip: "hot",
            },
            {
              id: 10,
              icon: require("../../assets/icon/com_invoice.png"),
              name: "进销存系统",
            },
            {
              id: 11,
              icon: require("../../assets/icon/com_work.png"),
              name: "办公OA",
              tip: "open",
            },
            {
              id: 12,
              icon: require("../../assets/icon/com_finance.png"),
              name: "财务系统",
              tip: "open",
            },
          ],
        },
        {
          name: "服务",
          items: [
            {
              id: 13,
              icon: require("../../assets/icon/serve_1.png"),
              name: "SAAS租用",
              tip: "new",
            },
            {
              id: 14,
              icon: require("../../assets/icon/serve_2.png"),
              name: "品牌定制",
            },
            {
              id: 15,
              icon: require("../../assets/icon/serve_3.png"),
              name: "功能定制",
            },
            {
              id: 16,
              icon: require("../../assets/icon/serve_4.png"),
              name: "独立部署",
              tip: "hot",
            },
          ],
        },
      ],
      selectList: [
        {
          icon: require("../../assets/icon/store_retail.png"),
          name: "零售商城系统",
        },
        {
          icon: require("../../assets/icon/store_vipcard.png"),
          name: "美业会员系统",
        },
        {
          icon: require("../../assets/icon/store_fresh.png"),
          name: "生鲜商城系统",
        },
        {
          icon: require("../../assets/icon/store_group.png"),
          name: "社区团购系统",
        },
      ],
      selectIndex: 0,
      proCurrent: 0,
      proIdx: 0,
    };
  },
  mounted() {
    this.hoverCurrent = this.num;
    this.mobCurrent = this.mobNum;
    this.proCurrent = this.$route.query.proIndex;
    document.addEventListener("visibilitychange", this.visibilityState); //监听页面显隐
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.visibilityState); //移除监听
  },
  methods: {
    toHref(href) {
      window.open(href);
    },
    visibilityState(e) {
      let visibilityState = e.target.visibilityState;
      switch (visibilityState) {
        case "visible": //显示
          this.hoverCurrent = this.num;
          console.log(this.current);
          break;
        case "hidden": //隐藏
          break;
        default:
      }
    },
    getHoverCurrent(index) {
      this.hoverCurrent = index;
      this.productShow = index == 1 ? true : false;
      this.helpShow = index == 4 ? true : false;
    },
    getProCurrent(id) {
      this.proIdx = id;
    },
    leave() {
      this.hoverCurrent = this.current;
    },
    toNavgetor(href) {
      this.$router.push({ path: href });
    },
    toHelpPage(index) {
      if (index == 0) {
        this.$router.push({ path: "/functionList" });
      } else if (index == 1) {
        this.$router.push({ path: "/updateLog" });
      } else if (index == 2) {
        this.$router.push({ path: "/aboutUs" });
      } else if (index == 3) {
        this.$router.push({ path: "/qustionPage" });
      } else if (index == 4) {
        this.$router.push({ path: "/tradeInfo", query: { isAll: 1 } });
      }
    },
    getCurrent(index) {
      this.current = index;
      if (index == 0) {
        this.$router.push({ path: "/webHome" });
      } else if (index == 2) {
        this.$router.push({ path: "/customerCase" });
      } else if (index == 3) {
        this.$router.push({ path: "/tradeInfo" });
      }
    },
    toProduct(index, idx) {
      if (index == 0) {
        if (idx == 0) {
          this.$router.push({ path: "/mall", query: { index: idx } });
        } else if (idx == 1) {
          this.$router.push({ path: "/vip", query: { index: idx } });
        } else if (idx == 2) {
          this.$router.push({ path: "/fresh", query: { index: idx } });
        } else if (idx == 3) {
          this.$router.push({ path: "/group", query: { index: idx } });
        }
      } else if (index == 1) {
        if (idx == 0) {
          this.$router.push({
            path: "/wm",
            query: { index: idx },
          });
        } else if (idx == 1) {
          this.$router.push({ path: "/cityHome", query: { index: idx } });
        } else if (idx == 2) {
          this.$router.push({ path: "/cityProduct", query: { index: idx } });
        } else if (idx == 3) {
          this.$router.push({ path: "/job", query: { index: idx } });
        }
      } else if (index == 2) {
        if (idx == 0) {
          this.$router.push({ path: "/crm" });
        } else if (idx == 1) {
          this.$router.push({ path: "/jxc" });
        } else if (idx == 2) {
          this.$router.push({ path: "/oa" });
        } else if (idx == 3) {
          this.$router.push({ path: "/fc" });
        }
      } else if (index == 3) {
        this.$emit("scroll", idx);
        this.$router.push({ path: "/service" });
      }
    },
    getMobCurrent(index) {
      this.mobCurrent = index;
      if (index == 0) {
        this.$router.push({ path: "/webHome" });
      } else if (index == 2) {
        this.$router.push({ path: "/tradeInfo" });
      } else if (index == 3) {
        this.$router.push({ path: "/aboutUs" });
      }
    },
    contrlShow(value) {
      if (value == "product") {
        this.productShow = true;
        this.hoverCurrent = 1;
      } else {
        this.helpShow = true;
        this.hoverCurrent = 4;
      }
    },
    closeShow(value) {
      if (value == "product") {
        this.hoverCurrent = this.current;
        this.productShow = false;
      } else {
        this.hoverCurrent = this.current;
        this.helpShow = false;
      }
    },
    getSelectList(list, index) {
      this.selectList = list;
      this.selectIndex = index;
    },
    toMobProduct(idx) {
      if (this.selectIndex == 0) {
        if (idx == 0) {
          this.$router.push({ path: "/mall", query: { index: idx } });
        } else if (idx == 1) {
          this.$router.push({ path: "/vip", query: { index: idx } });
        } else if (idx == 2) {
          this.$router.push({ path: "/fresh", query: { index: idx } });
        } else if (idx == 3) {
          this.$router.push({ path: "/group", query: { index: idx } });
        }
      } else if (this.selectIndex == 1) {
        if (idx == 0) {
          this.$router.push({
            path: "/wm",
            query: { index: idx },
          });
        } else if (idx == 1) {
          this.$router.push({ path: "/cityHome", query: { index: idx } });
        } else if (idx == 2) {
          this.$router.push({ path: "/cityProduct", query: { index: idx } });
        } else if (idx == 3) {
          this.$router.push({ path: "/job", query: { index: idx } });
        }
      } else if (this.selectIndex == 2) {
        if (idx == 0) {
          this.$router.push({ path: "/crm" });
        } else if (idx == 1) {
          this.$router.push({ path: "/jxc" });
        } else if (idx == 2) {
          this.$router.push({ path: "/oa" });
        } else if (idx == 3) {
          this.$router.push({ path: "/fc" });
        }
      } else if (this.selectIndex == 3) {
        this.$emit("scroll", idx);
        this.$router.push({ path: "/servicePage" });
      }
    },
    getIndex(item, index) {
      if (index != 1) {
        this.toNavgetor(item.href);
      }
    },
    toProNav(items, idx) {
      this.$router.push({ path: items.href, query: { proIndex: idx } });
    },
  },
};
</script>
  
  <style scoped>
.el-header-box {
  position: fixed;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 999;
  font-weight: normal;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
  cursor: pointer;
}
.mob-header-box {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 5px 15px;
  line-height: 50px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
  z-index: 999;
}
.mob-header-box img {
  width: 110px;
  height: 34px;
}
.mob-header-box h1 {
  font-size: 20px;
}
.mob-nav-box ul {
  height: 50px;
  background-color: #fff;
  padding: 5px 0;
}
.mob-nav-box li {
  width: 70px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
}
.li-active {
  color: #f08519;
}
.mob-nav-li-active {
  color: #f08519;
  background-color: #ffeeddfa;
  border-radius: 25px;
}

.mob-el-product-nav {
  position: fixed;
  top: 80px;
  background-color: #ffffff;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
  /* padding: 0 15px; */
}
.mob-el-product-nav ul {
  margin-top: 10px;
  padding: 10px 25px;
  border-top: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
}
.mob-el-demo-box {
  width: 75px;
  height: 26px;
  line-height: 26px;
  border-radius: 25px;
  border: #fcac5b 2px solid;
  margin: 0 5px;
  color: #f08519;
  font-size: 13px;
}
.product-list {
  padding: 5px 0;
}
.product-list p {
  margin: 8px 0;
}
.el-header-logo {
  position: relative;
  height: 80px;
  line-height: 80px;
  width: 200px;
}
.el-header-img-box {
  position: absolute;
  top: 4px;
  left: 0;
  width: 190px;
}
.el-nav-box {
  margin-left: 50px;
}
.el-nav-items {
  text-align: left;
  height: 70px;
  line-height: 77px;
  padding: 0 20px;
  font-size: 16px;
  color: #787878;
}
.el-demo-box {
  width: 100px;
  height: 40px;
  line-height: 40px;
  border-radius: 25px;
  border: #fcac5b 2px solid;
  margin: auto 0;
  color: #f08519;
  font-size: 16px;
}
.nav-active-color {
  color: #f08519;
  /* opacity: 0.5; */
  transition: all 1s ease;
}
.nav-active {
  border-bottom: 3px solid #fcac5b;
  animation-name: shutter-in-left;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}
.el-product-box {
  width: 100%;
  height: 420px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
}
.el-help-box {
  width: 100%;
  height: 100px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
}
.product-active {
  animation-name: slide-in-bck-center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: slide-in-bck-center 1s linear 0s 1 normal none;*/
}
.product-leave {
  animation-name: fade-out;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;

  /* shorthand
		animation: fade-out 2s linear 0s 1 normal forwards;*/
}

.el-prodect-item {
  text-align: left;
  flex: 1;
  margin: 0 30px;
  height: 70px;
  line-height: 70px;
}
.el-prodect-item ul .li-hover {
  width: 100%;
}
.el-prodect-item ul .li-hover :hover {
  background-color: #fafafa;
  color: #ff952b;
}
.el-prodect-item .pro-tip {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
}
.shake-center {
  animation-name: shake-center;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: shake-center 1s ease-out 0s 1 normal none;*/
}
@keyframes shake-center {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  10% {
    transform: rotate(8deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-8deg);
  }
  90% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}
.el-help-items {
  width: 25%;
}
.icon-width {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.icon-width-help {
  width: 40px;
  height: 40px;
  margin: 10px 0;
}
.icon-font {
  font-size: 14px;
  color: #787878;
}
.drawer-box {
  margin-left: 20px;
}
.el-drawer-ul {
  margin: 10px 0;
}
.el-drawer-ul li {
  margin: 10px 0;
  margin-left: 15px;
  font-size: 14px;
  text-align: left;
}
.el-icon-more {
  font-size: 24px;
}
.drawer-box h3 {
  margin: 10px 0;
  font-size: 15px;
  text-align: left;
}
.mobile-cnav-clicked {
  color: #f08519;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes slide-in-bck-center {
  0% {
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}
/* shorthand
		animation: shutter-in-left 1s linear 0s 1 normal none;*/
@keyframes shutter-in-left {
  0% {
    transform: rotateY(100deg);
    transform-origin: left;
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    transform-origin: left;
    opacity: 1;
  }
}
</style>
  