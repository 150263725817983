<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-img" v-if="!isMobile()">
            <img src="../../../assets/image/product/storeProduct/banner1.png" alt="" />
          </div>
          <div class="banner-items">
            <h1 class="el-font-colorfff">全端智能招聘网站管理系统</h1>
            <p>
              帮您快速实现招聘网站搭建的需求把握招聘体验提供全方位服务，按需定制 , 满足各行业不同需求,用心积累点滴，创造行业精品
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :show="four_show"
      ref="scrollFour"
    ></sectionFour>
    <sectionFive
      :content="fiveData"
      :show="three_show"
      ref="scrollFive"
    ></sectionFive>
    <sectionSix
      :content="sixData"
      :show="three_show"
      ref="scrollSix"
    ></sectionSix>
    <sectionSeven
      :content="sevenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionSeven>
    <sectionNine
      :content="nineData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionNine>
    <sectionEleven
      :content="elevenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionEleven>
    <sectionFooter
      :content="footerData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionSix from "@/components/productSection/sectionSix.vue";
import sectionSeven from "@/components/productSection/sectionSeven.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionNine,
    sectionEleven,
    sectionFooter,
    toastFrame
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "系统特色", "功能展示", "服务群体", "相关文档"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      oneData: {
        title: "板块1",
        title_two: "一站式智能招聘管理系统",
        desc: "打造高效 安全 智能 又容易管理的智能招聘系统",
        banner: require("../../../assets/image/test_section_1.png"),
        fontColor: "#4ebefd",
        borderColor: "#4ebefd",
        list: [
          { name: "极速搭建", desc: "l招聘平台搭建，定制品牌调性提供完整配套体系" },
          { name: "功能丰富", desc: "享有各类功能，性价比高，产品稳定快速响应产品迭代、运营方案持续更新" },
          { name: "维护便利", desc: "维护网站便利，可即时修改网站内容24小时运维专员为您服务" },
          { name: "系统实践经验", desc: "官方实战运营，降本增效，精细化运营降低运营成本干货多多" },
        ],
      },
      twoData: {
        title: "",
        title_two: "核心功能",
        desc: "易用、精选、一站式应用场景组合推荐,为站长创造竞争力",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "简历管理",
            desc: "简历智能解析，自动查重入库人才轨迹跟踪",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "招聘管理",
            desc: "智能管理招聘全过程支持视频面试，直播带岗",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "数据管理",
            desc: "数据实时分析，系统预置，数据预警自动检测，行业大数据分析",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "CRM管理",
            desc: "深度客户管理，挖掘客户价值洞察客户行为",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "在线直聊",
            desc: "内置丰富的求职常用语，简单点击随地与HR沟通",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "分站管理",
            desc: "数据实时分析，系统预置，数据预警自动检测，行业大数据分析",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "专题招聘",
            desc: "企业专题招聘，职位专题招聘可随心所欲选择企业",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "招聘会管理",
            desc: "现场招聘会网络招聘会相结合自动报名",
          },
        ],
      },
      threeData: {
        title: "",
        title_two: "智能化招聘管理系统",
        desc: "覆盖社招、校招、内推、猎头管理等场景，让HR获得更高效的招聘体验、更便捷的协作体验，让管理者获得招聘数据洞见。让招聘降本增效的同时，树立企业在候选人心目中的专业形象",
        list: [
          {
            img: require("../../../assets/image/product/cityJob/section3_1.jpeg"),
            name: "招聘流程自动化",
            desc: "灵活自定义流程，自动化推进，视频面试无缝融合，招聘过程“飞”起来",
          },
          {
            img: require("../../../assets/image/product/cityJob/section3_2.jpeg"),
            name: "人才库运营",
            desc: "一张Map看清人才结构，个性化内容激活沉睡的人才资源",
          },
          {
            img: require("../../../assets/image/product/cityJob/section3_3.jpeg"),
            name: "智能数据分析",
            desc: "一拖一拽数据实时呈现，可视化看板CEO看了也能一目了然",
          },
          {
            img: require("../../../assets/image/product/cityJob/section3_4.jpeg"),
            name: "强化雇主品牌",
            desc: "十分钟搭建招聘官网，雇主形象瞬间拉满，以专业雇主品牌吸引优质候选人",
          },
        ],
      },
      fourData: {
        title: "",
        title_two: "提供全场景网站运营数字化解决方案",
        img: require("../../../assets/image/test_bg_1.png"),
        desc: "帮助站长搭建私域流量池，构建可持续发展的网站经营体系",
        list: [
          { name: "稳定，高效的智能管理招聘全过程", desc: "帮助企业提高招聘效能，多端/多角色招聘协同全面提升网站获取竞争力" },
          { name: "数据驱动业务决策", desc: "全能型数据分析平台，网站数据分析应用的全场景需求" },
          { name: "洞察客户行为，赋能平台数字化能力", desc: "深度客户管理，挖掘客户价值，洞察客户行为，高效跟进客户，实现持续可复制的业绩增长" },
          { name: "构建私域流量池，告别低效销售方式", desc: "管理者也通过外呼系统，方便的管理每一个销售的工作，从而不断优化" },
        ],
      },
      fiveData: {
        title: "",
        title_two: "产品价值",
				desc: "数字化智能招聘系统管理 ，助力招聘网站提升核心竞争力",
        img: require("../../../assets/image/product/cityJob/section5_1.png"),
        list_one: [
          {
            name: "整合运营招聘渠道",
            desc: "招聘网站搭建，量身定制雇主品牌调性，支持多样的管控模式， 系统支持PC端/移动端多端应用，随时随地接入，便捷处理招聘业务",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "智能管理招聘过程",
            desc: "简历智能解析，企业/人才轨迹跟踪，简历/职位集中管理 招聘与测评，支持视频面试，在线沟通，高效协同运营",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "数据驱动业务决策",
            desc: "数据实时分析，系统预置丰富的报表模板，自由灵活配置数据报表 发现中心数据预警，自动监测，防范业务风险",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "多端/多角色招聘协同",
            desc: "系统支持PC端/移动端多端应用，随时随地接入 对接到微信、企业微信、等平台便捷处理招聘业务",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "统一管理，全局管控",
            desc: "招聘流程、表单、模版、页面功能等灵活配置，满足多变的业务需求 需求/职位集中管理，多渠道聚合，广告一键发布",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "个性化的灵活扩展",
            desc: "基于灵狐云系统，快速实现客制化开发与应用 定制招聘网站解决方案，实现招聘全流程数字化管理，提升竞争力",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      sixData: {
        title: "板块6",
        desc: "板块6板块6板块6板块6板块6板块6板块6板块6",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/store_fresh_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/store_fresh_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
        ],
      },
      sevenData: {
        title: "",
        title_two: "适配行业和场景的解决方案",
        desc: "板块7",
        list_one: [
          {
            name: "同城招聘",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_1.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
          {
            name: "校园招聘",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_2.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
        ],
        list_two: [
          {
            name: "连锁零售",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_3.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
          {
            name: "互联网行业",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_4.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
        ],
      },
      nineData: {
        title: "",
        title_two: "数字场景解决方案",
        desc: "开启招聘新体验，海量商业化验证，即买即用",
        list: [
          {
            img: require("../../../assets/image/product/cityJob/section9_1.jpeg"),
            name: "VIP服务群",
            desc: "客户专属VIP服务群结识更多优秀从业者助力站长成为招聘行业的领品牌",
          },
          {
						img: require("../../../assets/image/product/cityJob/section9_2.jpeg"),
						name: "全方位贴心服务",
						desc: "了解招聘行业环境，搭建站点框架提供完整的服务配套培训服务体系从零快速启动",
          },
          {
            img: require("../../../assets/image/product/cityJob/section9_3.jpeg"),
            name: "产品功能培训",
            desc: "快速了解产品功能及应用场景指导站点运用产品功能业务销售人员的操作规范流程",
          },
					{
						img: require("../../../assets/image/product/cityJob/section9_4.jpeg"),
						name: "运营案例指导",
						desc: "全年优秀运营案例输出一对一运营指导定制专属解决方案",
					},
        ],
      },
      elevenData: {
        title: "板块11",
        title_two: "板块11-1",
        title_three: "板块11—3",
        desc: "板块11desc",
        desc_two: "板块11——desctwo",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
        ],
      },
      footerData: {
				title: "系统演示",
        bgColor: "#207dff",
        codeList: [
          {
            name: "固嫩生活",
            img: require("../../../assets/image/product/cityJob/qrcode1.png"),
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—同城系统", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;
      console.log(this.$refs.scrollOne.$el.clientHeight);
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
        }
        if (scrollTop == 0) {
          this.navTop = false;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(85deg, #4F32FF, #3172FF, #04E1FE);
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #207dff;
  color: #207dff;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #207dff;
  color: #207dff;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(85deg, #4F32FF, #3172FF, #04E1FE);
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #207dff;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(85deg, #4F32FF, #3172FF, #04E1FE);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #207dff;
		color: #207dff;
	}
}
</style>
