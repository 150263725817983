<template>
  <div class="section-card-box" ref="scrollOne">
    <div class="el-content-width">
      <div class="slide-bottom">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">{{ content.desc }}</p>
      </div>
      <div class="section7-content el-flex-between-start slide-top">
        <div class="section7-msg">
          <ul class="el-flex-around-start">
            <li
              class="el-flex-cloumn"
              v-for="(item, index) in content.list_one"
              :key="index"
              :class="index % 2 == 0 ? 'li2' : 'li1'"
            >
              <img :src="item.icon" alt="" style="border-radius: 50%;width: 80px;height: 80px;" />
              <h3>{{ item.name }}</h3>
              <p>{{ item.desc }}</p>
            </li>
          </ul>
          <ul class="el-flex-around-start">
            <li
              class="el-flex-cloumn"
              v-for="(item, index) in content.list_two"
              :key="index"
              :class="index % 2 == 0 ? 'li2' : 'li1'"
            >
              <img :src="item.icon" alt="" style="border-radius: 50%;width: 80px;height: 80px;" />
              <h3>{{ item.name }}</h3>
              <p>{{ item.desc }}</p>
            </li>
          </ul>
        </div>
        <img
          v-if="!isMobile()"
          class="section0-img"
          :src="content.image ? content.image : '../../assets/image/store_vip_1.jpg'"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "sectionOne",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
};
</script>
  
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.section7-content {
  margin: 60px 0;
}
.section7-content .section7-msg {
  width: 60%;
}
.section7-content ul li {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #fff;
  padding: 10px;
  border: #ffc5db 5px solid;
  box-shadow: 0px 5px 15px 0px #ad0bb61a;
}
.section7-content ul li img {
  width: 82px;
  height: 82px;
}
.section7-content ul .li2 {
  margin-top: 100px;
}
.section7-content ul li h3 {
  margin: 10px 0;
}
.section7-content ul li p {
  width: 150px;
  margin: 0 auto;
  font-size: 15px;
  color: #666;
}
.section7-content .section0-img {
  width: 40%;
	height: 700px;
  border-radius: 25px;
  box-shadow: 0px 10px 15px 0px #e56df748;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section7-content {
    margin: 30px 0;
    padding: 10px;
  }
  .section7-content .section7-msg {
    width: 100%;
  }
  .section7-content ul li {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #fff;
    padding: 10px;
    border: #ffc5db 5px solid;
    box-shadow: 0px 5px 15px 0px #ad0bb61a;
  }
  .section7-content ul li img {
    width: 52px;
    height: 52px;
  }
  .section7-content ul .li2 {
    margin-top: 60px;
  }
  .section7-content ul li h3 {
    margin: 5px 0;
  }
  .section7-content ul li p {
    width: 100%;
    margin: 0 auto;
    font-size: 10px;
    color: #666;
  }
  .section7-content .section0-img {
    width: 40%;
    border-radius: 25px;
    box-shadow: 0px 10px 15px 0px #e56df748;
  }
}
</style>