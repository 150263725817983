<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <!-- <div class="banner-img" v-if="!isMobile()">
            <img src="../../../assets/image/product/storeProduct/banner1.png" alt="" />
          </div> -->
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐网络科技协同办公系统</h1>
            <p>
              打造智能极简的OA系统，赋能中小型企业数字化转型
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFive
      :content="fiveData"
      :isShow="four_show"
      ref="scrollFour"
    ></sectionFive>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFive,
    toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "主要功能", "业务价值", "系统优势"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      oneData: {
        title: "",
        title_two: "系统介绍",
        desc: "",
        banner: require("../../../assets/image/product/oa/section1_1.png"),
      },
      twoData: {
        title: "",
        title_two: "人力资源管理的主要功能",
        desc: "",
        imageBgColor: "#B6FFB8",
        list: [
          {
            icon: require("../../../assets/image/product/oa/icon1.png"),
            name: "员工档案",
            desc: "员工信息是人力资本管理的基础。企业需要把员工信息分门别类以结构化的形式记录、维护和分析、以供决策之用",
          },
          {
            icon: require("../../../assets/image/product/oa/icon2.png"),
            name: "考勤管理",
            desc: "通过严格的考勤，提高员工的遵守公司制度，规范自己行为的意识，分辨员工工作态度的不同，提高选择人才的依据",
          },
          {
            icon: require("../../../assets/image/product/oa/icon3.png"),
            name: "薪资管理",
            desc: "提供现金的薪酬和工资管理机制，能更好地满足各种累及不同企业的薪资管理需求所有工资计算由系统自动计算，大大解释也能够人力，避免错误，提高精准度和时效",
          },
          {
            icon: require("../../../assets/image/product/oa/icon4.png"),
            name: "社保管理",
            desc: "新员工一键参保，帮助企业在线管理员工社保信息，是复杂的社保管理变得简单快捷",
          },
          {
            icon: require("../../../assets/image/product/oa/icon5.png"),
            name: "绩效考核",
            desc: "建立适应不同需求的绩效管理体系，有效提升绩效计划编制及审核、绩效考核等工作效率，实现绩效考核成绩的广泛应用，助力各级管理人员和员工的价值提升",
          },
          {
            icon: require("../../../assets/image/product/oa/icon6.png"),
            name: "招聘管理",
            desc: "所有的招聘计划都能在系统中按实际数据实时显示，所有的相关部门也都能实时查到准确的信息。帮助各部门建立人才简历库，方便筛选人才和安排面试",
          },
          {
            icon: require("../../../assets/image/product/oa/icon7.png"),
            name: "行政管理",
            desc: "帮助提高企业人事、管理者办事效率，减少工作重量，节约管控成本",
          },
          {
            icon: require("../../../assets/image/product/oa/icon8.png"),
            name: "员工福利",
            desc: "有助于提高企业在员工和其他企业心目中的形象，提高员工对职务的满意度，使员工全身心的投入到工作中，留住人才",
          },
        ],
      },
      threeData: {
        title: "",
        title_two: "助力构建高效数智化OA平台",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/oa/section3_1.png"),
            name: "数字化，全域协同高效",
            desc: "100多协同应用开箱即用，覆盖“人财物事”全场景，让组织跨部门协同更高效",
          },
          {
            img: require("../../../assets/image/product/oa/section3_2.png"),
            name: "平台化，应用扩展敏捷",
            desc: "低代码，可建模、可集成、可开发；性能可伸缩、应用可扩展；适配国产化，安全可靠",
          },
          {
            img: require("../../../assets/image/product/oa/section3_3.png"),
            name: "智能化，效能自动提升",
            desc: "支持智能门户、智能助手、智能报销等应用，适配多款智能硬件，自动化水平高",
          },
          {
            img: require("../../../assets/image/product/oa/section3_4.png"),
            name: "生态化，内外协同一体",
            desc: "支撑集团型复杂组织在线，接入外部伙伴，打造生态组织，让“供产销服”内外协同更高效",
          },
        ],
      },
      fiveData: {
        title: "",
        title_two: "为什么客户选择人力资源管理系统",
        img: require("../../../assets/image/product/oa/section5_1.png"),
        list_one: [
          {
            name: "一体化管理员工信息",
            desc: "悟空人力资源集中管理所有HR工作，可通过搜索员工信息、查看组织结构图、制定HR流程、分析员工流失报表等等，都可以实现一体化管理",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "提高数据整理效率",
            desc: "从各个端口汇集考勤数据，自定义休假类型，轻松进行排班管理，避免因为手工录入而导致记录错误",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "简化员工绩效考核",
            desc: "全面收集反馈绩效情况，确定员工技能及能力提升方案，设定绩效目标，提升员工综合能力",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "解答员工的疑问",
            desc: "员工疑问太多？使用悟空人力资源管理工具，将各种问题分类整理，节省繁琐的咨询步骤，减少错误",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "自动化工作流",
            desc: "为重要活动、纪念日、生日等设置提醒。还以配置邮件提升，当员工试用期结束或内部转岗、职位变更等重要数据变化时自动通知",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "自定义设置，满足企业的独特需求",
            desc: "可创建自定义表单，用于入职培训、员工福利、出差、离职等事务的管理。可自定义函数并与第三方应用程序无缝对接",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      }
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技协同办公系统", //分享标题
      desc: "打造智能极简的OA系统，赋能中小型企业数字化转型", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
		getCurrent(index) {
			this.current = index;
			let headerHeight = this.$refs.scrollHeader.offsetHeight;
			let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
			let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
			let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
			let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;

			if (index == 0) {
				document.documentElement.scrollTop = headerHeight;
				document.body.scrollTop = headerHeight;
			} else if (index == 1) {
				document.documentElement.scrollTop = oneHeight;
				document.body.scrollTop = oneHeight;
			} else if (index == 2) {
				document.documentElement.scrollTop = twoHeight;
				document.body.scrollTop = twoHeight;
			} else if (index == 3) {
				document.documentElement.scrollTop = threeHeight;
				document.body.scrollTop = threeHeight;
			} else if (index == 4) {
				document.documentElement.scrollTop = fourHeight;
				document.body.scrollTop = fourHeight;
			}
		},
		handleScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (this.$refs.scrollHeader) {
				let headerHeight = this.$refs.scrollHeader.offsetHeight;
				let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
				let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
				let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				if (scrollTop > headerHeight) {
					this.navTop = true;
					this.current = 0
				}
				if (scrollTop == 0) {
					this.navTop = false;
					this.current = 0
				}
				if (scrollTop > oneHeight) {
					this.one_show = true;
					this.current = 1
				}
				if (scrollTop > twoHeight) {
					this.two_show = true;
					this.current = 2
				}
				if (scrollTop > threeHeight) {
					this.three_show = true;
					this.current = 3
				}
				if (scrollTop > fourHeight) {
					this.four_show = true;
					this.current = 4
				}
			}
		},
		destroyed() {
			document.removeEventListener("scroll", this.handleScroll);
		},
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
		rgb(141,211,142) 0%,
		rgb(63,181,64) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #8DD38E;
  color: #3FB540;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #8DD38E;
  color: #3FB540;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
		rgb(141,211,142) 0%,
		rgb(63,181,64) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #3FB540;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			90deg,
			rgb(141,211,142) 0%,
			rgb(63,181,64) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #8DD38E;
		color: #3FB540;
	}
}
</style>