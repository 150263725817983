<template>
  <div>
    <Header :num="4" />
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '200px' : '320px'"
      >
        <el-carousel-item>
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? 'el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h1 class="title">灵狐网络科技系统更新日志</h1>
                  <div class="el-flex-between"></div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="el-time-box">
        <div class="el-content-width">
          <el-timeline>
            <el-timeline-item timestamp="2023/10/29" placement="top">
              <el-card>
                <h2>灵狐外卖跑腿系统更新</h2>
                <ul class="time-ul">
                  <li>外卖用户端发布上线</li>
                  <li>外卖商户端发布上线</li>
                  <li>外卖骑手端发布上线</li>
                  <li>外卖运营端发布上线</li>
                </ul>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2023/10/19" placement="top">
              <el-card>
                <h2>灵狐云商城系统更新</h2>
                <ul class="time-ul">
                  <li>商户订单导出功能</li>
                  <li>商品分类页面布局设置</li>
                  <li>轮播图支持设置高度</li>
                  <li>灵狐生鲜商城收藏时间排序问题</li>
                  <li>会员卡系统用户线上买单功能</li>
                </ul>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2023/9/23" placement="top">
              <el-card>
                <h2>同城信息发布系统更新</h2>
                <ul class="time-ul">
                  <li>分类信息信息刷新功能</li>
                  <li>会员等级刷新设置</li>
                  <li>商品兑换功能</li>
                  <li>弹窗显示系统公告信息</li>
                </ul>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <Cnav />
    <Footer />
  </div>
</template>
        <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
export default {
  components: { Header, Cnav, Footer },
  data() {
    return {
      input: "",
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—更新日志", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {},
};
</script>
        <style scoped>
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/about_us_3.jpg");
  background-size: cover;
  background-position: center;
  height: 520px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 520px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 80%;
  height: 300px;
  margin: auto;
  text-align: center;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}
.el-banner-content .title {
  margin: 60px auto;
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.el-time-box {
  padding: 100px;
  text-align: left;
}
.el-time-box ul {
  margin: 20px;
}
.el-time-box .time-ul li {
  padding: 5px 0;
  color: #999;
  list-style: disc;
}
.el-table-box {
  margin-bottom: 100px;
}
.el-second-box {
  width: 80%;
  margin: 60px auto;
}
.el-second-box li {
  padding: 10px 60px;
  border: 1px solid #ccc;
}
</style>