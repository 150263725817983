<template>
  <div class="section-card-box" style="background: #f5f5f5">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-bottom' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <div class="section2-content" :class="isShow ? 'slide-top' : ''">
        <ul class="el-flex-between el-flex-wrap">
          <li
            class="el-flex-cloumn"
            v-for="(item, index) in content.list"
            :key="index"
          >
            <div
              class="img-bg"
              :style="{ backgroundColor: content.imageBgColor }"
            >
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ item.name }}</h3>
            <span>{{ item.desc }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script >
export default {
  name: "sectionTwo",
  props: {
    isShow: { type: Boolean, required: false },
    imageBgColor: { type: String, required: "" },
    content: { type: Object, required: null },
  },
};
</script>
<style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section2-content {
  margin: 60px 0;
}
.section2-content li {
  width: 23%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #0000001a;
  margin: 10px 0;
}
.section2-content li .img-bg {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #e3fdff;
  border-radius: 50%;
  padding: 15px;
}
.section2-content li img {
  width: 100%;
  height: 100%;
}
.section2-content li h3 {
  color: #333;
  margin: 20px;
}
.section2-content li span {
  color: #696969;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section2-content {
    margin: 60px 0;
  }
  .section2-content li {
    width: 47%;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px #0000001a;
    margin: 10px 0;
  }
  .section2-content li .img-bg {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-color: #e3ffff;
    border-radius: 50%;
    padding: 15px;
  }
  .section2-content li img {
    width: 100%;
    height: 100%;
  }
  .section2-content li h3 {
    color: #333;
    margin: 5px;
  }
  .section2-content li span {
    color: #696969;
    font-size: 10px;
  }
}
</style>