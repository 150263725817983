<template>
  <div class="section-card-box" ref="scrollTwo">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-left' : ''">
        <div class="section-title">
          <h2 class="el-font-color333">{{ content.title }}</h2>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">{{ content.desc }}</p>
      </div>
      <div class="section11-content">
        <div
          class="section11-msg"
          :class="isMobile() ? 'el-flex-cloumn' : 'el-flex-between'"
        >
          <div class="section11-img">
            <el-image
              style="width: 100%; height: 100%"
              :src="content.list[current].img"
              slot="placeholder"
              :class="isShow ? 'slide-right' : ''"
              fit="cover"
            >
              <div>加载中<span class="dot">...</span></div>
            </el-image>
          </div>
          <div
            class="el-flex-cloumn-left msg-content"
            :class="isShow ? 'slide-left' : ''"
          >
            <div style="width: 20%">
              <h2 class="el-font-color333">{{ content.title_three }}</h2>
              <div class="nav-active"></div>
            </div>
            <p>
              {{ content.desc_two }}
            </p>
            <ul class="el-flex-wrap el-flex">
              <li
                class="el-flex-column"
                v-for="(item, index) in content.list"
                :key="index"
                @mouseenter="getCurrent(index)"
              >
                <div
                  class="section11-icon bg-1"
                  :style="{ backgroundColor: item.iconBgColor }"
                >
                  <img :src="item.icon" alt="" />
                </div>
                <span>{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script >
export default {
  name: "sectionEleven",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
  data() {
    return { current: 0 };
  },
  methods: {
    getCurrent(index) {
      this.current = index;
    },
  },
};
</script>
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section11-content {
  padding: 100px 0;
}
.section11-msg {
  margin-top: 60px;
}
.section11-msg .section11-img {
  width: 45%;
}
.section11-msg .msg-content {
  width: 52%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px #f7b26d48;
}
.section11-msg .msg-content p {
  padding: 20px 0;
  text-align: left;
}
.section11-msg .msg-content li {
  width: 20%;
  padding: 10px;
  border-radius: 5px;
}
.section11-msg .msg-content li .section11-icon {
  width: 55px;
  height: 55px;
  padding: 8px;
  border-radius: 15px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.bg-1 {
  background-color: #fffce3;
}
.bg-2 {
  background-color: #ffe9e3;
}
.bg-3 {
  background-color: #e3f9ff;
}
.section11-msg .msg-content li img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section11-content {
    padding: 30px 0;
  }
  .section11-msg {
    margin-top: 20px;
  }
  .section11-msg .section11-img {
    width: 100%;
  }
  .section11-msg .msg-content {
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 10px 15px 0px #f7b26d48;
  }
  .section11-msg .msg-content p {
    padding: 20px 0;
    text-align: left;
  }
  .section11-msg .msg-content li {
    width: 24%;
    padding: 5px;
    border-radius: 5px;
  }
  .section11-msg .msg-content li .section1-icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .bg-1 {
    background-color: #fffce3;
  }
  .bg-2 {
    background-color: #ffe9e3;
  }
  .bg-3 {
    background-color: #e3f9ff;
  }
  .section1-msg .msg-content li img {
    width: 100%;
    height: 100%;
  }
}
</style>