<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div class="section-title">
        <h2 class="el-font-color333">{{ content.title }}</h2>
        <div class="nav-active" style="width: 80px"></div>
      </div>
      <div
        class="section-footer-href"
        :style="{ backgroundColor: content.bgColor }"
				v-if="content.hrefList"
      >
        <div
          class="el-flex el-align-center"
          v-for="(item, index) in content.hrefList"
          :key="index"
        >
          <h3>{{ item.name }}</h3>
          <span>{{ item.href }}</span>
        </div>
      </div>
      <div class="section-footer-content">
        <ul class="el-flex-around">
          <li
            v-for="(item, index) in content.codeList"
            :key="index"
            :style="{ borderColor: content.bgColor }"
          >
            <h3 :style="{ backgroundColor: content.bgColor }">
              {{ item.name }}
            </h3>
            <img :src="item.img" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script >
export default {
  name: "sectionTwo",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: "" },
  },
};
</script>
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}
.section-footer-content {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
  border-radius: 15px;
  /* box-shadow: 0px 5px 15px 0px #14e8ff1a; */
}
.section-footer-href {
  width: 48%;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 0px #14e8ff1a;
  /* background: linear-gradient(
    320deg,
    rgb(24, 211, 90) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgb(48, 254, 148) 100%
  ); */
}
.section-footer-href div {
  padding: 10px 0;
}
.section-footer-content ul li {
  width: 30%;
  border-radius: 20px;
  border: #fbd335 2px solid;
  overflow: hidden;
}
.section-footer-content ul li h3 {
  width: 100%;
  height: 70px;
  line-height: 70px;
  color: #fff;
  font-size: 23px;
  /* background: linear-gradient(
    90deg,
    rgb(252, 192, 53) 0%,
    rgb(244, 224, 0) 100%
  ); */
}
.section-footer-content ul li img {
  margin: 10px;
	width: 120px;
	height: 120px;
}
@media only screen and (max-width: 800px) {
  .section-footer-href {
    width: 90%;
    margin: 20px auto;
    padding: 20px 10px;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0px 5px 15px 0px #14e8ff1a;
    /* background: linear-gradient(
    320deg,
    rgb(24, 211, 90) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgb(48, 254, 148) 100%
  ); */
  }
  .section-footer-href div {
    padding: 10px 0;
  }
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }

  .section-footer-content {
    width: 100%;
    margin: 20px auto;
    padding: 5px;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0px 5px 15px 0px #14e8ff1a;
  }
  .section-footer-content ul li {
    width: 33%;
    border-radius: 20px;
    border: #ffdcdc 2px solid;
    overflow: hidden;
  }
  .section-footer-content ul li h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: #fff;
    font-size: 15px;
  }
  .section-footer-content ul li img {
    /* width: 75%; */
		width: 80px;
		height: 80px;
    margin: 5px;
  }
}
</style>