<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-right' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <div
        class="section4-content el-flex-between"
        :class="isShow ? 'slide-left' : ''"
      >
        <div class="section4-img">
          <el-image
            style="width: 100%; height: 100%"
            :src="content.img"
            slot="placeholder"
            class="image-slot"
            fit="cover"
          >
            <div>加载中<span class="dot">...</span></div>
          </el-image>
        </div>

        <ul>
          <li v-for="(item, index) in content.list" :key="index">
            <h3>{{ item.name }}</h3>
            <p>
              {{ item.desc }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script >
export default {
  name: "sectionFour",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
};
</script>
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section4-content {
  margin-top: 60px;
}
.section4-content ul {
  width: 60%;
  text-align: left;
}
.section4-content ul li {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 15px;
}
.section4-content ul li p {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}
.section4-img {
  width: 36%;
  height: 500px;
}
.section4-img img {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section4-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section4-content ul {
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
  }
  .section4-content ul li {
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0px 5px 15px 0px #0000001a;
    border-radius: 15px;
  }
  .section4-content ul li p {
    font-size: 14px;
    color: #333;
    margin-top: 10px;
    font-size: 10px;
  }
  .section4-img {
    width: 100%;
    height: 220px;
  }
  .section4-img img {
    width: 100%;
    height: 100%;
  }
}
</style>