import axios from 'axios';
import wx from 'weixin-js-sdk';
const jsApiList = ['onMenuShareAppMessage', 'onMenuShareTimeline', 'onMenuShareQQ', 'onMenuShareWeibo']


function getJSSDK(dataForWeixin) {
    axios.get('/wechat/jsSdk?url=' + location.href.split('#')[0]).then(res => {
        console.log(res.data)
        wx.config({
            debug: res.data.data.debug, // true 为测试版 可查看配置成功或失败的信息 正式使用时需要改为false
            appId: res.data.data.appId, // 必填，公众号的唯一标识,需要注册公众号提供appid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名，见附录1
            jsApiList: jsApiList// 必填，需要使用的JS接口列表，所有JS接口列表见附录2 updateAppMessageShareData
        });
    })
    if (!dataForWeixin.img) {
        dataForWeixin.img = process.env.VUE_APP_SERVER_URL + 'assets/images/share.png'
    }
    wx.ready(function () {
        wx.onMenuShareAppMessage({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                console.log(JSON.stringify(res));
            },
            success: function success(res) {
                console.log(JSON.stringify(res));
            },
            cancel: function cancel(res) {
                console.log(JSON.stringify(res));
            },
            fail: function fail(res) {
                console.log(JSON.stringify(res));
            }
        });
        // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
        wx.onMenuShareTimeline({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger() {
            },
            success: function success() {
            },
            cancel: function cancel() {
            },
            fail: function fail(res) {
                console.log(JSON.stringify(res));
            }
        });
        // 2.3 监听“分享到QQ”按钮点击、自定义分享内容及分享结果接口
        wx.onMenuShareQQ({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                console.log(res)
                //alert('用户点击分享到QQ');
            },
            complete: function complete(res) {
                alert(JSON.stringify(res));
            },
            success: function success(res) {
                console.log(res)
                //alert('已分享');
            },
            cancel: function cancel(res) {
                console.log(res)
                //alert('已取消');
            },
            fail: function fail(res) {
                console.log(res)
                //alert(JSON.stringify(res));
            }
        });
        // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
        wx.onMenuShareWeibo({
            title: dataForWeixin.title,
            desc: dataForWeixin.desc,
            link: dataForWeixin.linkurl,
            imgUrl: dataForWeixin.img,
            trigger: function trigger(res) {
                console.log(res)
                //alert('用户点击分享到微博');
            },
            complete: function complete(res) {
                console.log(res)
                // alert(JSON.stringify(res));
            },
            success: function success(res) {
                console.log(res)
                //alert('已分享');
            },
            cancel: function cancel(res) {
                console.log(res)
                // alert('已取消');
            },
            fail: function fail(res) {
                console.log(res)
                // alert(JSON.stringify(res));
            }
        });
    })
    wx.error(function (res) {
        console.log(res)
        // alert("微信验证失败");
    });
}
export default {
    // 获取JSSDK
    getJSSDK: getJSSDK
}

