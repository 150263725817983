import { render, staticRenderFns } from "./webHome.vue?vue&type=template&id=24c31dab&scoped=true"
import script from "./webHome.vue?vue&type=script&lang=js"
export * from "./webHome.vue?vue&type=script&lang=js"
import style0 from "./webHome.vue?vue&type=style&index=0&id=24c31dab&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c31dab",
  null
  
)

export default component.exports