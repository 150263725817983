<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐网络科技多行业会员卡系统</h1>
            <p>
              智能预约+抢购次卡+商城系统 覆盖大多美业领域
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
					<div class="banner-img" v-if="!isMobile()">
						<img src="../../../assets/image/product/storeVipcard/banner1.png" alt="" />
					</div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionSeven
      :content="sevenData"
      :isShow="one_show"
      ref="scrollOne"
    ></sectionSeven>
    <sectionTen
      :content="tenData"
      :isShow="two_show"
      ref="scrollTwo"
    ></sectionTen>		
    <sectionTwo
      :content="twoData"
      :isShow="three_show"
      ref="scrollThree"
    ></sectionTwo>
    <sectionEight
      :isShow="four_show"
      ref="scrollFour"
      :content="eightData"
    ></sectionEight>	
		<sectionThree :content="threeData" :isShow="five_show" ref="scrollFive">
		</sectionThree>
    <sectionFive
      :content="fiveData"
      :isShow="six_show"
      ref="scrollSix"
    ></sectionFive>
    <sectionNine
      :content="nineData"
      :isShow="seven_show"
      ref="scrollSeven"
    ></sectionNine>
    <sectionFooter
      :content="footerData"
      :isShow="footer_show"
      ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
		<toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionSeven from "@/components/productSection/sectionSeven.vue";
import sectionEight from "@/components/productSection/sectionEight.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionTen from "@/components/productSection/sectionTen.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionTwo,
    sectionThree,
    sectionFive,
    sectionSeven,
    sectionEight,
    sectionNine,
    sectionTen,
    sectionFooter,
		toastFrame		
  },
  data() {
    return {
      toastShow: false,			
      navList: ["系统介绍", "解决方案", "适用行业", "功能展示", "系统特色", "数字美业", "产品报价", "系统演示"],
      current: 0,
      one_show: true,
      two_show: false,
      three_show: false,
      four_show: false,
      five_show: false,
      six_show: false,
			seven_show: false,
			footer_show: false,
      navTop: false,
      twoData: {
        title: "适用行业",
        title_two: "灵狐会员卡系统适用行业",
        desc: "多行业应用解决方案",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "美业解决方案",
            desc: "美业解决方案，含有会员余额、积分、优惠券，抢购活动，次卡套餐，预约服务及在线商城等",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "宠物解决方案",
            desc: "宠物解决方案，含有会员余额、积分、优惠券，抢购活动，次卡套餐，预约服务及在线商城等",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "健身瑜伽解决方案",
            desc: "瑜伽解决方案，含有会员余额、积分、优惠券，抢购活动，次卡套餐，预约服务及在线商城等",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "会员制电商商城",
            desc: "适用于门店在线商城制作、含预售、拼团、秒杀、买单，风格可自定义",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "汽修多门店预约",
            desc: "汽修行业多门店预约系统，搭载最新的内部群机器人通知",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "按摩足疗SPA",
            desc: "适用于按摩足疗SPA的会所服务小程序，可在线预约会员管理",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "基础会员卡",
            desc: "以会员储值为基础提供到店增值服务，享受会员等级优惠以及积分和优惠券使用管理",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "通用服务业",
            desc: "通用服务业的入门，含有会员余额、积分、优惠券，抢购活动，次卡套餐等",
          },
        ],
      },
      threeData: {
        title: "系统特色",
        title_two: "灵狐会员卡系统特色",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/storeVipcard/img_1.png"),
            name: "满足门店所需",
            desc: "从预约排班、门店管理、会员管理都可以用VIPCard美业一站式实现",
          },
          {
            img: require("../../../assets/image/product/storeVipcard/img_2.png"),
            name: "多端互联操作",
            desc: "PC端、移动端、多端互联便捷操作、数据实时掌控",
          },
          {
            img: require("../../../assets/image/product/storeVipcard/img_3.png"),
            name: "适合多种行业",
            desc: "真正做到一套系统支持美业",
          },
          {
            img: require("../../../assets/image/product/storeVipcard/img_4.png"),
            name: "产品报价",
            desc: "为不同阶段、不同规模的您定制适合自己的选择",
          },
        ],
      },
      fiveData: {
        title: "数字美业",
        title_two: "连锁美业私域超能力",
        img: require("../../../assets/image/product/storeVipcard/img_7.png"),
        list_one: [
          {
            name: "数字化门店实现管理赋能",
            desc: "门店预约、服务SOP、收银对账、连锁门店管理等工作，通过门店管理中台实现数字化管理，通过手机APP实现全数据监管",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "美容师APP工作台实现业绩赋能",
            desc: "通过美容师工作APP实现美容师的日报、业绩、会员服务管理于一体，通过一部手机就完成了美容师服务、业绩、学习三大管理内容，符合90后美容师的数字化工作习惯",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "打造护理服务SOP实现服务质量赋能",
            desc: "每一个护理项目建立4大流程、6大服务节点、20个记录字段，全数字化流程管理美容师的每一个护理项目的服务细节，用数字能力提升美容师的服务品质与服务效率",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "社交营销实现门店流量赋能",
            desc: "闺蜜团小程序实现预约、购物两大核心功能，同时通过嗨美丽独创的分销合伙人、股东合伙人功能，社交营销实现门店推广的裂变营销，加快会员转化以及加速开店",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "iPad小美机器人实现门店AI赋能",
            desc: "智能分析近期可消费、可充值顾客，智能连接测肤仪、体脂称等医美硬件，智能采集体质数据",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "小区大数据实现连锁开店赋能",
            desc: "通过对所有会员来源的小区的大数据分析，可以分析以小区为核心会员的大数据消费、频次、消费能力等用户大数据画像，为分销开店、加盟开店提供AI大数据支撑",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      sevenData: {
        title: "系统介绍",
        title_two: "灵狐网络科技多行业会员卡系统",
        desc: "利用微信会员卡小程序沉淀私域流量 裂变获客",
				image: require("../../../assets/image/store_vip_1.jpg"),
        list_one: [
          {
            name: "门店管理中台",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_1.png"),
            desc: "收银、预约、会员、订单、商品、仓库、店务、系统设置",
          },
          {
            name: "员工端APP",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_2.png"),
            desc: "护理服务SOP、订单、业绩、会员管理、学习、我的等功能",
          },
        ],
        list_two: [
          {
            name: "用户端小程序",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_3.png"),
            desc: "预约、购物、优惠活动、会员中心、社交分销",
          },
          {
            name: "多种营销插件",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_4.png"),
            desc: "秒杀、拼团、砍价、优惠券、积分等营销插件",
          },
        ],
      },
      eightData: {
        title: "功能展示",
        title_two: "灵狐VIPCard功能展示",
        desc: "",
        functionList: [
          {
            name: "会员首页",
            icon: require("../../../assets/icon/product_icon/store_icon_1.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_1.png"),
          },
          {
            name: "智能预约",
            icon: require("../../../assets/icon/product_icon/store_icon_2.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_2.png"),
          },
          {
            name: "扫码核销",
            icon: require("../../../assets/icon/product_icon/store_icon_3.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_3.png"),
          },
          {
            name: "智能排班",
            icon: require("../../../assets/icon/product_icon/store_icon_4.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_4.png"),
          },
          {
            name: "零售商城",
            icon: require("../../../assets/icon/product_icon/store_icon_5.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_5.png"),
          },
          {
            name: "囤货商城",
            icon: require("../../../assets/icon/product_icon/store_icon_6.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_6.png"),
          },
          {
            name: "服务和计次卡",
            icon: require("../../../assets/icon/product_icon/store_icon_7.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_7.png"),
          },
          {
            name: "电子会员",
            icon: require("../../../assets/icon/product_icon/store_icon_8.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_8.png"),
          },
          {
            name: "提交订单",
            icon: require("../../../assets/icon/product_icon/store_icon_9.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_9.png"),
          },
          {
            name: "员工核销",
            icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
            img: require("../../../assets/image/product/storeVipcard/section8_10.png"),
          },
					{
						name: "代客下单",
						icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
						img: require("../../../assets/image/product/storeVipcard/section8_11.png"),
					},
					{
						name: "线下补单",
						icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
						img: require("../../../assets/image/product/storeVipcard/section8_12.png"),
					},
        ],
      },
      nineData: {
        title: "",
        title_two: "产品报价",
        desc: "为不同阶段、不同规模的您定制适合自己的选择",
        list: [
          {
            img: require("../../../assets/image/product/storeVipcard/img_5.png"),
            name: "授权版",
            desc: "独立部署系统服务器费用额外",
          },
          {
            img: require("../../../assets/image/product/storeVipcard/img_6.png"),
            name: "开源版",
            desc: "行业应用开源框架授权不开源",
          },
          {
            img: require("../../../assets/image/product/storeVipcard/img_3.png"),
            name: "代理加入",
            desc: "购买一套软件低折扣代理",
          },
        ],
      },
      tenData: {
        title: "解决方案",
        title_two: "灵狐会员卡系统解决方案",
        title_center: "会员系统",
        desc: "",
        bgColor_1: "#ffc6fa",
        bgColor_2: "#ff9bf8",
        bgColor_3: "#ff7ae7",
        list_one: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "美容、美发、美甲行业解决方案",
            desc: "无纸化开单，业绩提成自动计算，严谨的客勤流程，图形化的门店经营盘点，强大的会员卡项支持，全方面的手机端管理功能，产品消耗、寄存及完整的产品库存管理，丰富的客户关系管理",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "专业美容院管理方案",
            desc: "贴身的美容院现场管理软件，预约、服务、配料、日结、营销，轻松掌握门店管理，支持门店全方位立体化管理，界面清爽，操作简单",
          },
        ],
        list_two: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "医疗美容机构解决方案",
            desc: "全面提升医美机构的经营管理效率。大数据分析会员、品项变化趋势、业绩变动趋势，根据经营数据分析合理制定计划与适时调整营销方案；内置标准医美机构运营流程，让机构日常服务流程畅通，不再混乱",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "足浴行业一站式解决方案",
            desc: "集排钟、收银、会员、钟房、大厅、水吧、包厢、技师、财务、预约、智能门牌和手机管理于一体的、基于SaaS架构的智能化足浴沐足系统。产品涵盖洗浴、理疗和足浴保健等行业",
          },
        ],
      },
      footerData: {
				title: "系统演示",
        bgColor: "#fa3c85",
        codeList: [
          {
            name: "会员卡用户端",
            img: require("../../../assets/image/product/storeVipcard/gh_user.jpg"),
          },
					{
						name: "会员卡员工端",
						img: require("../../../assets/image/product/storeVipcard/gh_store.jpg"),
					},
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技多行业会员卡系统", //分享标题
      desc: "VIPCard多行业会员卡系统,利用微信会员卡小程序沉淀私域流量 裂变获客", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
			let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
			let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
			let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
			let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sevenHeight;
			
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      } else if (index == 5) {
        document.documentElement.scrollTop = fiveHeight;
        document.body.scrollTop = fiveHeight;
      } else if (index == 6) {
        document.documentElement.scrollTop = sixHeight;
        document.body.scrollTop = sixHeight;
      } else if (index == 7) {
        document.documentElement.scrollTop = sevenHeight;
        document.body.scrollTop = sevenHeight;
      } else if (index == 8) {
        document.documentElement.scrollTop = footerHeight;
        document.body.scrollTop = footerHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
				let headerHeight = this.$refs.scrollHeader.offsetHeight;
				let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
				let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
				let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
				let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
				let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sevenHeight;
				
        if (scrollTop > headerHeight) {
          this.navTop = true;
					this.current = 0;
        }
        if (scrollTop == 0) {
          this.navTop = false;
					this.current = 0;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
					this.current = 1;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
					this.current = 2;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
					this.current = 3;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
					this.current = 4;
        }
				if (scrollTop > fiveHeight) {
					this.four_show = true;
					this.current = 5;
				}
				if (scrollTop > sixHeight) {
					this.four_show = true;
					this.current = 6;
				}
				if (scrollTop > sevenHeight) {
					this.four_show = true;
					this.current = 7;
				}
				if (scrollTop > footerHeight) {
					this.four_show = true;
					this.current = 8;
				}
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    40deg,
    rgb(255, 83, 45) 10%,
    rgb(249, 82, 190) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #fa3c85;
  color: #fa3c85;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #fa3c85;
  color: #fa3c85;
  margin-left: 20px;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    40deg,
    rgb(255, 83, 45) 10%,
    rgb(249, 82, 190) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #fa3c85;
}

@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			40deg,
			rgb(255, 62, 45) 10%,
			rgb(253, 128, 93) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #ff5439;
		color: #ff5439;
	}
}
</style>