import { render, staticRenderFns } from "./takeawayProduct.vue?vue&type=template&id=215430a1&scoped=true"
import script from "./takeawayProduct.vue?vue&type=script&lang=js"
export * from "./takeawayProduct.vue?vue&type=script&lang=js"
import style0 from "./takeawayProduct.vue?vue&type=style&index=0&id=215430a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215430a1",
  null
  
)

export default component.exports