import { render, staticRenderFns } from "./testPage.vue?vue&type=template&id=253ad994&scoped=true"
import script from "./testPage.vue?vue&type=script&lang=js"
export * from "./testPage.vue?vue&type=script&lang=js"
import style0 from "./testPage.vue?vue&type=style&index=0&id=253ad994&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253ad994",
  null
  
)

export default component.exports