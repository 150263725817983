<template>
  <div>
    <Header :num="4" :mobNum="3" />
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '300px' : '520px'"
      >
        <el-carousel-item>
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h1 class="title">灵狐网络科技，让企业更有价值</h1>
                  <ul class="el-flex-between">
                    <li>
                      <h1>100+</h1>
                      <span>服务客户</span>
                    </li>
                    <li>
                      <h1>500+</h1>
                      <span>客户案例</span>
                    </li>
                    <li>
                      <h1>13+</h1>
                      <span>开发经验（年）</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="section-card-box" v-if="!isMobile()">
      <div class="el-content-width">
        <div class="section0-content el-flex-between-start">
          <div class="section0-msg">
            <h1>灵狐网络科技</h1>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              灵狐网络科技有限公司致力于为中小企业提供一站式IT服务解决方案，帮助广大中小企业轻松实现数字化转型，为中小企业创造价值。公司先后获得河北省科技型中小企业、国家科技型中小企业称号，拥有30余项软件著作权。公司自成立来以高科技为起点，以技术为核心、以完善的售后服务为后盾，秉承“技术开放创新，服务连接客户”的经营理念为企业提供服务，实现企业价值最大化。
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;
              为企业提供专业技术支持，提供包括互联网应用开发、微信小程序/公众
              号、企业网站、APP开发等IT应用的研发和维护
            </p>
            <p>
              我们的口号：灵狐网络科技，让企业更有价值。
              我们公司主要秉承的理念是为客户提供最优质的服务售后，一站式解决方案，终生保障您的售后！
            </p>
          </div>
          <img src="../../../assets/image/about_us_1.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <div class="section1-content el-flex-between">
          <img
            src="../../../assets/image/about_map.png"
            alt=""
            v-if="!isMobile()"
          />
          <div class="section1-msg">
            <h1>联系我们</h1>
            <el-form ref="form" :model="form" label-width="0">
              <div class="el-flex-between">
                <el-form-item>
                  <p>您的姓名</p>
                  <el-input
                    placeholder="请输入您的姓名"
                    v-model="form.name"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="!isMobile()">
                  <p>您的电话</p>
                  <el-input
                    placeholder="请输入您的电话"
                    v-model="form.mobile"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="el-flex-between" v-if="isMobile()">
                <el-form-item>
                  <p>您的电话</p>
                  <el-input
                    placeholder="请输入您的电话"
                    v-model="form.mobile"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item>
                <p>您的邮箱</p>
                <el-input
                  placeholder="请输入您的邮箱"
                  v-model="form.email"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <p>需求或建议</p>
                <el-input
                  :maxlength="300"
                  style="height: 100%"
                  placeholder="请输入您对我们的宝贵意见（10-500字）"
                  type="textarea"
                  v-model="form.content"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click.prevent="onSubmit"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <ul class="el-flex-between section3-content el-flex-wrap">
          <li>
            <img src="../../../assets/common/phone.png" alt="" />
            <h3>联系电话</h3>
            <p>{{ $config.APP_MOBILE }}</p>
          </li>
          <li>
            <img src="../../../assets/common/email.png" alt="" />
            <h3>公司邮箱</h3>
            <p>{{ this.$config.APP_EMAIL }}</p>
          </li>
          <li>
            <img src="../../../assets/common/home.png" alt="" />
            <h3>公司地址</h3>
            <p>{{ this.$config.APP_ADDRESS }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <div class="slide-bottom">
          <div class="section-title">
            <h3 class="el-font-color333" style="font-weight: 500">荣誉资质</h3>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">荣誉证书，知识版权</h1>
        </div>
        <div class="section4-content">
          <el-carousel
            indicator-position="inside"
            trigger="hover"
            type="card"
            :autoplay="false"
            :height="isMobile() ? '180px' : '320px'"
          >
            <el-carousel-item>
              <img
                src="../../../assets/image/aptitude/aptitude_21.png"
                alt=""
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                src="../../../assets/image/aptitude/aptitude_22.jpg"
                alt=""
              /> </el-carousel-item
            ><el-carousel-item>
              <img
                src="../../../assets/image/aptitude/aptitude_23.png"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <Cnav />
    <Footer />
  </div>
</template>
<script>
import axios from "axios";
import util from "@/util/util";
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
export default {
  components: { Header, Cnav, Footer },
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        email: "",
        content: "",
      },
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—关于我们", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    onSubmit() {
      if (!this.form.name) {
        this.$message({
          offset: 100,
          message: "请您输入您的姓名",
          type: "error",
        });
        return false;
      }
      if (!this.form.mobile) {
        this.$message({
          offset: 100,
          message: "请您输入您的电话",
          type: "error",
        });
        return false;
      }
      if (!util.isMobileNumber(this.form.mobile)) {
        this.$message({
          offset: 100,
          message: "请您输入正确的手机号",
          type: "error",
        });
        return false;
      }
      if (!this.form.content) {
        this.$message({
          offset: 100,
          message: "请您输入您的需求或建议",
          type: "error",
        });
        return false;
      }
      if (this.form.content.length < 10 || this.form.content.length > 500) {
        this.$message({
          offset: 100,
          message: "请输入10-500字的建议哦~",
          type: "error",
        });
        return false;
      }
      let that = this;
      axios
        .post("/guestbooks", that.form)
        .then(function (res) {
          console.log(res);
          if (res.data.msg == "ok" || res.data.code == 200) {
            that.$message({
              offset: 100,
              message: "提交成功",
              type: "success",
            });
            that.form.name = "";
            that.form.mobile = "";
            that.form.content = "";
            that.form.email = "";
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
  },
};
</script>
<style scoped>
:deep(.el-textarea__inner) {
  height: 150px;
  resize: none;
}
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/about_us_2.jpg");
  background-size: cover;
  background-position: center;
  height: 520px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 520px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 80%;
  height: 300px;
  margin: auto;
  padding-top: 60px;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.el-banner-content .title {
  margin: 60px auto;
}
.el-banner-content li {
  text-align: center;
}
.el-banner-content h1 {
  font-size: 50px;
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.section-card-box {
  padding: 100px 0;
}
.section0-content {
  width: 100%;
}
.section0-content .section0-msg {
  width: 45%;
  text-align: left;
}
.section0-content .section0-msg h1 {
  font-size: 28px;
  padding-left: 20px;
}
.section0-content .section0-msg p {
  margin: 20px 0;
  line-height: 30px;
  color: #666;
  font-size: 15px;
}
.section0-content img {
  width: 45%;
}
.section1-content {
  width: 100%;
}
.section1-content img {
  width: 45%;
}
.section1-content .section1-msg {
  width: 45%;
  text-align: left;
}
.section1-content .section1-msg h1 {
  margin-bottom: 40px;
  font-size: 28px;
}
.section1-content .section1-msg p {
  color: #666;
  margin-bottom: 5px;
  font-size: 14px;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-p {
  width: 60%;
  margin: 0 auto;
  font-size: 15px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(38, 230, 255) 0%,
    rgb(54, 175, 235) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.section-text {
  width: 60%;
  margin: 15px auto;
}
.section2-content {
  margin: 60px 0;
}
.section2-content li {
  width: 25%;
  margin: 20px;
  box-shadow: 0px 5px 15px 0px #0000001a;
}
.section2-content li img {
  width: 100%;
}
.section3-content img {
  width: 70px;
}
.section3-content li {
  width: 26%;
  padding: 10px;
  border-radius: 15px;
  border: solid 1px #ccc;
  color: #666;
}
.section3-content li:hover {
  color: #333;
  box-shadow: 0px 5px 15px 0px #0000001a;
}
.section3-content li h3 {
  margin: 10px 0;
}
.section4-content {
  margin: 60px 0;
}
.section4-content img {
  width: 75%;
}
@media only screen and (max-width: 800px) {
  .el-banner-items {
    margin: auto;
    background: url("../../../assets/image/about_us_2.jpg");
    background-size: cover;
    background-position: center;
    height: 520px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 520px;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .el-banner-content {
    width: 100%;
    padding: 10px;
    height: 300px;
    margin: auto 0;
    text-align: left;
    color: #fff;
    animation-name: slide-in-right;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
		animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
  }
  .el-banner-content h1 {
    font-size: 18px;
  }
  .banner-span {
    margin: 30px 0;
    font-size: 14px;
  }
  .section-card-box {
    padding: 30px 0;
  }
  .section1-content {
    width: 80%;
  }
  .section1-content .section1-msg {
    width: 100%;
    text-align: left;
  }
  .section1-content .section1-msg h1 {
    margin-bottom: 20px;
    font-size: 18px;
  }
  .section1-content .section1-msg p {
    color: #666;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .nav-active {
    width: 100%;
    height: 3px;
    background: linear-gradient(
      90deg,
      rgb(38, 230, 255) 0%,
      rgb(54, 175, 235) 100%
    );
    margin: 0 auto;
    margin-top: 10px;
  }
  .section-text {
    width: 60%;
    margin: 15px auto;
  }
  .section2-content {
    margin: 30px 0;
  }
  .section2-content li {
    width: 30%;
    margin: 10px;
    box-shadow: 0px 5px 15px 0px #0000001a;
  }
  .section2-content li img {
    width: 100%;
  }
  .section3-content img {
    width: 40px;
  }
  .section3-content li {
    width: 100%;
    padding: 5px;
    margin: 5px 10px;
    border-radius: 15px;
    border: solid 1px #ccc;
    color: #666;
  }
  .section3-content li:hover {
    color: #333;
    box-shadow: 0px 5px 15px 0px #0000001a;
  }
  .section3-content li h3 {
    margin: 5px 0;
  }
  .section4-content {
    margin: 30px 0;
  }
  .section4-content img {
    width: 98%;
  }
}
</style>