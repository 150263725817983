import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/common.css';
import mobile from '@/util/mobile.js';
import sdk from '@/util/wxShare';
import axios from "axios";
import config from '@/config/index'
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + 'api';//设置全局默认值
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.timeout = 5000 //设置5秒超时时间

// 全局方法挂载
Vue.prototype.isMobile = mobile.isMobile
Vue.prototype.wxShare = sdk.getJSSDK
Vue.prototype.$config = config

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
