<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div class="">
        <div
          class="section6-msg"
          :class="isMobile() ? 'el-flex-cloumn' : 'el-flex-between'"
        >
          <div class="section6-img slide-left">
            <el-image
              style="width: 100%; height: 100%"
              :src="content.list[current].img"
              slot="placeholder"
              class="image-slot"
              fit="cover"
            >
              <div>加载中<span class="dot">...</span></div>
            </el-image>
          </div>
          <div class="el-flex-cloumn-left msg-content slide-right">
            <div style="width: 24%">
              <h2 class="el-font-color333">{{ content.title }}</h2>
              <div class="nav-active"></div>
            </div>
            <p>
              {{ content.desc }}
            </p>
            <ul class="el-flex-wrap el-flex-between">
              <li
                v-for="(item, index) in content.list"
                :key="index"
                @mouseenter="getCurrent(index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "sectionSix",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
  data() {
    return { current: 0 };
  },
  methods: {
    getCurrent(index) {
      this.current = index;
    },
  },
};
</script>
  
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.section6-msg {
  margin-top: 60px;
}
.section6-msg .msg-content {
  width: 50%;
}
.section6-msg .msg-content p {
  padding: 35px 0;
  text-align: left;
}
.section6-msg .msg-content li {
  width: 45%;
  padding: 10px;
  margin: 5px 0;
  border: #777777 solid 1px;
  border-radius: 5px;
}
.section6-img {
  width: 45%;
  height: 350px;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section6-msg {
    margin-top: 20px;
  }
  .section6-msg img {
    width: 100%;
  }
  .section6-msg .msg-content {
    width: 100%;
  }
  .section6-msg .msg-content p {
    padding: 35px 0;
    text-align: left;
  }
  .section6-msg .msg-content li {
    width: 45%;
    padding: 10px;
    margin: 5px 0;
    border: #777777 solid 1px;
    border-radius: 5px;
  }
  .section6-img {
    width: 100%;
    height: 350px;
  }
}
</style>