<template>
  <div>
    <Header :num="1" @scroll="toNav($event)" />
    <div class="el-banner-card" ref="scrollHeader">
      <div class="el-content-width">
        <div class="el-flex-between banner-content">
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐科技服务</h1>
            <p>
              灵狐科技服务让你拥有匹配你发展阶段的服务，拥有独立、自主、品牌发展空间更大
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">
                联系我们
              </div>
              <!-- <div class="banner-button-2" >立即联系</div> -->
            </div>
          </div>
          <div class="banner-img" v-if="!isMobile()">
            <img
              src="../../../assets/image/product/service/service_banner_1.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="el-section-card" ref="scrollOne">
      <div class="el-content-width">
        <div class="slide-bottom">
          <div class="section-title">
            <h2 class="el-font-color333">服务类型</h2>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">多元化服务类型全面满足用户</h1>
          <p class="section-text">
            对前期创业、中期进阶、后期拓展所有阶段都提供有不同的价格、品牌化的针对性服务，让你拥有匹配你发展阶段的服务，拥有独立、自主、品牌发展空间更大
          </p>
        </div>
        <section class="section1-content slide-top">
          <ul class="el-flex-between el-flex-wrap">
            <li class="el-flex-cloumn">
              <div class="img-bg color-1">
                <img
                  src="../../../assets/icon/product_icon/service_1.png"
                  alt=""
                />
              </div>
              <h3>SAAS租用</h3>
              <p>
                基础版全套系统使用，搭建客户独立品牌的平台小程序，提供免费的技术维护服务、系统更新服务，以及专人售后服务
              </p>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg color-2">
                <img
                  src="../../../assets/icon/product_icon/service_2.png"
                  alt=""
                />
              </div>
              <h3>OEM品牌定制</h3>
              <p>
                支持骑手和商家APP（Android+针对个别用户某些特殊功能需求，支持多对一定制服务，匹配产品人员、UI设计、前后端开发、测试全产品线小组服务
              </p>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg color-3">
                <img
                  src="../../../assets/icon/product_icon/service_3.png"
                  alt=""
                />
              </div>
              <h3>特殊功能定制</h3>
              <p>
                针对个别用户某些特殊功能需求，支持多对一定制服务，匹配产品人员、UI设计、前后端开发、测试全产品线小组服务
              </p>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg color-4">
                <img
                  src="../../../assets/icon/product_icon/service_4.png"
                  alt=""
                />
              </div>
              <h3>独立部署</h3>
              <p>
                支持单个用户平台服务独立部署，独立服务器，专人运维，保障信息数据独立且安全，需要随时导出
              </p>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <div class="el-section-card" ref="scrollTwo">
      <div class="el-content-width">
        <div class="slide-left">
          <div class="section-title">
            <h2 class="el-font-color333">品牌定制</h2>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">设计独立品牌，有哪些优势？</h1>
        </div>
        <div class="section2-content">
          <ul class="el-flex-between">
            <li
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../../assets/image/index_banner_3.jpg') +
                  ')',
              }"
            >
              <span>塑造品牌形象</span>
              <p>
                支持全套产品品牌定制，建立用户的品牌意识，树立形象的同时加深市场对品牌的认可度，彰显品牌实力
              </p>
            </li>
            <li
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../../assets/image/about_us_1.jpg') +
                  ')',
              }"
            >
              <span>抢占市场先机 </span>
              <p>
                无需高额的技术开发成本，更不需要占用太长的开发周期，现成的、完善的O2O平台系统，快速抢占市场
              </p>
            </li>
            <li
              v-if="!isMobile()"
              :style="{
                backgroundImage:
                  'url(' +
                  require('../../../assets/image/about_us_2.jpg') +
                  ')',
              }"
            >
              <span>加盟提升收益 </span>
              <p>
                除了自己本地运营，还支持多城市、多站点发展代理加盟商，多区域同步运营，收取站点佣金，提升收益
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="el-section-card section-bg" ref="scrollThree">
      <div class="el-content-width">
        <div class="section-title">
          <h2 class="el-font-color333">功能定制</h2>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">灵狐科技提供哪些OEM品牌定制服务？</h1>
        <div class="section3-content">
          <ul>
            <li>√ 团队后台域名定制</li>
            <li>√ 短信OEM，定制后平台所有短信均以团队名称发送</li>
            <li>√ 商户后台域名定制</li>
            <li>√ 商户APP（Android+IOS）应用定制，包含名称、logo</li>
            <li>√ 商户PC后台定制，支持OEM后logo和头像自定义上传</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="el-section-card" ref="scrollFour">
      <div class="el-content-width">
        <div class="section-title">
          <h2 class="el-font-color333">独立部署</h2>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">多元化服务类型全面满足用户</h1>
        <div class="section4-content">
          <ul class="el-flex">
            <li class="li-odd">
              <img
                src="../../../assets/icon/product_icon/service_btm_1.png"
                alt=""
              />
              <p>需求沟通</p>
            </li>
            <li class="li-even">
              <img
                src="../../../assets/icon/product_icon/service_btm_2.png"
                alt=""
              />
              <p>评估开发周期与人员配置</p>
            </li>
            <li class="li-odd">
              <img
                src="../../../assets/icon/product_icon/service_btm_3.png"
                alt=""
              />
              <p>签约打款</p>
            </li>
            <li class="li-even">
              <img
                src="../../../assets/icon/product_icon/service_btm_4.png"
                alt=""
              />
              <p>设计功能产品原型</p>
            </li>
            <li class="li-odd">
              <img
                src="../../../assets/icon/product_icon/service_btm_5.png"
                alt=""
              />
              <p>UI设计出图</p>
            </li>
            <li class="li-even">
              <img
                src="../../../assets/icon/product_icon/service_btm_6.png"
                alt=""
              />
              <p>技术人员开发</p>
            </li>
            <li class="li-odd">
              <img
                src="../../../assets/icon/product_icon/service_btm_7.png"
                alt=""
              />
              <p>功能测试</p>
            </li>
            <li class="" style="background: #333; color: #fff">
              <img
                src="../../../assets/icon/product_icon/service_btm_8.png"
                alt=""
              />
              <p>上线交付</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
    <Cnav />
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>
  </div>
</template>
<script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";
export default {
  components: { Header, Cnav, Footer, toastFrame },
  data() {
    return {
      toastShow: false,
    };
  },
  methods: {
    toNav(e) {
      console.log(e);
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.offsetHeight;
      let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
      if (e == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (e == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (e == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (e == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      }
    },
  },
};
</script>
<style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */

  background: linear-gradient(
    320deg,
    rgb(39 248 202) 0%,
    rgb(104 122 253) 61%,
    #42fff8 100%
  );
  background-size: cover;
  background-position: center;
  height: 460px;
  width: 100%;
}
.banner-content {
  position: relative;
}
.banner-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 49%;
}
.banner-img img {
  position: absolute;
  right: 0;
  bottom: -40px;
  width: 96%;
  height: 560px;
}
.banner-items {
  width: 40%;
  height: 460px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #00a4d5;
  color: #00a4d5;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #00a4d5;
  color: #00a4d5;
  margin-left: 20px;
}
.el-section-card {
  width: 100%;
  padding: 30px 0;
}
.section-title {
  margin: 20px auto;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    320deg,
    rgb(39 248 202) 0%,
    rgb(104 122 253) 61%,
    #42fff8 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.section1-content ul li {
  height: 270px;
  width: 23%;
  padding: 20px 30px;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgb(244, 244, 244) 60%,
    rgb(243, 243, 243) 100%
  );
  box-shadow: 0px 5px 15px 0px #e4e4e46f;
}
.section1-content ul li:hover {
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 60%,
    rgb(255, 255, 255) 100%
  );
  box-shadow: 0px 5px 15px 0px #8f8f8f6f;
}
.section1-content {
  padding: 60px 0;
}
.section1-content li img {
  width: 34px;
  height: 34px;
  margin: 0px 0;
}
.section1-content li p {
  margin: 10px 0;
  font-size: 14px;
  line-height: 20px;
}
.section1-content li .img-bg {
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 20px auto;
  padding: 6px;
  border-radius: 50%;
}
.color-1 {
  box-shadow: 0px 5px 15px 0px #ebd247c3;
  background: linear-gradient(180deg, #ffcc24 0%, #f8be08 61%, #ffcc27 100%);
}
.color-2 {
  box-shadow: 0px 5px 15px 0px #53c8ce;
  background: linear-gradient(180deg, #53c8ce 0%, #53c8ce 61%, #53c8ce 100%);
}
.color-3 {
  box-shadow: 0px 5px 15px 0px #67bff5b3;
  background: linear-gradient(180deg, #67bef5 0%, #67bef5 61%, #67bef5 100%);
}
.color-4 {
  box-shadow: 0px 5px 15px 0px #fc7c45b9;
  background: linear-gradient(180deg, #fc7d45 0%, #fc7d45 61%, #fc7d45 100%);
}
.section2-content {
  margin: 50px 0;
}
.section2-content ul li {
  position: relative;
  width: 32%;
  border-radius: 15px;
  background: url("../../../assets/image/index_banner_3.jpg") no-repeat;
  overflow: hidden;
  background-size: cover;
  height: 280px;
}
.section2-content ul li span {
  margin: 0 auto;
  display: block;
  width: 160px;
  padding: 10px 15px;
  border-radius: 0 0 10px 10px;
  background-color: #0084d7d1;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
}
.section2-content ul li p {
  position: absolute;
  bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  padding: 30px 20px 20px 20px;
  /* background-color: #33333351; */
  background: linear-gradient(
    180deg,
    #33333300 0%,
    #2424242f 50%,
    #00000092 100%
  );
}
.section-bg {
  background: linear-gradient(180deg, #eef5ff 0%, #e6f2ff 61%, #e5e6ff 100%);
}

.section3-content {
  margin: 60px;
}
.section3-content ul li {
  background-color: #fff;
  width: 70%;
  padding: 20px;
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 0px #a8acd8d8;
}
.section4-content {
  padding: 60px 0 0;
}
.section4-content ul li {
  width: 20%;
  padding: 30px 18px;
  border-radius: 10px;
  border: 1px solid #fff;
  text-align: left;
}
.section4-content ul li p {
  font-size: 17px;
  font-weight: 700;
}
.section4-content ul li img {
  width: 27px;
  height: 25px;
  margin-bottom: 10px;
}
.section4-content ul .li-odd {
  position: relative;
  background-color: #c000ff91;
  color: #333;
}
.section4-content ul .li-odd::before {
  position: absolute;
  right: -11px;
  top: 66px;
  content: "";
  background-color: #db6eff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  z-index: 999;
}
.section4-content ul .li-even {
  position: relative;
  background-color: #333;
  color: #fff;
}
.section4-content ul .li-even::before {
  position: absolute;
  right: -11px;
  top: 66px;
  content: "";
  background-color: #333;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  z-index: 999;
}
@media only screen and (max-width: 800px) {
  .el-banner-card {
    margin: auto;
    /* global 94%+ browsers support */
    background: linear-gradient(
      320deg,
      rgb(39 248 202) 0%,
      rgb(104 122 253) 61%,
      #42fff8 100%
    );
    background-size: cover;
    background-position: center;
    height: 320px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 320px;
    padding: 70px 0;
    text-align: left;
  }
  .banner-items h1 {
    color: #fff;
    font-size: 27px;
    font-weight: bold;
  }
  .banner-items p {
    color: #fff;
    margin: 20px 0;
  }
  .banner-button {
    margin: 70px 0;
  }
  .banner-button-1 {
    padding: 16px 20px;
    background-color: #fff;
    border: solid 1px #00a4d5;
    color: #00a4d5;
  }
  .banner-button-2 {
    padding: 16px 20px;
    background-color: #fff;
    border: solid 1px #00a4d5;
    color: #00a4d5;
    margin-left: 20px;
  }
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .el-section-card {
    padding: 20px 0;
  }
  .section1-content ul li {
    height: 270px;
    width: 48%;
    padding: 11px 11px;
    margin-bottom: 16px;
    border-radius: 15px;
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255) 0%,
      rgb(244, 244, 244) 60%,
      rgb(243, 243, 243) 100%
    );
    box-shadow: 0px 5px 15px 0px #e4e4e46f;
  }
  .section1-content ul li:hover {
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 60%,
      rgb(255, 255, 255) 100%
    );
    box-shadow: 0px 5px 15px 0px #8f8f8f6f;
  }
  .section1-content {
    padding: 60px 0;
  }
  .section1-content li img {
    width: 34px;
    height: 34px;
    margin: 0px 0;
  }
  .section1-content li p {
    margin: 10px 0;
    font-size: 12px;
    line-height: 20px;
  }
  .section1-content li .img-bg {
    width: 50px;
    height: 50px;
    text-align: center;
    margin: 20px auto;
    padding: 6px;
    border-radius: 50%;
  }
  .color-1 {
    box-shadow: 0px 5px 15px 0px #ebd247c3;
    background: linear-gradient(180deg, #ffcc24 0%, #f8be08 61%, #ffcc27 100%);
  }
  .color-2 {
    box-shadow: 0px 5px 15px 0px #3bf25dbd;
    background: linear-gradient(180deg, #49d763 0%, #49d763 61%, #49d763 100%);
  }
  .color-3 {
    box-shadow: 0px 5px 15px 0px #67bff5b3;
    background: linear-gradient(180deg, #67bef5 0%, #67bef5 61%, #67bef5 100%);
  }
  .color-4 {
    box-shadow: 0px 5px 15px 0px #fc7c45b9;
    background: linear-gradient(180deg, #fc7d45 0%, #fc7d45 61%, #fc7d45 100%);
  }
  .section2-content {
    margin: 50px 0;
  }
  .section2-content ul li {
    position: relative;
    width: 48%;
    border-radius: 15px;
    background: url("../../../assets/image/index_banner_3.jpg") no-repeat;
    overflow: hidden;
    background-size: cover;
    height: 280px;
  }
  .section2-content ul li span {
    margin: 0 auto;
    display: block;
    width: 110px;
    padding: 10px 15px;
    border-radius: 0 0 10px 10px;
    background-color: #0084d7d1;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }
  .section2-content ul li p {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    padding: 30px 20px 20px 20px;
    /* background-color: #33333351; */
    background: linear-gradient(
      180deg,
      #33333300 0%,
      #2424242f 50%,
      #00000092 100%
    );
  }
  .section-bg {
    background: linear-gradient(180deg, #eef5ff 0%, #e6f2ff 61%, #e5e6ff 100%);
  }

  .section3-content {
    margin: 30px 0;
  }
  .section3-content ul li {
    background-color: #fff;
    width: 96%;
    padding: 15px;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0px 5px 15px 0px #a8acd8d8;
  }
  .section4-content {
    padding: 60px 0 0;
  }
  .section4-content ul li {
    width: 20%;
    padding: 20px 7px;
    border-radius: 10px;
    border: 1px solid #fff;
    text-align: left;
  }
  .section4-content ul li p {
    font-size: 12px;
    font-weight: 700;
  }
  .section4-content ul li img {
    width: 27px;
    height: 25px;
    margin-bottom: 7px;
  }
  .section4-content ul .li-odd {
    position: relative;
    background-color: #c000ff91;
    color: #333;
  }
  .section4-content ul .li-odd::before {
    position: absolute;
    right: -7px;
    top: 100px;
    content: "";
    background-color: #db6eff;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: 999;
  }
  .section4-content ul .li-even {
    position: relative;
    background-color: #333;
    color: #fff;
  }
  .section4-content ul .li-even::before {
    position: absolute;
    right: -7px;
    top: 100px;
    content: "";
    background-color: #333;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: 999;
  }
}
</style>