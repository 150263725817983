<template>
  <div class="section-card-box el-section9" ref="scrollTwo">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-bottom' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <div :style="{'--sectionWith' : sectionWith}" class="section9-content" :class="isShow ? 'silde-top' : ''">
        <ul class="el-flex-between" v-if="isMobile()">
          <li v-for="(item, index) in content.list.slice(0, 2)" :key="index">
            <img :src="item.img" alt="" />
            <div class="section9-msg">
              <h3>{{ item.name }}</h3>
              <p>
                {{ item.desc }}
              </p>
            </div>
          </li>
        </ul>
        <ul class="el-flex-around el-flex-wrap" v-else>
          <li v-for="(item, index) in content.list" :key="index">
            <img :src="item.img" alt="" />
            <div class="section9-msg">
              <h3>{{ item.name }}</h3>
              <p>
                {{ item.desc }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
    <script >
export default {
  name: "sectionNine",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
	data(){
		return{
				sectionWith: "27%"
		}
	},
	created() {
		let lenght = this.content.list.length
		if (lenght == 2) {
			this.sectionWith = "45%"
		}
		if (lenght == 4 || lenght > 6) {
			this.sectionWith = "23%"
		}
	}
};
</script>
    <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.el-section9 {
  background: url("../../assets/image/test_section_bg.jpg");
  background-size: 190%;
  background-repeat: no-repeat;
}
.section9-content {
  width: 100%;
  margin: 60px;
}
.section9-content ul li {
  width: var(--sectionWith, "27%");
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #0000001a;
	margin-top: 20px;
}
.section9-content ul li img {
  width: 100%;
  height: 160px;
}
.section9-msg {
  padding: 20px;
  text-align: left;
}
.section9-msg h3 {
  margin-bottom: 10px;
  color: #333;
}
.section9-msg p {
  font-size: 15px;
  color: #666;
  line-height: 25px;
}
.section9-msg img {
  width: 45%;
  height: 350px;
}
.section9-msg .msg-content {
  width: 50%;
}
.section9-msg .msg-content p {
  padding: 35px 0;
  text-align: left;
}
.section9-msg .msg-content li {
  width: 45%;
  padding: 10px;
  margin: 5px 0;
  border: #777777 solid 1px;
  border-radius: 5px;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }

  .el-section9 {
    background: url("../../assets/image/test_section_bg.jpg");
    background-repeat: no-repeat;
  }
  .section9-content {
    width: 100%;
    margin: 5px 0;
  }
  .section9-content ul li {
    width: 48%;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px #0000001a;
  }
  .section9-msg {
    padding: 5px;
    margin-top: 0px;
    text-align: center;
  }
  .section9-msg h3 {
    margin-bottom: 10px;
    color: #333;
  }
  .section9-msg p {
    font-size: 10px;
    color: #666;
  }
  .section9-msg img {
    width: 45%;
    height: 350px;
  }
  .section9-msg .msg-content {
    width: 50%;
  }
  .section9-msg .msg-content p {
    padding: 35px 0;
    text-align: left;
  }
  .section9-msg .msg-content li {
    width: 45%;
    padding: 10px;
    margin: 5px 0;
    border: #777777 solid 1px;
    border-radius: 5px;
  }
  .section9-content ul li img {
    width: 100%;
    height: 74px;
  }
}
</style>