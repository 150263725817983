<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-left' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <img class="section5-img" :src="content.img" alt="" v-if="isMobile()" />
      <div
        class="section5-content el-flex-between"
        :class="isShow ? 'slide-right' : ''"
      >
        <ul
          class="ul1"
          :class="isMobile() ? 'el-flex-column' : 'el-flex-column-left'"
        >
          <li
            :class="index % 2 == 0 ? 'li-right' : ''"
            v-for="(item, index) in content.list_one"
            :key="index"
          >
            <h2
              :style="{ color: item.fontColor, backgroundColor: item.bgColor }"
            >
              {{ item.name }}
            </h2>
            <p>{{ item.desc }}</p>
          </li>
        </ul>
        <img :src="content.img" alt="" v-if="!isMobile()" />
        <ul
          class="ul2"
          :class="isMobile() ? 'el-flex-column' : 'el-flex-column-left'"
        >
          <li
            :class="index % 2 == 0 ? 'li-left' : ''"
            v-for="(item, index) in content.list_two"
            :key="index"
          >
            <h2
              :style="{ color: item.fontColor, backgroundColor: item.bgColor }"
            >
              {{ item.name }}
            </h2>
            <p>{{ item.desc }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script >
export default {
  name: "sectionFive",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
};
</script>
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section5-content {
  margin: 60px 0;
}
.section5-content img {
  width: 42%;
}
.section5-content .ul1 {
  width: 27%;
  text-align: right;
}
.section5-content .ul2 {
  width: 27%;
  text-align: left;
}
.section5-content ul li {
  margin-bottom: 60px;
}
.section5-content ul .li-right {
  margin-right: 60px;
}
.section5-content ul .li-left {
  margin-left: 40px;
}
.section5-content ul li h2 {
  width: 50%;
  padding: 5px;
  border-radius: 40px;
  font-size: 20px;
  background-color: #e9effb;
  text-align: center;
  color: #4a7ee6;
  margin: 10px 50%;
}
.section5-content .ul2 li h2 {
  margin: 10px 0;
}
.section5-content ul li p {
  font-size: 15px;
  color: #666;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section5-content {
    margin: 0 0 20px 0;
  }
  .section5-img {
    margin: 10px auto;
    width: 100%;
  }
  .section5-content img {
    width: 42%;
  }
  .section5-content .ul1 {
    width: 44%;
    text-align: left;
  }
  .section5-content .ul2 {
    width: 44%;
    text-align: left;
  }
  .section5-content ul li {
    margin-bottom: 20px;
  }
  .section5-content ul .li-right {
    margin-right: 0px;
  }
  .section5-content ul .li-left {
    margin-left: 0px;
  }
  .section5-content ul li h2 {
    width: 100%;
    padding: 5px;
    border-radius: 40px;
    font-size: 14px;
    background-color: #e9effb;
    text-align: center;
    color: #4a7ee6;
    margin: 10px;
  }
  .section5-content .ul2 li h2 {
    margin: 10px 0;
  }
  .section5-content ul li p {
    font-size: 10px;
    color: #666;
  }
}
</style>