<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-img" v-if="!isMobile()">
            <img src="../../../assets/image/product/storeProduct/banner1.png" alt="" />
          </div>
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐云商城构建企业级全终端+全场景电商解决方案</h1>
            <p>
              打造企业自己的京东+天猫商城模式，支持自营+多商家入驻，海量促销功能+直播带货
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
		<sectionThree :content="threeData" :isShow="two_show" ref="scrollTwo">
		</sectionThree>
    <sectionTwo
      :content="twoData"
      :isShow="three_show"
      ref="scrollThree"
    ></sectionTwo>		
    <sectionEight
      :isShow="four_show"
      ref="scrollFour"
      :content="eightData"
    ></sectionEight>	
    <sectionEleven
      :content="elevenData"
      :isShow="five_show"
      ref="scrollFive"
    ></sectionEleven>				
    <sectionFour
      :content="fourData"
      :isShow="six_show"
      ref="scrollSix"
    ></sectionFour>
    <sectionFive
      :content="fiveData"
      :isShow="seven_show"
      ref="scrollSeven"
    ></sectionFive>
    <sectionNine
      :content="nineData"
      :isShow="eight_show"
      ref="scrollEight"
    ></sectionNine>			
    <sectionFooter
      :content="footerData"
      :show="footer_show"
      ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionEight from "@/components/productSection/sectionEight.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionEight,
    sectionNine,
    sectionEleven,
    sectionFooter,
    toastFrame
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍",  "适用群体", "系统特色", "功能展示", "营销插件", "系统性能", "二次开发", "售后服务", "系统演示"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
			five_show: false,
			six_show: false,
			seven_show: false,
			eight_show: false,
			footer_show: false,
      navTop: false,
      oneData: {
        title: "系统介绍",
        title_two: "灵狐云商城系统介绍",
        desc: "灵狐云商城电商系统是固安灵狐网络科技有限公司倾心打造的多端一体化网络电商平台。帮助商家高效快捷的打造一款功能完善的购物应用，速度快，功能丰富，灵活扩展，轻松开启新零售，商城系统全渠道多终端覆盖，完善技术架构支撑企业级服务，支持开发+源码部署",
        banner: require("../../../assets/image/product/storeProduct/banner1_1.png"),
        fontColor: "#ff644d",
        borderColor: "#ff644d",
        list: [
          { name: "新零售社交营销", desc: "以消费者为中心构建社群营销模式，重构品牌与消费者的关系，从供需关系向社群化关系转变，社交赋能新零售竞争优势，结合企业业务架构进行全渠道内容升级，实现社交电商、私域运营等数字化管理经营。将用户转化为品牌资产，提升零售效率" },
          { name: "品牌连锁私域运营", desc: "由头部统筹领导、腰部功能主导、尾部数据支撑形成三位一体的商城系统，实现企业线上线下一体化运营，全面提升企业全链路数字化运营能力。通过流量上行，促进平台用户裂变，引导流量下行，实现门店客流倍增，双管齐下稳固私域流量" },
          { name: "商户入驻千店千面", desc: "结合线上平台型商城+多个入驻商家形成“多合一”模式，是一种涵盖自营、联营、招商入驻等多种经营模式的电商平台，能够帮助企业整合行业、地区资源，低成本快速构建多店联营、本地O2O、社区电商、等网上商城系统" },
          { name: "知识内容价值变现", desc: "针对知识生态提供从引流、获客，到运营、转化的内容变现全链条解决方案，匹配课程、直播、资料、练习、考试、专题、专栏等多元化内容，用多种教学形式连接线下线上教学场景，打破地域和时间的限制，满足不同行业、不同人群的知识服务需求" },
        ],
      },
      twoData: {
        title: "系统特色",
        title_two: "灵狐云商城特色功能",
        desc: "",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "会员制分销",
            desc: "用户付费成为高级会员，并享受推广分拥权利，解决平台流量增长难题",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "多种营销模块",
            desc: "拼团、砍价、秒杀、积分等多种营销功能帮助商家精准运营，提高下单率",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "可视化装修",
            desc: "商城页面全部实现可视化装修模式，设置更便捷满足不同商家个性化需求",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "短视频+直播",
            desc: "支持商家短视频营销直播功能模块帮助商家直播带货完善平台业务生态",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "支持零售+批发",
            desc: "打造适合供应商、批发商的B2B平台，实现订单交易、物流配送一体化管理",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "手机管理后台",
            desc: "商家可以随时通过手机端掌握订单、店铺经营状况，管理更便捷高效",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "自定义专题页面",
            desc: "支持自定义专题页面，针对特定商品进行装修轻松拥有一个炫酷的专题页",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "提供源码",
            desc: "独立部署含价值千万源码，一次买断、企业终身授权",
          },
        ],
      },
      threeData: {
        title: "适用群体",
        title_two: "灵狐云商城适用群体",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/storeProduct/section1_1.png"),
            name: "传统百货商城",
            desc: "互联网时代，消费群体更喜欢线上购物，线下体验，通过大商创搭建电商平台，让线下百货商品销售触达全球",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section1_2.png"),
            name: "垂直行业整合",
            desc: "本地垂直行业繁多，创业者可以整合众多行业服务放到线上平台销售，搭建一个本地化一站式综合服务平台",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section1_3.png"),
            name: "打造自营品牌",
            desc: "通过搭建线上综合交易平台，针对性的为消费者提供服务，通过独特的经营模式和良好的商业口碑，打造自营品牌",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section1_4.png"),
            name: "中大型企业",
            desc: "大部分企业发展都离不开交易的环节，灵狐云商城为企业打造的不止于电商，更是现代数字化经济解决方案",
          },
        ],
      },
      fourData: {
        title: "",
        title_two: "重安全保障系统稳定，高性能提供优质体验",
        img: require("../../../assets/image/product/storeProduct/section4_1.png"),
        desc: "",
        list: [
          { name: "高性能", desc: "订单并发数实测值50000单/分，商品全文检索毫秒级响应，高性能提供优质用户体验" },
          { name: "重安全", desc: "安全开发规范，代码安全审计，主机安全扫描，应用安全渗透测试" },
          { name: "重产研", desc: "占比90%的产研团队，深研新开发技术，紧跟互联网的⾼速迭代，为客户提供安全、稳定的系统" },
          { name: "全服务", desc: "搭建售前、实施、售后各个任务模块团队，进行项目过程全服务" },
        ],
      },
      fiveData: {
        title: "二次开发",
        title_two: "助力开发者，提速企业数字化",
				desc: "系统性能优越，开发技术先进，有效助力开发者，提速企业数字化",
        img: require("../../../assets/image/product/storeProduct/five_1.png"),
        list_one: [
          {
            name: "极易二开",
            desc: "后台标准接口、前后端分离，二次开发更方便",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "代码开源",
            desc: "代码开源、清晰，对有二次开发需求的客户十分友好",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "高性能框架",
            desc: "基于laravel8.X+ Swoole4+Uniapp 开发的高性能系统框架",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "独立部署",
            desc: "私有化独立安装部署，信息独立保存，数据更安全",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "永久免费更新",
            desc: "一次购买即可享受系统永久免费更新，系统升级更便捷更省心",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "文档齐全",
            desc: "开发文档、接口文档、安装文档、使用文档全面支持，帮助用户快速安装使用",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      eightData: {
        title: "功能展示",
        title_two: "灵狐云商城功能展示",
        desc: "",
        functionList: [
          {
            name: "商城首页",
            icon: require("../../../assets/icon/product_icon/store_icon_1.png"),
            img: require("../../../assets/image/product/storeProduct/section8_1.png"),
          },
          {
            name: "分类列表",
            icon: require("../../../assets/icon/product_icon/store_icon_2.png"),
            img: require("../../../assets/image/product/storeProduct/2.png"),
          },
          {
            name: "购物车",
            icon: require("../../../assets/icon/product_icon/store_icon_3.png"),
            img: require("../../../assets/image/product/storeProduct/3.png"),
          },
          {
            name: "限时秒杀",
            icon: require("../../../assets/icon/product_icon/store_icon_4.png"),
            img: require("../../../assets/image/product/storeProduct/4.png"),
          },
          {
            name: "拼团活动",
            icon: require("../../../assets/icon/product_icon/store_icon_5.png"),
            img: require("../../../assets/image/product/storeProduct/5.png"),
          },
          {
            name: "个人中心",
            icon: require("../../../assets/icon/product_icon/store_icon_6.png"),
            img: require("../../../assets/image/product/storeProduct/6.png"),
          },
          {
            name: "商品详情",
            icon: require("../../../assets/icon/product_icon/store_icon_7.png"),
            img: require("../../../assets/image/product/storeProduct/7.png"),
          },
          {
            name: "我的订单",
            icon: require("../../../assets/icon/product_icon/store_icon_8.png"),
            img: require("../../../assets/image/product/storeProduct/8.png"),
          },
          {
            name: "用户充值",
            icon: require("../../../assets/icon/product_icon/store_icon_9.png"),
            img: require("../../../assets/image/product/storeProduct/9.png"),
          },
          {
            name: "优惠券",
            icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
            img: require("../../../assets/image/product/storeProduct/10.png"),
          },
        ],
      },
      nineData: {
        title: "",
        title_two: "全面售后服务，再无后顾之忧",
        desc: "私有化部署、SAAS两种销售模式可选，根据客户需求灵活选择",
        list: [
          {
            img: require("../../../assets/image/product/storeProduct/section9_1.png"),
            name: "资料支持",
            desc: "从服务器配置到程序上线，完整视频教程系统讲解，开发、接口、安装、使用文档全面支持，助用户安装使用",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section9_2.png"),
            name: "专属会员群",
            desc: "从服务器配置到程序上线，完整视频教程系统讲解 &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section9_3.png"),
            name: "免费升级",
            desc: "系统永久免费更新，及时获取新功能",
          },
        ],
      },
      elevenData: {
        title: "",
        title_two: "公域引流用户积累沉淀，私域运营有效提升销量",
        title_three: "营销插件",
        desc: "",
        desc_two: "线上引流、到店消费；线下获客、线上转化；多种营销模式组合，打通线上线下营销壁垒，形成销售闭环，有效提升销售转化",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "拼团活动",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "限时秒杀",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "分销体系",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "砍价活动",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "优惠券",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "积分商城",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "优惠套餐",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "积分体系",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "限时折扣",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "商品限购",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "会员特权",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "余额充值",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "满减满赠",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
        ],
      },
      footerData: {
				title: "系统演示",
        bgColor: "#ff644d",
        hrefList: [
          {
            name: "商户后台地址：",
            href: "https://www.foxmall.online/merchant",
          },
          {
            name: "文档地址：",
            href: "https://docs.foxonline.net/docs/",
          },
        ],
        codeList: [
          {
            name: "零售商城",
            img: require("../../../assets/image/product/storeProduct/gh_001.jpg"),
          },
					{
						name: "元气茶呀",
						img: require("../../../assets/image/product/storeProduct/gh_002.jpeg"),
					},
					{
						name: "员工端",
						img: require("../../../assets/image/product/storeVipcard/gh_store.jpg"),
					},
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐云商城构建企业级全终端+全场景电商解决方案", //分享标题
      desc: "打造企业自己的京东+天猫商城模式，支持自营+多商家入驻，海量促销功能+直播带货", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
      let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
      let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
      let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
			let eightHeight = this.$refs.scrollEight.$el.clientHeight + sevenHeight;
      let footerHeight = this.$refs.scrollFooter.$el.clientHeight + eightHeight;
      
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      } else if (index == 5) {
        document.documentElement.scrollTop = fiveHeight;
        document.body.scrollTop = fiveHeight;
      } else if (index == 6) {
        document.documentElement.scrollTop = sixHeight;
        document.body.scrollTop = sixHeight;
      } else if (index == 7) {
        document.documentElement.scrollTop = sevenHeight;
        document.body.scrollTop = sevenHeight;
      } else if (index == 8) {
        document.documentElement.scrollTop = eightHeight;
        document.body.scrollTop = eightHeight;
      } else if (index == 9) {
        document.documentElement.scrollTop = footerHeight;
        document.body.scrollTop = footerHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
				let headerHeight = this.$refs.scrollHeader.offsetHeight;
				let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
				let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
				let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
				let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
				let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
				let eightHeight = this.$refs.scrollEight.$el.clientHeight + sevenHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + eightHeight;
				
        if (scrollTop > headerHeight) {
          this.navTop = true;
					this.current = 0;
        }
        if (scrollTop == 0) {
          this.navTop = false;
					this.current = 0;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
					this.current = 1;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
					this.current = 2;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
					this.current = 3;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
					this.current = 4;
        }
				if (scrollTop > fiveHeight) {
					this.five_show = true;
					this.current = 5;
				}
				if (scrollTop > sixHeight) {
					this.six_show = true;
					this.current = 6;
				}
				if (scrollTop > sevenHeight) {
					this.seven_show = true;
					this.current = 7;
				}
				if (scrollTop > eightHeight) {
					this.eight_show = true;
					this.current = 8;
				}
				if (scrollTop > footerHeight) {
					this.footer_show = true;
					this.current = 9;
				}
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    40deg,
    rgb(255, 62, 45) 10%,
    rgb(253, 128, 93) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-img h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ff5439;
  color: #ff5439;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ff5439;
  color: #ff5439;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    40deg,
    rgb(255, 62, 45) 10%,
    rgb(253, 128, 93) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #ff5439;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			40deg,
			rgb(255, 62, 45) 10%,
			rgb(253, 128, 93) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #ff5439;
		color: #ff5439;
	}
}
</style>