<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
				<div class="el-flex-between">
					<div class="banner-items">
						<h1 class="el-font-colorfff">灵狐科技优质的社区团购解决方案</h1>
						<p>
							线上团购+线下自提，玩转社区消费新模式，提供专业、优质的社区团购解决方案
						</p>
						<div class="banner-button el-flex">
							<div class="banner-button-1" @click="toastShow = true">
								获取详情
							</div>
							<!-- <div class="banner-button-2">立即联系</div> -->
						</div>
					</div>
					<div class="banner-img" v-if="!isMobile()">
						<img src="../../../assets/image/product/storeFresh/banner_1.png" alt="" />
					</div>
				</div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :isShow="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :isShow="four_show"
      ref="scrollFour"
    ></sectionFour>
    <sectionFive
      :content="fiveData"
      :isShow="five_show"
      ref="scrollFive"
    ></sectionFive>
    <sectionEleven
      :content="elevenData"
      :isShow="six_show"
      ref="scrollSix"
    ></sectionEleven>
    <sectionFooter
      :content="footerData"
      :isShow="footer_show"
      ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
		<toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionEleven,
    sectionFooter,
		toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "系统特点", "适用群体", "功能介绍", "系统特色", "特色功能", "系统演示"],
      current: 0,
      one_show: true,
      two_show: false,
      three_show: false,
      four_show: false,
      five_show: false,
      six_show: false,
			footer_show: false,
      navTop: false,
      oneData: {
        title: "系统介绍",
        title_two: "社区团购的核心特点优势",
        desc: "社区团购是一种全新的团购模式，平台统一提供相应产品、通过小区团长、辐射小区用户。社区用户在小程序下单后、平台可直接发货至用户，或用户至团长处自提。平台通过【预售+拼团】，拼团以销定产，降低损耗和成本、用户可得到真正的实惠。",
        banner: require("../../../assets/image/product/storeFresh/section_1.png"),
        fontColor: "#1dcb1d",
        borderColor: "#1dcb1d",
        list: [
          { name: "增加复购", desc: "通过微信群聚合用户，增加社群复购率。" },
          { name: "易于推广", desc: "邻里身份的社群，信任感强，更易于推广传播" },
          { name: "减少损耗", desc: "货源直采、社区直供模式，省去中间环节，利率更高" },
          { name: "降低风险", desc: "先收款再进货，商家没有资金压力，降低库存风险" },
        ],
      },
      twoData: {
        title: "系统特点",
        title_two: "网上商城系统，选我们的理由",
        desc: "特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能",
        imageBgColor: "#defedcf2",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_1.png"),
            name: "更新迭代",
            desc: "顺应互联网时代发展，不断迭代更新",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_2.png"),
            name: "提供源码",
            desc: "提供PHP商城系统源码",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_3.png"),
            name: "高级会员",
            desc: "提供PHP商城系统源码",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_4.png"),
            name: "接受二次开发",
            desc: "可根据个性化需求定制开发商城系统",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_5.png"),
            name: "24小时客服",
            desc: "客服7*24小时在线，紧急情况处理",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_6.png"),
            name: "装修指导",
            desc: "对商家店铺装修制作进行引导和协助",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_7.png"),
            name: "在线培训",
            desc: "提供商城制作使用手册、帮助文档等",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_8.png"),
            name: "多终端覆盖",
            desc: "系统支持PC+移动各个终端",
          },
        ],
      },
      threeData: {
        title: "适用群体",
        title_two: "适用群体",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/storeGroup/section_1.png"),
            name: "小区连锁物业",
            desc: "连锁物业资源较多，可以针对物业覆盖的小区，成立社区团购群，引导居民团购消费",
          },
          {
            img: require("../../../assets/image/product/storeGroup/section_2.png"),
            name: "货源供应商",
            desc: "供应商能够为社区店提供一手货源，可以通过合作发展社区团购业务来拓展销售渠道",
          },
          {
            img: require("../../../assets/image/product/storeGroup/section_3.png"),
            name: "小区零售门店",
            desc: "零售门店深耕社区服务，客流量大，可以整合多方面资源，多方位合作打通社区团购链路",
          },
          {
            img: require("../../../assets/image/product/storeGroup/section_4.png"),
            name: "生鲜配送企业",
            desc: "生鲜配送企业具有稳定的供应链，货源、仓库，物流一手掌控，对接C端消费者即可开展团购业务",
          },
        ],
      },
      fourData: {
        title: "功能介绍",
        title_two: "做社区团购，选择灵狐科技的三个理由",
        img: require("../../../assets/image/test_bg_1.png"),
        desc: "深入了解社区团购企业，做更贴合市场功能需求的社区团购系统",
        list: [
					{ name: "深入了解社区团购企业", desc: "灵狐网络科技帮你“排忧解难”" },
          { name: "全方位提升企业盈利能力", desc: "[营销工具+裂变推广+会员管理+数据分析+财务管理]运营体系，加速企业在社区团购市场抢占红利！" },
          { name: "与团长紧密连接，提升积极性", desc: "[移动化管理+佣金立即可见+团长业绩PK+团长自营]实现平台与团长紧密连接，让团长更卖力推广！" },
          { name: "提升采购效率，节约配送成本", desc: "[对接供应商+智能采购+规划配送路线+多维度配送单导出]，精准采购，降低配送成本，运营更省力！" },
        ],
      },
      fiveData: {
        title: "系统特色",
        title_two: "强大的研发实力，安全可靠的系统支持",
        img: require("../../../assets/image/product/storeGroup/section5_1.png"),
        list_one: [
          {
            name: "提供系统源码",
            desc: "商家拥有源码，轻松掌握商城的运营权",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "节省运营成本",
            desc: "生鲜配送系统-SAAS服务商 生鲜配送系统源码，生鲜批发百货配送系统商城源码是一套成熟的电商系统省去上百万的开发费用",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "支持定制开发",
            desc: "根据企业的需求个性化定制商城功能",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "系统持续更新",
            desc: "每周都有新的系统更新，开发永无止境",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "系统适用广泛",
            desc: "系统可广泛适用于各行各业",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "系统稳定可靠",
            desc: "系统已持续开发三年多，累计服务100+客户",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      elevenData: {
        title: "特色功能",
        title_two: "灵狐社区团特色功能",
        title_three: "",
        desc: "多样的插件功能，玩转社区团购",
        desc_two: "丰富的插件与营销功能、提升商城系统营销转化与用户沉淀",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "会员充值",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "推荐商品",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "商品秒杀",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "优惠券",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "全民拼团",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "商品预售",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
        ],
      },
      footerData: {
				title: "系统演示",
        bgColor: "#0bce0b",
        hrefList: [
          {
            name: "商户后台：",
            href: "https://www.foxmall.online/merchant",
          },
          {
            name: "说明文档：",
            href: "https://docs.foxonline.net",
          },
        ],
        codeList: [
          {
            name: "灵狐社区团购演示",
            img: require("../../../assets/image/product/storeGroup/qrcode.png"),
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐科技社区团购系统优质的社区团购解决方案", //分享标题
      desc: "线上团购+线下自提，玩转社区消费新模式，提供专业、优质的社区团购解决方案", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
      let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
      let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
      let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sixHeight;
			
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      } else if (index == 5) {
        document.documentElement.scrollTop = fiveHeight;
        document.body.scrollTop = fiveHeight;
      } else if (index == 6) {
        document.documentElement.scrollTop = sixHeight;
        document.body.scrollTop = sixHeight;
      } else if (index == 7) {
        document.documentElement.scrollTop = footerHeight;
        document.body.scrollTop = footerHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
				let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sixHeight;

				if (scrollTop > headerHeight) {
					this.navTop = true;
					this.current = 0;
				}
				if (scrollTop == 0) {
					this.navTop = false;
					this.current = 0;
				}
				if (scrollTop > oneHeight) {
					this.one_show = true;
					this.current = 1;
				}
				if (scrollTop > twoHeight) {
					this.two_show = true;
					this.current = 2;
				}
				if (scrollTop > threeHeight) {
					this.three_show = true;
					this.current = 3;
				}
				if (scrollTop > fourHeight) {
					this.four_show = true;
					this.current = 4;
				}
				if (scrollTop > fiveHeight) {
					this.five_show = true;
					this.current = 5;
				}
				if (scrollTop > sixHeight) {
					this.six_show = true;
					this.current = 6;
				}
				if (scrollTop > footerHeight) {
					this.footer_show = true;
					this.current = 7;
				}	
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    320deg,
    rgb(24, 211, 90) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgba(92, 255, 172, 1) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
  font-size: 36px;
  font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0bce0b;
  color: #0bce0b;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0bce0b;
  color: #0bce0b;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    320deg,
    rgb(32, 212, 95) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgb(37, 255, 143) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #0bce0b;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			320deg,
			rgb(24, 211, 90) 0%,
			rgba(55, 219, 111, 1) 61%,
			rgba(92, 255, 172, 1) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #0bce0b;
		color: #0bce0b;
	}
}
</style>