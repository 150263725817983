<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-img" v-if="!isMobile()">
            <img src="../../../assets/image/product/storeProduct/banner1.png" alt="" />
          </div>
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐CRM（客户关系管理）系统</h1>
            <p>
              灵狐CRM实现企业『销售+管理』全流程客户关系管理系统的前沿创新
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :show="four_show"
      ref="scrollFour"
    ></sectionFour>
    <sectionFive
      :content="fiveData"
      :show="three_show"
      ref="scrollFive"
    ></sectionFive>
    <sectionSix
      :content="sixData"
      :show="three_show"
      ref="scrollSix"
    ></sectionSix>
    <sectionSeven
      :content="sevenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionSeven>
    <sectionNine
      :content="nineData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionNine>
    <sectionFooter
      :content="footerData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionNine,
    sectionFooter,
    toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "功能展示", "系统特色", "主要功能", "系统优势", "适用行业", "系统演示"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      oneData: {
        title: "",
        title_two: "灵狐CRM 实现企业【销售+管理】全流程",
        desc: "客户关系管理系统的前沿创新",
        banner: require("../../../assets/image/product/crm/section1_1.png"),
        fontColor: "#ffab0e",
        borderColor: "#ffab0e",
        /* list: [
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
        ], */
      },
      twoData: {
        title: "",
        title_two: "灵狐CRM功能展示",
        desc: "",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "线索管理",
            desc: "有效的线索管理是产生更多销售机会的最有效途径",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "客户管理",
            desc: "客户管理，把控全局",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "联系人管理",
            desc: "智能联系提醒，提升跟进效率",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "商机管理",
            desc: "对商机按照不同阶段进行分类、跟进、推进",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "合同管理",
            desc: "随时随地记录、跟踪和控制订单执行过程",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "回款管理",
            desc: "设置合同回款计划，有序的记录每一笔回款金额",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "发票管理",
            desc: "打造票、税一体化解决方案",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "市场活动",
            desc: "从获客过程打通整个客户来源及业务过程",
          },
        ],
      },
      threeData: {
        title: "",
        title_two: "为什么使用灵狐CRM?",
        desc: "解决中小企业找客难、转化低、管理混乱的问题，全面提升销售全流程运营效率",
        list: [
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "多渠道营销触达",
            desc: "通过短信、朋友圈H5、电销、语音外呼机器人，多渠道营销获客",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "高效精准获客",
            desc: "融合搜客宝2.4亿企业数据库，数千个维度筛选精准客户，为企业拓客降本增效",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "精准客户转化",
            desc: "制定销售计划、拜访计划、回款计划，时刻推动销售转化高价值客户",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "数据统计报表",
            desc: "灵活匹配公司业务，通过部门回款、销售排名、业绩统计等报表，提升销售管理时效",
          },
        ],
      },
      fourData: {
        title: "",
        title_two: "客户管理的主要功能",
        img: require("../../../assets/image/test_bg_1.png"),
        desc: "",
        list: [
          { name: "客户管理，把控全局", desc: "助力销售全流程，通过对客户初始信息、跟进过程、 关联商机、合同等的全流程管理，与客户建立紧密的联系， 帮助销售统筹规划每一步，赢得强有力的竞争力优势" },
          { name: "线索管理产生更多销售机会", desc: "从线索的获取,筛选到转换为潜在客户，每一步都可 根据线索的跟进情况作出下一步正确的决策，把握更多 高质量线索" },
          { name: "高效推进商机，快速达成成交", desc: "对商机不同阶段进行分类、跟进，快速了解商机动态的同时， 助您多维度、跨销售结构地高效管理商机" },
          { name: "合同/订单", desc: "随时跟踪订单完成情况、控制订单实际执行、即时反馈订单异常，做到随时随地记录。跟踪和控制订单执行过程" },
					{ name: "回款发票", desc: "设置合同回款计划，有序的记录每一笔回款金额" },
					{ name: "强大的自定义字段", desc: "CRM系统自定义功能强大，通过自定义某个模块可以将不需要的字段隐藏掉，也可以增加自己的需要的字段" },
					{ name: "客户分析", desc: "针对CRM中的客户数据进行分析，形成数据建议报告，为企业管理人员制定经营管理方法提供了一定的决策依据" },
        ],
      },
      fiveData: {
        title: "",
        title_two: "灵狐CRM为您提供全方位服务",
        img: require("../../../assets/image/store_fresh_img_1.png"),
        list_one: [
          {
            name: "安全可靠",
            desc: "拥有安全的数据中心及防护机制，确保企业的CRM数据安全",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "移动应用",
            desc: "利用灵狐CRM手机APP，销售可随时随地查找客户信息。处理销售活动，实现移动办公，让工作更加高效",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "数据迁移",
            desc: "灵狐CRM使用非常简单，任何用户都可以快速上手。通过数据迁移可以将电子表格或将其他CRM系统的数据，轻松导入到灵狐CRM",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "客户服务",
            desc: "灵狐提供全流程的客户服务，确保客户CRM软件的成功使用。通过互动式的服务方式，保障客户无忧",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "开源+云服务",
            desc: "灵狐CRM价格公开透明，无隐形费用，您可以按月或按年购买，同时没有最低用户数的限制",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "售后保障",
            desc: "灵狐CRm将为您提供7*24小时的售后支持服务，确保您的任何问题都能及时解决",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      nineData: {
        title: "",
        title_two: "灵狐CRM为您定制专属解决方案",
        desc: "灵狐CRM产品涉及30多种行业解决方案，为您专注场景，量身定制",
        list: [
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "互联网行业",
            desc: "通过任务、活动管理、邮件或短信提醒、日历等工具， 灵狐CRM可帮助企业及时跟进商机",
          },
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "汽车行业",
            desc: "把汽车销售公司内部管理业务纳入统一的平台上，消除 信息孤岛，整合业务流程，使业务能够快速反应",
          },
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "家电行业",
            desc: "集成呼叫中心与派单功能，从售前到售后整个流程实时 把控，帮助企业实现全流程信息化管理",
          },
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "教育行业",
						desc: "打通从招生、学员到缴费整个业务流程管理，实现 教室、教师、排课等全方位资源管理",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "物流行业",
						desc: "客户行业、地区、来源等不同维度进行分类管理，提供 行业针对性的物流服务",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "医疗器械行业",
						desc: "有效管理重要医疗机构、相关决策人及现有医疗器械配 置情况，帮助销售员人员找对关键人",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "金融租赁行业",
						desc: "灵狐CRM能够有机结合融资租赁公司现有业务员资源 优势与运行机制，提供公司总体竞争力",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "贸易行业",
						desc: "灵狐CRM与邮箱服务深度集成,在CRM中直接收发 邮件，更可群发邮件实现自动化邮件营销",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "食品行业",
						desc: "建立详细的渠道经销商信息资料和对应的联系人信息资 料，并详细记录历史所有沟通信息和订单信息",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "旅行社行业",
						desc: "通过悟空CRM，公司可定时发布线路信息，了解线路 收客情况，线路订单回款等信息",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "餐饮行业",
						desc: "通过悟空CRM充分了解客户的品性、饮食习惯、个性 爱好等，以及对客户关系维持的具体措施",
					},
					{
						img: require("../../../assets/image/test_bg_2.png"),
						name: "房地产行业",
						desc: "房地产公司进行前期置业信息收集已成为招募会员的重 要环节，悟空CRM可帮助企业管理潜在客户信息",
					},
        ],
      },
      footerData: {
        bgColor: "#0ee7ff",
        hrefList: [
          {
            name: "系统演示地址：",
            href: "https://crm.foxonline.net",
          },
          {
            name: "文档地址地址：",
            href: "https://docs.foxonline.net/docs/foxcrm/",
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐CRM（客户关系管理）系统", //分享标题
      desc: "灵狐CRM实现企业『销售+管理』全流程客户关系管理系统的前沿创新", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;
      console.log(this.$refs.scrollOne.$el.clientHeight);
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
        }
        if (scrollTop == 0) {
          this.navTop = false;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(33, 180, 234) 0%,
    rgb(26, 207, 192) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #009daf;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #009daf;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(33, 180, 234) 0%,
    rgb(26, 207, 192) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #09b6ca;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			90deg,
			rgb(33, 180, 234) 0%,
			rgb(26, 207, 192) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #0ee7ff;
		color: #09b6ca;
	}
}
</style>