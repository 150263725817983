<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div class="slide-bottom" :class="isShow ? 'slide-bottom' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <div class="section1-content slide-top" :class="isShow ? '' : ''">
        <img
          class="section1-img"
          :src="content.banner"
          alt=""
        />
        <ul class="el-flex-between el-flex-wrap">
          <li v-for="(item, index) in content.list" :key="index">
            <h3
              :style="{
                color: content.fontColor,
                borderBottomColor: content.borderColor,
              }"
            >
              {{ item.name }}
            </h3>
            <p>
              {{ item.desc }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "sectionOne",
  props: {
    isShow: { type: Boolean, required: false },
    content: {
      type: Object,
      require: {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section1-content {
  margin-top: 60px;
}
.section1-content .section1-img {
  width: 100%;
}
.section1-content ul {
  margin: 40px 0;
}
.section1-content ul li {
  width: 40%;
  padding: 20px;
  margin: 10px 0;
  border-radius: 15px;
  box-shadow: 3px 15px 15px 1px #83838341;
  color: #333;
}
.section1-content ul li h3 {
  color: #ffab0e;
  padding-bottom: 15px;
  border-bottom: #ffcb6b 2px solid;
}
.section1-content ul li p {
  margin-top: 15px;
  line-height: 25px;
  font-size: 15px;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section1-content {
    margin: 60px 0;
  }
  .section1-content .section0-img {
    width: 100%;
  }
  .section1-content ul {
    margin: 20px 0;
  }
  .section1-content ul li {
    width: 47%;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 3px 15px 15px 1px #e0c52b41;
    color: #333;
    background-color: #fff;
  }
  .section1-content ul li h3 {
    color: #ffab0e;
    padding-bottom: 10px;
    border-bottom: #ffcb6b 2px solid;
  }
  .section1-content ul li p {
    margin-top: 10px;
    line-height: 25px;
    font-size: 10px;
  }
}
</style>