const mobile = {
    isMobile() {
        // 是否为手机端
        var Mobile = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        return Mobile;
    }
};
export default { isMobile: mobile.isMobile };
