<template>
  <div>
    <Header :num="2" />
    <!-- 轮播图start -->
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '300px' : '520px'"
      >
        <el-carousel-item v-for="(item, index) in 3" :key="index">
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h2 class="el-title-font-size">
                    深耕本地生活服务市场历经上千合作平台实战打磨
                  </h2>
                  <span class="banner-span"></span>
                  <!-- <el-button round size="medium">立刻咨询</el-button> -->
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图end -->
    <!-- 案例导航start -->
    <div class="el-content-width el-case-nav">
      <ul class="el-flex-between">
        <li class="el-flex-column">
          <img
            src="../../../assets/icon/case_icon/nav_business.png"
            alt=""
          /><span>业务系统</span>
        </li>
        <li class="el-flex-column">
          <img src="../../../assets/icon/case_icon/nav_mini.png" alt="" /><span
            >小程序</span
          >
        </li>
        <li class="el-flex-column">
          <img src="../../../assets/icon/case_icon/nav_app.png" alt="" /><span
            >手机App</span
          >
        </li>
        <li class="el-flex-column">
          <img src="../../../assets/icon/case_icon/nav_web.png" alt="" /><span
            >网站建设</span
          >
        </li>
      </ul>
    </div>
    <!-- 案例导航结束 -->
    <div class="section-card-box">
      <div class="el-content-width">
        <h1 class="el-font-color333 el-title-font-size">案例展示</h1>
        <div class="el-section-title"></div>
        <p class="el-font-color999 font-style">
          由专业的团队提供支持，在保证安全性和可靠性的前提下，提供了更高质量的服务，可以满足企业各类需求。
          包含各种不同的产品和服务，适用于企业不同的需求。企业可以选择根据实际需要购买所需的服务，并灵活调整使用频率和规格。这种定制化能够更好地满足企业在不同时期的需求。
        </p>
        <div class="el-flex-between section1-content">
          <div class="section1-nav">
            <ul>
              <li
                :class="current == index ? 'li-hover' : ''"
                v-for="(item, index) in caseList"
                :key="index"
                @mouseenter="getCurrent(index, item)"
              >
                <h2>{{ item.title }}</h2>
              </li>
            </ul>
          </div>
          <div class="section1-item">
            <el-carousel
              indicator-position="inside"
              trigger="hover"
              type="card"
              :autoplay="false"
              :height="isMobile() ? '140px' : '240px'"
            >
              <el-carousel-item
                v-for="(item, index) in selectItem.imgList"
                :key="index"
              >
                <img :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
            <div class="desc">
              <span style="font-weight: 700">案例介绍:</span>
              <p>
                {{ selectItem.desc }}
              </p>
            </div>
            <ul class="el-flex-between">
              <li class="el-flex-column">
                <img src="../../../assets/icon/case_icon/c_icon_1.png" alt="" />
                <span>高性能</span>
              </li>
              <li class="el-flex-column">
                <img src="../../../assets/icon/case_icon/c_icon_2.png" alt="" />
                <span>高性能</span>
              </li>
              <li class="el-flex-column">
                <img src="../../../assets/icon/case_icon/c_icon_3.png" alt="" />
                <span>高性能</span>
              </li>
              <li class="el-flex-column">
                <img src="../../../assets/icon/case_icon/c_icon_4.png" alt="" />
                <span>高性能</span>
              </li>
              <li class="el-flex-column">
                <img src="../../../assets/icon/case_icon/c_icon_5.png" alt="" />
                <span>高性能</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="section-card-box">
      <h1 class="el-font-color333 el-title-font-size">灵狐网络科技-专业的软件开发公司</h1>
      <div class="el-section-title"></div>
      <div class="el-content-width">
        <ul class="section2-content">
          <li class="section2-item" style="margin-left: 0px">
            <div class="item-title el-align-center">
              <img
                src="../../../assets/icon/case_icon/s_icon_1.png"
                alt=""
              /><span>一站式平台搭建</span>
            </div>
            <span
              >全程技术与售后平台搭建，绑定自己的收款账户，3-5个工作日成果交付，直接上手运营。</span
            >
          </li>
          <li class="section2-item" style="margin-left: 80px">
            <div class="item-title el-align-center">
              <img
                src="../../../assets/icon/case_icon/s_icon_4.png"
                alt=""
              /><span>保姆式售后指导</span>
            </div>
            <span
              >专人售后一对一服务，提供详细的操作文档与视频资料，5*12h在线指导，随时解疑答惑。</span
            >
          </li>
          <li class="section2-item" style="margin-left: 150px">
            <div class="item-title el-align-center">
              <img
                src="../../../assets/icon/case_icon/s_icon_3.png"
                alt=""
              /><span>运营指导方案</span>
            </div>
            <span
              >提供专业的市场运营指导方案、免费提供商家、骑手合作协议模板与用户使用协议模板。</span
            >
          </li>
          <li class="section2-item" style="margin-left: 230px">
            <div class="item-title el-align-center">
              <img
                src="../../../assets/icon/case_icon/s_icon_2.png"
                alt=""
              /><span>专业技术支持</span>
            </div>
            <span
              >免费提供系统维护，以及后续平台系统的功能更新迭代，7*24h运维、高级防护全方位守护。</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <h1 class="el-font-color333 el-title-font-size">品牌量身定制</h1>
        <div class="el-section-title"></div>
        <ul class="el-flex-wrap section3-content el-flex-between">
          <li class="el-flex-column">
            <img src="../../../assets/icon/case_icon/make_1.png" alt="" />
            <span>国家科技型中小企业</span>
            <p>
              国家级技术资质认定，权威、专业、信任。
            </p>
          </li>
          <li class="el-flex-column">
            <img src="../../../assets/icon/case_icon/make_2.png" alt="" />
            <span>独立知识产权</span>
            <p>
              灵狐网络科技自主研发的软件产品，拥有独立知识产权。
            </p>
          </li>
          <li class="el-flex-column">
            <img src="../../../assets/icon/case_icon/make_3.png" alt="" />
            <span>自主商标产权</span>
            <p>
              灵狐网络科技独立商标产权，拥有独立品牌优势。
            </p>
          </li>
          <li class="el-flex-column">
            <img src="../../../assets/icon/case_icon/make_4.png" alt="" />
            <span>500+合作案例</span>
            <p>
              服务客户遍布国内外多个区域，覆盖校园、城市、乡镇。
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width el-section4">
        <h1 class="el-font-color333 el-title-font-size">我们的合作伙伴</h1>
        <div class="el-section-title"></div>
        <p>
          实力出众的合作伙伴，给你带来稳定技术支撑，为您的创业之路保价护航
        </p>
        <div class="section4-content">
          <ul class="el-flex-between">
            <li>
              <img src="../../../assets/image/partner/par_1.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/image/partner/par_2.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/image/partner/par_4.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/image/partner/par_5.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/image/partner/par_3.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/image/partner/par_8.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Cnav></Cnav>
    <Footer></Footer>
  </div>
</template>
<script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";

export default {
  name: "HelloWorld",
  components: { Header, Cnav, Footer },
  data() {
    return {
      caseList: [
        {
          title: "业务系统",
          imgList: [
            require("../../../assets/image/case/1.png"),
            require("../../../assets/image/case/2.png"),
            require("../../../assets/image/case/3.png"),
            require("../../../assets/image/case/4.png"),
          ],
          icon: "",
          desc: "业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统",
          icon_name: "",
        },
        {
          title: "小程序",
          imgList: [
            require("../../../assets/image/case/1.png"),
            require("../../../assets/image/case/2.png"),
            require("../../../assets/image/case/3.png"),
            require("../../../assets/image/case/4.png"),
          ],
          icon: "",
          desc: "小程序小程序小程序小程序小程序小程序小程序",
          icon_name: "",
        },
        {
          title: "手机App",
          imgList: [
            require("../../../assets/image/case/1.png"),
            require("../../../assets/image/case/2.png"),
            require("../../../assets/image/case/3.png"),
            require("../../../assets/image/case/4.png"),
          ],
          icon: "",
          desc: "手机App手机App手机App手机App手机App手机App手机App",
          icon_name: "",
        },
        {
          title: "网站建设",
          imgList: [
            require("../../../assets/image/case/1.png"),
            require("../../../assets/image/case/2.png"),
            require("../../../assets/image/case/3.png"),
            require("../../../assets/image/case/4.png"),
          ],
          icon: "",
          desc: "网站建设网站建设网站建设网站建设网站建设网站建设",
          icon_name: "",
        },
      ],
      selectItem: {
        title: "业务系统",
        imgList: [
					require("../../../assets/image/case/5.png"),
					require("../../../assets/image/case/6.png"),
					require("../../../assets/image/case/2.png"),
					require("../../../assets/image/case/3.png"),
        ],
        icon: "",
        desc: "业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统业务系统",
        icon_name: "",
      },
      current: 0,
    };
  },
  mounted() {
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—客户案例", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index, item) {
      this.current = index;
      this.selectItem = item;
    },
  },
};
</script>
<style scoped>
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/index_banner_3.jpg");
  background-size: cover;
  background-position: center;
  height: 520px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 520px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 100%;
  height: 300px;
  margin: auto 0;
  text-align: left;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.font-style {
  line-height: 30px;
  margin: 30px 0;
  font-size: 15px;
}

.el-case-nav {
  margin-top: 20px;
  padding: 10px 0;
  color: #777777;
  background-color: #fff;
}
.el-case-nav img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.el-section-title {
  width: 130px;
  height: 6px;
  margin: 30px auto;
  background: linear-gradient(
    28deg,
    rgb(251, 148, 79) 18%,
    rgb(249, 170, 66) 100%
  );
}
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section1-content {
  margin-top: 60px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  padding: 30px;
}
.section1-nav {
  width: 25%;
}
.section1-nav li {
  height: 80px;
  line-height: 80px;
  border-radius: 50px;
  margin: 30px;
  background-color: #fff8dc;
}
.section1-nav li :hover {
  border-radius: 40px;
  box-shadow: 0px 5px 15px 0px #fda96c1a;
  background-color: #ffd17a;
}
.section1-nav .li-hover {
  border-radius: 40px;
  box-shadow: 0px 5px 15px 0px #fda96c1a;
  background-color: #ffd17a;
}
.section1-item {
  width: 78%;
  text-align: left;
  padding: 20px;
}
.section1-item p {
  font-size: 15px;
  line-height: 25px;
}
.section1-item .desc {
  line-height: 25px;
}
.section1-item img {
  width: 100%;
  height: 300px;
}
.section1-item ul {
  margin-top: 30px;
}
.section1-item ul img {
  width: 45px;
  height: 45px;
  margin: 0 0 10px 5px;
}
.section2-content {
  padding: 60px 20px;
}
.section2-item {
  border: #e8e8e8 solid 1px;
  border-radius: 20px;
  padding: 20px 30px;
  margin: 5px 0;
  width: 80%;
  text-align: left;
}
.section2-item:hover {
  box-shadow: 0px 5px 15px 0px #3d26151a;
}
.section2-item .item-title {
  margin-bottom: 10px;
}
.section2-item .item-title img {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}
.section2-item .item-title span {
  font-size: 20px;
  font-weight: 700;
}
.section3-content {
  margin-top: 60px;
}
.section3-content li {
  width: 44%;
  padding: 30px;
  margin: 30px;
  box-shadow: 0px 5px 15px 0px #3d26151a;
}
.section3-content img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.section3-content span {
  font-weight: 700;
  font-size: 18px;
  margin: 10px 0;
  color: #414141;
}
.section3-content p {
  line-height: 25px;
  color: #414141;
}
.section4-content ul li {
  width: 15%;
  /* height: 90px; */
}
.section4-content ul li img {
  width: 100%;
  height: 100%;
  /* border: #c8c8c8 solid 1px; */
}
.el-section4 p {
  width: 60%;
  font-size: 15px;
  color: #666;
  margin: 30px auto;
}
@media only screen and (max-width: 800px) {
}
</style>