<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-img" v-if="!isMobile()">
            <img src="../../../assets/image/product/finance/banner1.png" alt="" />
          </div>
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐财务系统 全新财务管理模式</h1>
            <p>
              以企业财务管理人员为主，提供全面财务管理功能，实现客户-销售-库存-财务一体化智能管理
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :scrollOne="four_show"
      ref="scrollFour"
    ></sectionFour>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "主要功能", "业务价值", "系统优势"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      oneData: {
        title: "",
        title_two: "全新财务管理模式",
        desc: "",
        banner: require("../../../assets/image/product/finance/section1_1.png"),
        fontColor: "#0286FE",
        borderColor: "#0286FE",
        list: [
          { name: "什么是FS？", desc: "FS即财务管理（Financial Management System）" },
          { name: "中小型企业如何寻找合适财务系统实现数字化？", desc: "成长型企业需要合适的技术和解决方案来实现数字化以提高销售额和生产力，并令回报最大化" },
        ],
      },
      twoData: {
        title: "",
        title_two: "财务管理的主要功能",
        desc: "完善的功能体系，提升企业财务人员效率",
        imageBgColor: "#A7D5FE",
        list: [
          {
            icon: require("../../../assets/image/product/finance/icon1.png"),
            name: "凭证管理",
            desc: "您不仅可以手动录入凭证，也可以将CRM及进销存中的单据自动生成凭证",
          },
          {
            icon: require("../../../assets/image/product/finance/icon2.png"),
            name: "账簿管理",
            desc: "强大的的账簿功能，让您快速了解各项财务指标",
          },
          {
            icon: require("../../../assets/image/product/finance/icon3.png"),
            name: "财务报表管理",
            desc: "资产负债表利润表现金流量表自动生成，提供应交税金明细表费用明细表",
          },
          {
            icon: require("../../../assets/image/product/finance/icon4.png"),
            name: "结账管理",
            desc: "快捷的结账功能，保证每月财务数据安全有效",
          },
          {
            icon: require("../../../assets/image/product/finance/icon5.png"),
            name: "固定资产管理",
            desc: "强大的固定资产管理功能，严密管控财产数据，防止财产损失",
          },
          {
            icon: require("../../../assets/image/product/finance/icon6.png"),
            name: "财务出纳管理",
            desc: "您可以通过出纳模块进行日记账、银行对账单的管理",
          },
          {
            icon: require("../../../assets/image/product/finance/icon7.png"),
            name: "科目管理",
            desc: "精细化的科目管理，实现企业财务管理新模式",
          },
					{
						icon: require("../../../assets/image/product/finance/icon8.png"),
						name: "财务系统设置",
						desc: "以财务管理创新为和新，打造“经营+管理”的一体化解决方案",
					},
        ],
      },
      threeData: {
        title: "业务价值",
        title_two: "财务管理的业务价值",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/finance/section3_1.png"),
            name: "全员财务",
            desc: "赋能企业中的每一个人，让每个人都能拥有自己的业务信息和财务信息，让数据服务于每个人，实现员工自我驱动",
          },
          {
            img: require("../../../assets/image/product/finance/section3_2.png"),
            name: "AI智能财务",
            desc: "嵌入AI数据分析，实现智能的财务报表、财务报表分析，解放财务生产力、创造更大价值",
          },
          {
            img: require("../../../assets/image/product/finance/section3_3.png"),
            name: "业务财务",
            desc: "打破财务与业务之间的壁垒，让数据从单一的财务系统结合进销存、人力资源管理、客户管理系统等让信息可以自由的流通",
          },
          {
            img: require("../../../assets/image/product/finance/section3_4.png"),
            name: "战略财务",
            desc: "向未来价值发掘者转变，将真正变成未来发展的方向，在具体繁杂业务中抽身，转而关注未来的筹划和价值创造",
          },
        ],
      },
      fourData: {
        title: "",
        title_two: "企业财务管理轻松高效",
        img: require("../../../assets/image/product/finance/section4_1.png"),
        desc: "",
        list: [
          { name: "一键生成凭证 让财务工作更轻松", desc: "银企互联，下载银行流水，实时查询余额；收支汇总表，全面掌握资金收支，规避资金风险；独立于财务结账，实时锁定业务，使权责明确化" },
          { name: "多维账表分析 指导企业经营决策", desc: "数量、外币、核算项目多维账表查询；企业经营状况一目了然，满足企业精细化核算需求" },
          { name: "全面资金管控，及时掌握企业资金流", desc: "银企互联，下载银行流水，实时查询余额；收支汇总表，全面掌握资金收支，规避资金风险；独立于财务结账，实时锁定业务，使权责明确化" },
          { name: "发票台账式管理，财税一体省时省力", desc: "一键导入发票，解决票财脱节的难题；一键生成凭证，实现财税无缝连接；自动测算企业税负，并给出节税建议" },
					{ name: "随时随地记账查账 方便快捷", desc: "支持APP，小程序多端记账查账，关键数据及时掌握；拍照、扫码识别发票，并根据用户记账习惯智能匹配凭证模板，智能记账" },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐财务系统 全新财务管理模式", //分享标题
      desc: "以企业财务管理人员为主，提供全面财务管理功能，实现客户-销售-库存-财务一体化智能管理", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;

      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
					this.current = 0
        }
        if (scrollTop == 0) {
          this.navTop = false;
					this.current = 0
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
					this.current = 1
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
					this.current = 2
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
					this.current = 3
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
					this.current = 4
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(2,134,254) 0%,
    rgb(14,231,255) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #0286FE;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #0286FE;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(2,134,254) 0%,
    rgb(14,231,255) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #0286FE;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			90deg,
			rgb(2,134,254) 0%,
			rgb(14,231,255) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #0ee7ff;
		color: #0286FE;
	}
}
</style>