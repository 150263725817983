<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="banner-items">
          <h1 class="el-font-colorfff">灵狐网络科技 · 外卖跑腿O2O系统</h1>
          <p>
            外卖系统整体解决方案，帮您打造类似美团/饿了么的独立外卖平台
          </p>
          <div class="banner-button el-flex">
            <div class="banner-button-1" @click="toastShow = true">获取详情</div>
            <!-- <div class="banner-button-2">立即联系</div> -->
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
		<sectionTen
			:content="tenData"
			:show="four_show"
			ref="scrollFour"
		></sectionTen>
  <sectionEight
      :show="five_show"
      ref="scrollFive"
      :content="eightData"
    ></sectionEight>
		<sectionFive
			:content="fiveData"
			:show="six_show"
			ref="scrollSix"
		></sectionFive>	
    <sectionFooter
      :content="footerData"
      :show="footer_show"
      ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
		<toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionTen from "@/components/productSection/sectionTen.vue";
import sectionEight from "@/components/productSection/sectionEight.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
		sectionTen,
		sectionEight,
		sectionFive,
    sectionFooter,
		toastFrame
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "运营后台", "市场定位", "终端介绍", "功能展示", "运营模式", "演示地址"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
			five_show: false,
			six_show: false,
			seven_show: false,
      navTop: false,
      oneData: {
        title: "系统介绍",
        title_two: "同城系统餐饮外卖解决方案",
        desc: "打造类似美团/饿了么的优质外卖平台致力于实现本地生活信息化，提供全套解决方案",
        banner: require("../../../assets/image/product/takeaway/section_1.png"),
        fontColor: "#ffab0e",
        borderColor: "#ffab0e",
        list: [
          { name: "同城外卖系统", desc: "对标美团平台，融合同城生活多行业，覆盖本地全业态商圈，为创业者打造自主品牌的本地O2O生活平台。" },
          { name: "本地跑腿配送系统", desc: "适用于打造本地跑腿配送平台，支持多元化服务场景自定义设置，可对接美团、饿了么、抖音等第三方自配送订单,搭建万能跑腿配送服务平台，全面赋能本地配送创业者。" },
          { name: "校园外卖平台系统", desc: "打造学校移动掌上生活圈，支持学生创业者整合校内外餐饮美食商家，给学生提供线上点餐、外卖配送全套生活服务，打造独立品牌的智慧校园平台" },
          { name: "本地团购系统", desc: "借鉴抖音团购、大众点评运营模式，充分整合本地资源，实现线上购买线下核销，通过优惠折扣和网络红利，为实体门店消费引流。" },
        ],
      },
      twoData: {
        title: "运营后台",
        title_two: "功能强大的运营后台",
        desc: "高效、易用、功能强大的运营后台，才是外卖创业者的首选！",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "门店管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "商品管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "订单管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "配送管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "会员管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "数据管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "财务管理",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "营销管理",
            desc: "",
          },
        ],
      },
      threeData: {
        title: "市场定位",
        title_two: "目标市场定位",
        desc: "",
        list: [
					{
						img: require("../../../assets/image/test_section1_1.png"),
						name: "一二线城市",
						desc: "一二线城市线城市是新商业模式的「试金石」，虽然存在已有互联网巨头的强大竞争压力，但在外卖市场的众多垂直细分领域仍然有机会",
					},
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "同城市场",
            desc: "收外卖巨头高额佣金影响，越来越多的玩家开拓新的同城外卖渠道，可挖掘的潜力巨大",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "三四线及区县",
            desc: "外卖市场的整体下沉趋势明显，凭借自身的本地资源优势建立独立品牌可迅速获得并站稳市场份额",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "校园市场",
            desc: "高效是特殊的需求市场，在全国拥有数万之多，只要能够在示范高效取得成功便能迅速推向全国",
          },
        ],
      },
      fiveData: {
        title: "运营模式",
        title_two: "外卖O2O运营模式优势",
        img: require("../../../assets/image/store_fresh_img_1.png"),
        list_one: [
          {
            name: "全平台操作",
            desc: "可在PC、微信公众号、APP、小程序、外卖小程序等全平台进行操作，多渠道开展O2O模式",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "就近送达",
            desc: "LBS定位匹配就近店铺，最近配送团队信息获取，轻松搞定店铺订单配送问题",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "信息互通",
            desc: "订单信息、配送信息、会员交易信息等多种类型信息实时查询，多端同步",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "低成本营销",
            desc: "系统0抽成，平台收益自由，线上多样营销工具助力获客、转化、提升复购",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "功能完善",
            desc: "外卖平台系统多终端+多产品服务支持，功能+运营体系完善，减轻投资压力",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "多行业支持",
            desc: "餐饮、生鲜、零售多行业支持，从下单+发单+配送+结算等多流程提供解决方案",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      eightData: {
        title: "功能展示",
        title_two: "亮点功能汇总",
        desc: "",
        functionList: [
          {
            name: "用户首页",
            icon: require("../../../assets/icon/product_icon/store_icon_1.png"),
            img: require("../../../assets/image/product/takeaway/1.png"),
          },
          {
            name: "商家登录",
            icon: require("../../../assets/icon/product_icon/store_icon_2.png"),
            img: require("../../../assets/image/product/takeaway/2.png"),
          },
          {
            name: "入驻邀请",
            icon: require("../../../assets/icon/product_icon/store_icon_3.png"),
            img: require("../../../assets/image/product/takeaway/3.png"),
          },
          {
            name: "骑手端",
            icon: require("../../../assets/icon/product_icon/store_icon_4.png"),
            img: require("../../../assets/image/product/takeaway/4.png"),
          },
          {
            name: "美食团购",
            icon: require("../../../assets/icon/product_icon/store_icon_5.png"),
            img: require("../../../assets/image/product/takeaway/5.png"),
          },
          {
            name: "财务管理",
            icon: require("../../../assets/icon/product_icon/store_icon_6.png"),
            img: require("../../../assets/image/product/takeaway/6.png"),
          },
          {
            name: "数据分析",
            icon: require("../../../assets/icon/product_icon/store_icon_7.png"),
            img: require("../../../assets/image/product/takeaway/7.png"),
          },
          {
            name: "抢单大厅",
            icon: require("../../../assets/icon/product_icon/store_icon_8.png"),
            img: require("../../../assets/image/product/takeaway/8.png"),
          },
          {
            name: "骑手状态",
            icon: require("../../../assets/icon/product_icon/store_icon_9.png"),
            img: require("../../../assets/image/product/takeaway/9.png"),
          },
          {
            name: "骑手调度",
            icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
            img: require("../../../assets/image/product/takeaway/10.png"),
          },
        ],
      },
      tenData: {
        title: "终端介绍",
        title_two: "灵狐外卖跑腿系统终端介绍",
        title_center: "外卖跑腿系统",
        desc: "打通外卖业务全流程，让平台业务处理更高效！",
        bgColor_1: "#ffc6fa",
        bgColor_2: "#ff9bf8",
        bgColor_3: "#ff7ae7",
        list_one: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "用户端",
            desc: "清晰便捷的下单入口，丰富多样的营销玩法，升级用户体验",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "商家端",
            desc: "便捷商户订单、商品、分类等设置，详细经营数据分析",
          },
        ],
        list_two: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "骑手端",
            desc: "骑手路线实时定位记录,骑手公告、骑手通知，提升平台管理骑手的效率",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "运营管理",
            desc: "高效、易用、功能强大的运营后台，才是外卖创业者的首选！",
          },
        ],
      },
      footerData: {
				title: "演示地址",
				bgColor: "#ff9208",
        codeList: [
					{
						name: "用户端",
						img: require("../../../assets/image/product/takeaway/app.png"),
					},
					{
						name: "商家端",
						img: require("../../../assets/image/product/takeaway/foxwaimai-shop.png"),
					},
					{
						name: "骑手端",
						img: require("../../../assets/image/product/takeaway/foxwaimai-deliveryer.png"),
					}
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技 · 外卖跑腿O2O系统", //分享标题
      desc: "外卖系统整体解决方案，帮您打造类似美团/饿了么的独立外卖平台", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
			let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
			let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
			let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sixHeight;
      
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      } else if (index == 5) {
        document.documentElement.scrollTop = fiveHeight;
        document.body.scrollTop = fiveHeight;
      } else if (index == 6) {
        document.documentElement.scrollTop = sixHeight;
        document.body.scrollTop = sixHeight;
      } else if (index == 7) {
        document.documentElement.scrollTop = footerHeight;
        document.body.scrollTop = footerHeight;
      }
    },
    handleScroll() {
     let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
				let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sixHeight;
				
        //let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				if (scrollTop > headerHeight) {
					this.navTop = true;
				}
				if (scrollTop == 0) {
					this.navTop = false;
				}
				if (scrollTop > oneHeight) {
					this.one_show = true;
				}
				if (scrollTop > twoHeight) {
					this.two_show = true;
				}
				if (scrollTop > threeHeight) {
					this.three_show = true;
				}
				if (scrollTop > fourHeight) {
					this.four_show = true;
				}
				if (scrollTop > fiveHeight) {
					this.five_show = true;
				}
				if (scrollTop > sixHeight) {
					this.six_show = true;
				}
				if (scrollTop > footerHeight) {
					this.footer_show = true;
				}	
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-img h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #ffab0e;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			40deg,
			rgb(255, 62, 45) 10%,
			rgb(253, 128, 93) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #ffcb6b;
		color: #ffcb6b;
	}
}
</style>