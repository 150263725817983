<template>
  <div class="el-footer">
    <div :class="isMobile() ? '.el-mobile-content-width' : 'el-content-width'">
      <div class="el-flex-between">
        <div class="el-flex-around" v-if="!isMobile()">
          <ul class="footer-items">
            <li><h1>产品介绍</h1></li>
            <li>灵狐云商城</li>
            <li>会员卡系统</li>
            <li>社区团购</li>
            <li>外卖跑腿系统</li>
          </ul>
          <ul class="footer-items">
            <li><h1>特色功能</h1></li>
            <li>会员分销</li>
            <li>门店核销</li>
            <li>商家入驻</li>
            <li>大数据高并发</li>
          </ul>
          <ul class="footer-items">
            <li><h1>解决方案</h1></li>
            <li>新零售</li>
            <li>社区团购</li>
            <li>外卖点餐</li>
            <li>企业应用</li>
          </ul>
          <ul class="footer-items">
            <li><h1>帮助支持</h1></li>
            <li @click="toHref('/aboutUs')">关于我们</li>
            <li @click="toHref('/functionList')">功能列表</li>
            <li @click="toHref('/updateLog')">更新日志</li>
            <li @click="toHref('/qustionPage')">常见问题</li>
          </ul>
        </div>
        <div class="footer-contact">
          <div class="el-align-center el-email">
            <i class="el-icon-phone"></i>
            <div class="el-email">
              <span>热线电话：</span>
              <span>{{ $config.APP_MOBILE }}</span>
            </div>
          </div>
          <div class="el-align-center el-email">
            <i class="el-icon-s-promotion"></i>
            <div class="el-email">
              <span>邮箱地址：</span>
              <span>{{ $config.APP_EMAIL }}</span>
            </div>
          </div>
          <div class="el-align-center el-email">
            <i class="el-icon-s-home"></i>
            <div class="el-email">
              <span>公司地址：</span>
              <span>{{ $config.APP_ADDRESS }}</span>
            </div>
          </div>
          <ul class="el-wechat el-flex">
            <li class="el-wechat-img">
              <img :src="$config.APP_WX_CODE" alt="" />
              <span>微信二维码</span>
            </li>
            <li>
              <img :src="$config.APP_WX_ACOUNT_CODE" alt="" />
              <span>微信公众号</span>
            </li>
            <li>
              <img :src="$config.APP_DY_CODE" alt="" />
              <span>抖音二维码</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="el-footer-link" v-if="!isMobile()">
        <ul class="el-flex-around">
          <li>
            <a @click="goToExternalSite('https://github.com/')">GitHub</a>
          </li>
          <li>
            <a @click="goToExternalSite('https://gitee.com/')">码云</a>
          </li>
          <li><a @click="goToExternalSite('https://www.bt.cn/')">宝塔</a></li>
          <li>
            <a @click="goToExternalSite('https://packagist.org/')">Packagist</a>
          </li>
          <li>
            <a @click="goToExternalSite('https://learnku.com/laravel')"
              >Laravel中文社区</a
            >
          </li>
          <li>
            <a
              @click="
                goToExternalSite(
                  'https://stackoverflow.com/questions/tagged/docusaurus'
                )
              "
              >Stack Overflow</a
            >
          </li>
        </ul>
      </div>
      <div class="el-footer-foot">
        <p>{{ this.$config.APP_PAGE_FOOTER }}</p>
        <div :class="isMobile() ? 'el-flex-column' : 'el-align-center'">
          <span>河北省廊坊市固安县永定路绿宸万华城</span>
          <a @click="goToExternalSite('https://beian.miit.gov.cn/')"
            >冀ICP备20002787号-2</a
          >
          <a
            class="el-align-center"
            :class="isMobile() ? 'mobile-margin' : ''"
            @click="
              goToExternalSite(
                'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13102202000554'
              )
            "
            ><img
              src="https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/beian_public_security.png"
              alt=""
            />冀公网安备 13102202000554号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LayoutFooter",
  data() {
    return {};
  },
  methods: {
    goToExternalSite(href) {
      window.open(href);
    },
    toHref(href) {
      this.$router.push({ path: href });
    },
  },
};
</script>
<style scoped>
.el-footer {
  width: 100%;
  background-color: #222222;
  z-index: 999;
}
.footer-items {
  text-align: center;
  color: #a8a8a8;
  padding: 40px 40px;
}
.footer-items li {
  margin: 15px 0;
  font-size: 14px;
}
.footer-items li:hover {
  color: #cdcdcd;
  cursor: pointer;
}
.footer-items li h1 {
  padding-bottom: 17px;
}
.el-footer-link {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-top: #474747 solid 1px;
  border-bottom: #454545 solid 1px;
  cursor: pointer;
}
.el-footer-link a {
  color: #a8a8a8;
  font-size: 14px;
  text-decoration: none;
}
.el-footer-link a:hover {
  color: #cdcdcd;
}
.footer-contact {
  width: 30%;
}
.footer-contact i {
  margin-right: 5px;
}
.el-email {
  color: #a8a8a8;
  font-size: 14px;
  margin-bottom: 5px;
}
.el-wechat {
  margin-top: 10px;
}
.el-wechat li {
  margin: 0 10px;
}
.el-wechat img {
  width: 80px;
  margin-bottom: 10px;
}
.el-wechat span {
  display: block;
  color: #a8a8a8;
  font-size: 14px;
}
.el-footer-foot {
  padding: 30px 0;
  text-align: center;
  color: #a8a8a8;
  font-size: 14px;
}
.el-footer-foot a {
  cursor: pointer;
}
.el-footer-foot div {
  justify-content: center;
}
.el-footer-foot div img {
  margin-right: 5px;
}
.el-footer-foot a {
  color: #c3c3c3;
  text-decoration: none;
  margin-left: 15px;
}
.el-footer-foot a:hover {
  color: #dadada;
}
.el-footer-foot p {
  margin-bottom: 10px;
}
[class^="el-icon-"] {
  margin-bottom: 3px;
}
@media only screen and (max-width: 800px) {
  .footer-contact {
    width: 100%;
    margin-top: 20px;
  }
  .el-wechat li {
    width: 30%;
    font-size: 11px;
  }
  .el-wechat img {
    width: 100%;
  }
  .el-footer {
    width: 100%;
    padding-bottom: 80px;
    background-color: #222222;
  }
  .el-email {
    font-size: 11px;
  }
  .el-footer-foot {
    padding: 20px 0;
    text-align: center;
    color: #a8a8a8;
    font-size: 11px;
  }
  .el-footer-foot a {
    margin: 5px 0 0 0;
  }
}
</style>
