<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐进销存系统企业生意管理好帮手</h1>
            <p>
              高效、省心、高性价比的在线进销存软件，满足不同行业需求
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
		<sectionNine
			:content="nineData"
			:isShow="one_show"
			ref="scrollOne"
		></sectionNine>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :show="four_show"
      ref="scrollFour"
    ></sectionFour>
    <sectionFooter
      :content="footerData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionNine,
    toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "系统功能", "痛点解决", "核心优势"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      twoData: {
        title: "",
        title_two: "全面 轻松 管理供应链",
        desc: "",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/image/product/jxc/icon1.png"),
            name: "快速初始化",
            desc: "引导式新手向导，属性批量导入",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon2.png"),
            name: "智能APP",
            desc: "开单/查数据/打印单据/审批",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon3.png"),
            name: "商品搜索",
            desc: "名称、条码、型号规格灵活组合搜索",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon4.png"),
            name: "商品特性",
            desc: "商品多规格属性支持批次保质期、序列号、尺码颜色",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon5.png"),
            name: "客户管理",
            desc: "客户分层/流失预警/贡献分析",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon6.png"),
            name: "在线打印",
            desc: "灵活单据模板，在线打印单据",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon7.png"),
            name: "线上开店",
            desc: "打通智慧微店，营销实时获客",
          },
          {
            icon: require("../../../assets/image/product/jxc/icon8.png"),
            name: "发货跟踪",
            desc: "打通快递物流，有效提效发货",
          },
        ],
      },
      threeData: {
        title: "",
        title_two: "全方位解决买卖难题",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/jxc/section9_1.png"),
            name: "货物品类多，发货找不到",
            desc: "以单品为最小维度定义批次，精确定位库位，并提供拣货路径规划，配合PDA作业，提高发货效率",
          },
          {
            img: require("../../../assets/image/product/jxc/section9_3.png"),
            name: "库存总不准，采购不及时",
            desc: "多仓库多货主管理，线上线下库存数据自动同步统一处理，配合进销存系统补货采购策略数据分析，预测需求变化，从源头控制库存平衡",
          },
          {
            img: require("../../../assets/image/product/jxc/section9_2.png"),
            name: "客户复购率低，营销模式单一",
            desc: "提供40多种赠品策略，消息策略，回访策略，对客户数据统计分析，支持目标客户二次营销",
          },
          {
            img: require("../../../assets/image/product/jxc/section4_1.png"),
            name: "财务对账难度大，数据统计不准确",
            desc: "支持财务业务一体化，能够对接多款财务软件，及时自动对账，排除异常订单，账目数据精准",
          },
        ],
      },
      fourData: {
        title: "",
        title_two: "小微企业生意管理好帮手",
        img: require("../../../assets/image/product/jxc/section4_1.png"),
        desc: "",
        list: [
          { name: "订单驱动采购，减少积压", desc: "根据销售计划制定采购计划清单，合理控制库存，降低资金占用；系统计算建议采购量，一键生成购货订单" },
          { name: "多维库存管理参数，算得准", desc: "提供其他出入库、盘点、移仓调拨、成本调整等所有仓库管理功能；支持多种商品特性管理，灵活适配行业商品管理要求" },
          { name: "随时随地开单，销货高效", desc: "数据实时更新，销售APP即可扫码开单；自动带出客户等级及价格，价格策略灵活；云端打印出库单据，远程选仓发货" },
          { name: "简易资金对账，理得清", desc: "二维码在线支付，系统自动核销，一键分享对账单，轻松完成对账；支持通过业务单据一键生成凭证" },
					{ name: "智能经营分析，支撑决策", desc: "多维度的报表查询，全面了解业绩、资产、员工、客户；通过经营日报，随时随地获悉经营数据，精确决策" },
        ],
      },
      nineData: {
        title: "",
        title_two: "进销存管理的核心价值",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/jxc/section9_1.png"),
            name: "降低商家履约成本",
            desc: "快递仓库成本最优 仓储拣货路径最短",
          },
          {
            img: require("../../../assets/image/product/jxc/section9_2.png"),
            name: "提升作业协同效率",
            desc: "订单智能策略 波次/爆款打单",
          },
          {
            img: require("../../../assets/image/product/jxc/section9_3.png"),
            name: "提高企业管理效率",
            desc: "员工绩效 科学决策 数据报表",
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐进销存系统企业生意管理好帮手", //分享标题
      desc: "高效、省心、高性价比的在线进销存软件，满足不同行业需求", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;

      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
					this.current = 0
        }
        if (scrollTop == 0) {
          this.navTop = false;
					this.current = 0
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
					this.current = 1
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
					this.current = 2
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
					this.current = 3
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
					this.current = 4
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(2,134,254) 0%,
    rgb(14,231,255) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #0286FE;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0ee7ff;
  color: #0286FE;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(2,134,254) 0%,
    rgb(14,231,255) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #0286FE;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			90deg,
			rgb(2,134,254) 0%,
			rgb(14,231,255) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #0ee7ff;
		color: #0286FE;
	}
}
</style>