<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    if (this.isMobile()) {
      this.rem();
      // 动态监听屏幕尺寸变化从而修改font-size
      window.onresize = () => {
        this.rem();
      };
    }
  },
  methods: {
    rem() {
      const docEl = document.documentElement;
      window.rem = docEl.getBoundingClientRect().width / 50;
      docEl.style.fontSize = window.rem + "px";
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
