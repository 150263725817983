<template>
  <div v-show="toastShow">
    <div class="mask" id="mask" ref="mask">
      <div class="el-toast-box" ref="toast">
        <div class="el-toast-header el-align-center">
          <img class="header-img" src="../../assets/logo.png" alt="" />
          <div class="header-msg">
            <h3>您好，我是您的专属顾问</h3>
            <p>扫码添加我微信，获取更多产品资料</p>
          </div>
          <i class="el-icon-close" @click="toClose"></i>
        </div>
        <div class="el-toast-content">
          <img :src="$config.APP_WX_CODE" alt="" />
          <p>灵狐网络科技专业客户服务</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { toastShow: { type: Boolean, required: false } },
  data() {
    return {};
  },
  mounted() {
    this.$refs.mask.style.height =
      window.document.getElementById("app").clientHeight + "px";
  },
  methods: {
    toClose() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.mask {
  width: 100%;
  /* opacity: 0.6; */
  /* background-color: black; */
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 99;
  transition: opacity 0.5s ease-in;
}
.el-toast-header {
  padding: 25px 30px;
  background: linear-gradient(90deg, rgb(255, 163, 58) 0%, #f08519);
}
.header-img {
  width: 80px;
  height: 80px;
  margin-left: 20px;
  border-radius: 50%;
}
.header-msg {
  text-align: left;
  color: #fff;
}
.header-msg p {
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}
.el-toast-content img {
  width: 200px;
  height: 200px;
  margin-top: 20px;
}
.mask .el-toast-box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 450px;
  height: 410px;
  margin: auto;
  background-color: #fff;
  border-radius: 15px;
  z-index: 9999;
  overflow: hidden;
  transition: opacity 0.5s ease-in;
}
.el-icon-close {
  position: absolute;
  right: 15px;
  top: 10px;
  background: rgba(101, 61, 0, 0.4);
  border-radius: 50%;
  padding: 4px;
  font-size: 16px;
  color: #fff;
	cursor: pointer;
}
@media (max-width: 800px) {
  .mask {
    width: 100%;
    /* opacity: 0.6; */
    /* background-color: black; */
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    transition: opacity 0.5s ease-in;
  }
  .el-toast-header {
    padding: 10px 20px;
    background: linear-gradient(90deg, rgb(255, 163, 58) 0%, #f08519);
  }
  .header-img {
    width: 55px;
    height: 55px;
    margin: 10px 0;
    border-radius: 50%;
  }
  .header-msg {
    text-align: left;
    color: #fff;
  }
  .header-msg h3 {
    font-size: 16px;
  }
  .header-msg p {
    margin-top: 10px;
    font-size: 12px;
    color: #fff;
  }
  .el-toast-content img {
    width: 160px;
    height: 160px;
    margin-top: 0;
    margin: 10px 0;
  }
  .mask .el-toast-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 320px;
    height: 320px;
    margin: auto;
    background-color: #fff;
    border-radius: 15px;
    z-index: 9999;
    overflow: hidden;
    transition: opacity 0.5s ease-in;
  }
  .el-icon-close {
    position: absolute;
    right: 15px;
    top: 10px;
    background: rgba(101, 61, 0, 0.4);
    border-radius: 50%;
    padding: 3px;
    font-size: 16px;
    color: #fff;
  }
}
</style>