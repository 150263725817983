<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div class="el-content-width">
        <div :class="isShow ? 'slide-right' : ''">
          <div class="section-title">
            <h2 class="el-font-color333">{{ content.title }}</h2>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">{{ content.title_two }}</h1>
          <p class="section-text">
            {{ content.desc }}
          </p>
        </div>
        <div
          class="section8-content el-flex-between-start"
          :class="isShow ? 'slide-left' : ''"
        >
          <ul class="el-flex-wrap">
            <li
              class="el-flex-column"
              v-for="(item, index) in content.functionList"
              :key="index"
              :class="funCurrent == index ? 'li-active' : ''"
              @mouseenter="getFunCurrent(index)"
            >
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <div class="section8-img">
            <el-image
              style="width: 100%; height: 100%"
              :src="content.functionList[funCurrent].img"
              slot="placeholder"
              :class="isShow ? 'slide-right' : ''"
              fit="cover"
            >
              <div>加载中<span class="dot">...</span></div>
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script >
export default {
  name: "sectionEghit",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: [] },
  },
  data() {
    return {
      funCurrent: 0,
    };
  },
  methods: {
    getFunCurrent(index) {
      this.funCurrent = index;
    },
  },
};
</script>
    <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section8-content {
  margin-top: 60px;
}
.section8-content ul {
  width: 50%;
}
.section8-content ul li {
  width: 15%;
  padding: 10px;
  margin: 20px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 15px;
}
.section8-content ul .li-active {
  background-color: #fdffd6;
  color: #333;
}
.section8-content ul li span {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.section8-content ul li img {
  width: 40px;
  height: 40px;
}
.section8-img {
  width: 50%;
  height: 410px;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section8-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section8-content ul {
    margin-top: 10px;
    width: 100%;
    /* justify-content: space-between; */
  }
  .section8-content ul li {
    width: 22%;
    padding: 5px;
    margin: 5px;
    box-shadow: 0px 5px 15px 0px #0000001a;
    border-radius: 15px;
  }
  .section8-content ul li:hover {
    background-color: #fffcf3;
    color: #333;
  }
  .section8-content ul li span {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
  }

  .section8-content ul li img {
    width: 40px;
    height: 40px;
  }
  .section8-img {
    width: 100%;
    height: 310px;
  }
}
</style>