<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
				<div class="el-flex-between">
					<div class="banner-img" v-if="!isMobile()">
						<img src="../../../assets/image/product/storeFresh/banner_1.png" alt="" />
					</div>
					<div class="banner-items">
						<h1 class="el-font-colorfff">生鲜电商零售解决方案</h1>
						<p>
							让生鲜配送更简单/让您的生意更高效
						</p>
						<div class="banner-button el-flex">
							<div class="banner-button-1" @click="toastShow = true">
								获取详情
							</div>
							<!-- <div class="banner-button-2">立即联系</div> -->
						</div>
					</div>
				</div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :isShow="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :isShow="four_show"
      ref="scrollFour"
    ></sectionFour>
		<sectionEleven
			:content="elevenData"
			:isShow="five_show"
			ref="scrollFive"
		></sectionEleven>
    <sectionFive
      :content="fiveData"
      :isShow="six_show"
      ref="scrollSix"
    ></sectionFive>
    <sectionTen
      :content="tenData"
      :isShow="seven_show"
      ref="scrollSeven"
    ></sectionTen>
    <sectionFooter
      :content="footerData"
      :isShow="footer_show"
      ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
		<toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>		
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionTen from "@/components/productSection/sectionTen.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionTen,
    sectionEleven,
    sectionFooter,
		toastFrame		
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "系统特色", "市场分析","系统特权", "功能展示",  "系统支持",  "核心功能", "系统演示"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
			five_show: false,
			six_show: false,
			seven_show: false,
			footer_show: false,
			nine_show: false,
      navTop: false,
      oneData: {
        title: "系统介绍",
        title_two: "灵狐生鲜系统介绍",
        desc: "生鲜电商从2012年下半年浮出水面，是受资本关注度最高活跃的领域，因通过严格的QA&QC检测，已得到市场和用户认可，现各方对生鲜电商市场的争夺日渐激烈。",
        banner: require("../../../assets/image/product/storeFresh/section_1.png"),
        fontColor: "#1dcb1d",
        borderColor: "#1dcb1d",
        list: [
          { name: "多终端B2C线上商城", desc: "覆盖小程序、H5微商城、PC端、APP商城等多终端触点。功能完善，交互友好帮助企业快速搭建以消费者为中心的商城体系" },
          { name: "线上线下融合", desc: "根据门店服务范围搭建线上多站点根据用户的LBS定位获取近门店门店线上站点页面可根据运营需要配置不同风格和内容，实现千店千面满足新零售场景化需要" },
          { name: "多种营销玩法", desc: "创新性营销引擎，支持近100种促销规则支持满减、满赠、优惠券、限时抢购、预售、拼团、宅配、储值卡、礼品卡等多种营销方式，营销活动和会员资产和线下门店打通，全面满足运营需要" },
          { name: "统一高效运营", desc: "支持商品、订单、会员、营销、库存、服务，统一化运营管理可快速建立一整套数字化商业管理系统" },
        ],
      },
      twoData: {
        title: "适用场景",
        title_two: "网上商城系统，选我们的理由",
        desc: "特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能",
        imageBgColor: "#defedcf2",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_1.png"),
            name: "更新迭代",
            desc: "顺应互联网时代发展，不断迭代更新",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_2.png"),
            name: "提供源码",
            desc: "提供PHP商城系统源码",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_3.png"),
            name: "高级会员",
            desc: "提供PHP商城系统源码",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_4.png"),
            name: "接受二次开发",
            desc: "可根据个性化需求定制开发商城系统",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_5.png"),
            name: "24小时客服",
            desc: "客服7*24小时在线，紧急情况处理",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_6.png"),
            name: "装修指导",
            desc: "对商家店铺装修制作进行引导和协助",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_7.png"),
            name: "在线培训",
            desc: "提供商城制作使用手册、帮助文档等",
          },
          {
            icon: require("../../../assets/icon/product_icon/store_fresh_8.png"),
            name: "多终端覆盖",
            desc: "系统支持PC+移动各个终端",
          },
        ],
      },
      threeData: {
        title: "市场分析",
        title_two: "市场SWOT分析",
        desc: "",
        list: [
          {
            img: require("../../../assets/image/product/storeFresh/section3_1.png"),
            name: "优势",
            desc: "利润高，高重复购买率及客单价高",
          },
          {
            img: require("../../../assets/image/product/storeFresh/section3_2.png"),
            name: "劣势",
            desc: "最后一公里技术有限，消费习惯转变慢",
          },
          {
            img: require("../../../assets/image/product/storeFresh/section3_3.png"),
            name: "机会",
            desc: "生鲜电商范围占比小，且有政府扶持",
          },
          {
            img: require("../../../assets/image/product/storeFresh/section3_4.png"),
            name: "挑战",
            desc: "产品运输和网络营销等多种困难限制",
          },
        ],
      },
      fourData: {
        title: "系统特权",
        title_two: "解锁更多的系统特权",
        img: require("../../../assets/image/product/storeFresh/section4_1.png"),
        desc: "购买商业授权版本，获得更多系统功能，畅享版本免费升级",
        list: [
          { name: "商业授权证书", desc: "官方正式授权证书，保障系统安全可靠" },
          { name: "更多营销功能", desc: "升级拼团，砍价等营销功能，促进粉丝快速裂变转化" },
          { name: "会员群服务", desc: "技术群指导服务，帮助开发者快速熟悉系统架构" },
          { name: "在线更新", desc: "免费更新升级，解决系统维护难题" },
        ],
      },
      fiveData: {
        title: "",
        title_two: "强大的研发实力，安全可靠的系统支持",
        img: require("../../../assets/image/store_fresh_img_1.png"),
        list_one: [
          {
            name: "提供系统源码",
            desc: "商家拥有源码，轻松掌握商城的运营权",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "节省运营成本",
            desc: "生鲜配送系统-SAAS服务商 生鲜配送系统源码，生鲜批发百货配送系统商城源码是一套成熟的电商系统省去上百万的开发费用",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "支持定制开发",
            desc: "根据企业的需求个性化定制商城功能",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "系统持续更新",
            desc: "每周都有新的系统更新，开发永无止境",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "系统适用广泛",
            desc: "系统可广泛适用于各行各业",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "系统稳定可靠",
            desc: "系统已持续开发三年多，累计服务100+客户",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      tenData: {
        title: "核心功能",
        title_two: "核心功能荟萃",
        title_center: "核心功能",
        desc: "",
        bgColor_1: "#57FCA7",
        bgColor_2: "#3FE580",
        bgColor_3: "#1DD661",
        list_one: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "物流跟踪功能",
            desc: "根据商家的物流编号，迅速整合物流信息，快速跟踪物流进程",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "多城市多仓库",
            desc: "商品可根据”省、市、区（县）“区域上传，并就近调度发货",
          },
        ],
        list_two: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "限时秒杀",
            desc: "可设置商品的限时购买价格，为平台带来超高的用户流量",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "预售功能",
            desc: "用于提前展示预收商品，为新商品宣传造势，增加商户人气",
          },
        ],
      },
      elevenData: {
        title: "",
        title_two: "功能展示",
        title_three: "板块11—3",
        desc: "",
        desc_two: "线上引流、到店消费；线下获客、线上转化；多种营销模式组合，打通线上线下营销壁垒，形成销售闭环，有效提升销售转化",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "拼团活动",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "限时秒杀",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "分销体系",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "砍价活动",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "优惠券",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "积分商城",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "优惠套餐",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "积分体系",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "限时折扣",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "商品限购",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "会员特权",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "余额充值",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "满减满赠",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},
        ],
      },
      footerData: {
				title: "演示地址",
        bgColor: "#0bce0b",
        hrefList: [
          {
            name: "商户后台：",
            href: "https://www.foxmall.online/merchant",
          },
          {
            name: "说明文档：",
            href: "https://docs.foxonline.net",
          },
        ],
        codeList: [
          {
            name: "生鲜商城用户端",
            img: require("../../../assets/image/product/storeFresh/qrcode.png"),
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技生鲜电商新零售解决方案", //分享标题
      desc: "让生鲜配送更简单/让您的生意更高效", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
			let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
      let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
      let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
      let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
      let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sevenHeight;
      
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      } else if (index == 5) {
        document.documentElement.scrollTop = fiveHeight;
        document.body.scrollTop = fiveHeight;
      } else if (index == 6) {
        document.documentElement.scrollTop = sixHeight;
        document.body.scrollTop = sixHeight;
      } else if (index == 7) {
        document.documentElement.scrollTop = sevenHeight;
        document.body.scrollTop = sevenHeight;
      } else if (index == 8) {
        document.documentElement.scrollTop = footerHeight;
        document.body.scrollTop = footerHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
        let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
        let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + sevenHeight;
        
				if (scrollTop > headerHeight) {
					this.navTop = true;
					this.current = 0;
				}
				if (scrollTop == 0) {
					this.navTop = false;
					this.current = 0;
				}
				if (scrollTop > oneHeight) {
					this.one_show = true;
					this.current = 1;
				}
				if (scrollTop > twoHeight) {
					this.two_show = true;
					this.current = 2;
				}
				if (scrollTop > threeHeight) {
					this.three_show = true;
					this.current = 3;
				}
				if (scrollTop > fourHeight) {
					this.four_show = true;
					this.current = 4;
				}
				if (scrollTop > fiveHeight) {
					this.five_show = true;
					this.current = 5;
				}
				if (scrollTop > sixHeight) {
					this.six_show = true;
					this.current = 6;
				}
				if (scrollTop > sevenHeight) {
					this.seven_show = true;
					this.current = 7;
				}
				if (scrollTop > footerHeight) {
					this.footer_show = true;
					this.current = 8;
				}
			}
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    320deg,
    rgb(24, 211, 90) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgba(92, 255, 172, 1) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items h1 {
  font-size: 36px;
  font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0bce0b;
  color: #0bce0b;
	cursor: pointer;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #0bce0b;
  color: #0bce0b;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.el-nav-box span {
	cursor: pointer;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    320deg,
    rgb(32, 212, 95) 0%,
    rgba(55, 219, 111, 1) 61%,
    rgb(37, 255, 143) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #0bce0b;
}
@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			320deg,
			rgb(24, 211, 90) 0%,
			rgba(55, 219, 111, 1) 61%,
			rgba(92, 255, 172, 1) 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #0bce0b;
		color: #0bce0b;
	}
}
</style>