<template>
  <div>
    <div class="el-cnav-card" @mouseleave="cnavLeave" v-if="!isMobile()">
      <ul>
        <li
          v-for="(item, index) in cnavList"
          :key="index"
          @mouseenter="cnavActive(index)"
        >
          <div class="el-flex-column">
            <img
              :class="hoverCurrent == index ? 'cnav-active' : ''"
              :src="item.icon"
              alt=""
            />
            <span>{{ item.name }}</span>
          </div>
          <div
            class="cnav-contact-show"
            :style="{
              width: width,
              height: height,
              top: top,
              lineHeight: height,
            }"
            v-if="hoverCurrent == index"
          >
            <h2 x v-if="item.content">{{ item.content }}</h2>
            <div class="cnav-code-img el-flex-column" v-if="item.img">
              <img :src="item.img" alt="" />
              <span>微信扫一扫</span>
            </div>
          </div>
        </li>
        <li class="li5" @click="toTop">
          <img src="../../assets/icon/contact_reback_top.png" alt="" />
        </li>
      </ul>
    </div>
    <div class="mob-el-footer-box" v-else>
      <ul class="el-flex-around">
        <li
          class="el-align-center items"
          v-for="(item, index) in cnavMobList"
          :key="index"
          @click="toCall(index, item.content)"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  name: "LayoutHeader",
  data() {
    return {
      cnavList: [
        // {
        //   icon: require("@/assets/icon/contact_online.png"),
        //   name: "在线咨询",
        // },
        {
          icon: require("@/assets/icon/contact_phone.png"),
          name: "电话咨询",
          content: this.$config.APP_MOBILE,
        },
        {
          icon: require("@/assets/icon/contact_code.png"),
          name: "公众号",
          img: this.$config.APP_WX_ACOUNT_CODE,
        },
        {
          icon: require("@/assets/icon/concact_wechat.png"),
          name: "微信咨询",
          img: this.$config.APP_WX_CODE,
        },
      ],
      cnavMobList: [
        // {
        //   icon: require("@/assets/icon/contact_online.png"),
        //   name: "在线咨询",
        // },
        {
          icon: require("@/assets/icon/contact_phone.png"),
          name: "打电话",
          content: this.$config.APP_MOBILE,
        },
        {
          icon: require("@/assets/icon/concact_wechat.png"),
          name: "加微信",
          img: this.$config.APP_WX__CODE,
        },
      ],
      hoverCurrent: -1,
      width: 0,
      height: 0,
      top: 0,
      imgShow: false,
    };
  },
  methods: {
    cnavActive(index) {
      this.hoverCurrent = index;
      if (index == 0) {
        this.width = "130px";
        this.height = "75px";
        this.top = 0;
      } else if (index == 1 || index == 2) {
        this.width = "130px";
        this.height = "130px";
        this.top = "-18px";
      }
    },
    cnavLeave() {
      this.hoverCurrent = -1;
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    toCall(index, phoneNumber) {
      if (index == 0) {
        window.location.href = "tel://" + phoneNumber;
      } else if (index == 1) {
        this.open();
      }
    },
    open() {
      this.$alert(
        this.$config.APP_MOBILE + "，可复制前往微信添加好友咨询",
        "商务经理电话",
        {
          confirmButtonText: "复制",
          callback: (action) => {
            if (action == "confirm") {
              util.copyText(this.$config.APP_MOBILE);
              this.$message({
                type: "success",
                message: `提示: ${"已复制到粘贴板"}`,
              });
            }
          },
        }
      );
    },
  },
};
</script>
<style scoped>
.el-cnav-card {
  position: fixed;
  right: 10px;
  width: 72px;
  top: 50%;
  margin-top: -158px;
  z-index: 9999;
}
.el-cnav-card li {
  position: relative;
  background-color: #fff;
  border: #fcac5b solid 2px;
  color: #f08519;
  padding: 6px 0;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 10px;
}
li img {
  width: 38px;
  height: 38px;
  margin-bottom: 5px;
}
.li5 {
  background-color: #fff;
  border: #fcac5b solid 2px;
  color: #f08519;
  font-size: 12px;
  border-radius: 5px;
  padding: 0;
}
.li5 img {
  height: 30px;
  margin-bottom: 0;
}
.cnav-contact-show {
  position: absolute;
  right: 78px;
  top: 0;
  background: linear-gradient(
    28deg,
    rgb(251, 148, 79) 18%,
    rgb(249, 170, 66) 100%
  );
  border: #f08519 solid 2px;
  border-radius: 5px;
  color: #fff;
  animation-name: slide-in-elliptic-right-fwd;
  animation-duration: 0.7s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: slide-in-elliptic-right-fwd 1s linear 0s 1 normal none;*/
}
@keyframes slide-in-elliptic-right-fwd {
  0% {
    transform: translateX(700px) rotateY(-30deg) scale(0);
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotateY(0) scale(1);
    transform-origin: -700px 50%;
    opacity: 1;
  }
}
.cnav-code-img {
  padding: 5px;
}
.cnav-code-img img {
  width: 100px;
  height: 100px;
}
.cnav-code-img span {
  line-height: 10px;
}
.cnav-contact-show ::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #f08519;
}
.cnav-active {
  animation-name: shake-center;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: shake-center 1s ease-out 0s 1 normal none;*/
}
.mob-el-footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  padding: 5px 20px;
  z-index: 99999;
}
.mob-el-footer-box .items {
  position: relative;
}
.mob-el-footer-box img {
  width: 40px;
  height: 40px;
  margin-bottom: 0;
}
.items span {
  font-size: 15px;
  margin-left: 10px;
}

@keyframes shake-center {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
  10% {
    transform: rotate(8deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-8deg);
  }
  90% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}
</style>
<style>
@media (max-width: 800px) {
  .el-message-box {
    width: 320px !important;
  }
}
</style>