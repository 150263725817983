<template>
  <div>
    <Header :num="4" />
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '200px' : '300px'"
      >
        <el-carousel-item v-for="(item, index) in 1" :key="index">
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h2 class="el-title-font-size">
                    灵狐网络科技常见问题
                  </h2>
                  <span class="banner-span"
                    >疑难解答，助您快速入门</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="el-question-box">
        <div class="el-content-width">
          <div v-if="qusList.length > 0">
            <div
              class="el-flex question-box"
              v-for="(item, index) in qusList"
              :key="index"
            >
              <div class="question-box-title">
                问题{{ (pageIndex - 1) * 5 + index + 1 }}
              </div>
              <div style="width: 85%">
                <h3>
                  问题{{ (pageIndex - 1) * 5 + index + 1 }}：{{ item.title }}
                </h3>
                <p>答：{{ item.describe }}</p>
              </div>
            </div>
          </div>
          <el-empty description="没有相关问题哦~" v-else></el-empty>
        </div>
      </div>
    </div>
    <div class="el-page">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="totalPage"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <Cnav />
    <Footer />
  </div>
</template>
        <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import axios from "axios";
export default {
  components: { Header, Cnav, Footer },
  data() {
    return {
      qusList: [],
      pageIndex: 1,
      totalPage: 10,
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.getQusList();
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—常见问题", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getQusList() {
      axios
        .get("/articles", {
          params: {
            limit: 5,
            page: this.pageIndex,
            key: "faq",
            keyword: this.search_value,
          },
        })
        .then((res) => {
          this.totalPage = Math.ceil(res.data.data.count / 5) * 10;
          this.qusList = res.data.data.list;
        })
        .catch((error) => {
          alert(error);
        });
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getQusList();
    },
  },
};
</script>
        <style scoped>
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/about_us_3.jpg");
  background-size: cover;
  background-position: center;
  height: 520px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 520px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 50%;
  height: 300px;
  margin: auto 0;
  text-align: left;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
                animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.el-question-box {
  padding-top: 100px;
  text-align: left;
}
.el-question-box p {
  color: #999;
  margin-top: 20px;
  font-size: 15px;
}
.question-box {
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ffa01c;
}
.question-box-title {
  width: 120px;
  height: 70px;
  background: #ffa01c;
  padding: 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-right: 50px;
  text-align: center;
}
.el-page {
  margin: 30px;
}
@media only screen and (max-width: 800px) {
  .el-banner-items {
    margin: auto;
    background: url("../../../assets/image/about_us_3.jpg");
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 200px;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .el-banner-content {
    width: 80%;
    height: 200px;
    margin: 10px 20px;
    text-align: left;
    color: #fff;
    animation-name: slide-in-right;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
                animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
  }
  .banner-span {
    margin: 30px 0;
    font-size: 14px;
  }
  .el-question-box {
    padding-top: 50px;
    text-align: left;
  }
  .el-question-box p {
    color: #999;
    margin-top: 10px;
    font-size: 13px;
  }
  .question-box {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffa01c;
  }
  .question-box-title {
    width: 80px;
    height: 40px;
    background: #ffa01c;
    padding: 10px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    margin-right: 20px;
    text-align: center;
  }
  .el-page {
    margin: 30px;
  }
}
</style>