<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="el-flex-between">
          <div class="banner-items">
            <h1 class="el-font-colorfff">灵狐云到家服务系统</h1>
            <p>
              快速搭建本地家政、维修等服务行业在线预约平台支持自营+商家入驻，适合多种服务类型
            </p>
            <div class="banner-button el-flex">
              <div class="banner-button-1" @click="toastShow = true">获取详情</div>
              <!-- <div class="banner-button-2" @click="toastShow = true">
                获取详情
              </div> -->
            </div>
          </div>
					<div class="banner-img" v-if="!isMobile()">
						<img src="../../../assets/image/product/cityHome/banner1.png" alt="" />
					</div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :content="oneData"
      :isShow="one_show"
      ref="scrollOne"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionEight
      :content="eightData"
      :show="four_show"
      ref="scrollFour"
    ></sectionEight>
    <sectionEleven
      :content="elevenData"
      :show="five_show"
      ref="scrollFive"
    ></sectionEleven>
		<sectionTen
			:content="tenData"
			:show="six_show"
			ref="scrollSix"
		></sectionTen>
		<sectionFive
      :content="fiveData"
      :show="seven_show"
      ref="scrollSeven"
    ></sectionFive>
		<sectionNine
			:content="nineData"
			:show="eight_show"
			ref="scrollEight"
		></sectionNine>
    <sectionFooter
      :content="footerData"
			:show="footer_show"
			ref="scrollFooter"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
		<toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionEight from "@/components/productSection/sectionEight.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionTen from "@/components/productSection/sectionTen.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFive,
    sectionEight,
    sectionNine,
    sectionTen,
    sectionEleven,
    sectionFooter,
		toastFrame
  },
  data() {
    return {
			toastShow: false,
      navList: ["系统介绍", "特色功能", "解决方案", "功能展示", "营销插件", "多终端覆盖", "调度系统", "售后服务", "案例演示"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      five_show: false,
      six_show: false,
      seven_show: false,
      eight_show: false,
      footer_show: false,
      navTop: false,
      oneData: {
        title: "",
        title_two: "灵狐云到家服务系统介绍",
        desc: "灵狐云到家服务系统适用于多种服务模式，几乎所有本地上门预约行业商家都可以入驻平台，开通线上销售渠道独立品牌，私有化部署，支持分站多城市运营",
        banner: require("../../../assets/image/product/cityHome/section1_1.png"),
        fontColor: "#13AAFF",
        borderColor: "#13AAFF",
        list: [
          { name: "一口价、预约金等多种定价策略", desc: "一口价：服务即商品，购买后服务过程中不再收取额外费用。预约金：下单需支付部分定金，服务完成后按实际金额收费。附加费：针对夜间、雨雪天气等特殊场景临时调整服务费用。空单费：服务人员开始上门预约后取消订单，收取相应跑腿费" },
          { name: "代客下单，简单方便", desc: "若客户通过微信或电话及其他渠道进行预约，管理员可在后台使用客户手机号代客下单，新用户会自动注册账号。用户则可以使用下单手机号登录平台，查看订单信息、支付订单费用。" },
          { name: "增值服务，师傅现场报价下单", desc: "若服务过程中需要维修配件或增值服务，服务人员可在服务端APP查看平台预设的配件或项目信息，选择并下单后由客户进行在线支付或当面扫码付款。" },
          { name: "师傅信息全方位展示", desc: "个人资料、服务项目、评价等信息全方位展示，一目了然。帮助客户快速筛选，并且支持下单指定服务人员或由系统自动匹配附近服务人员提供上门预约。" },
        ],
      },
      twoData: {
        title: "",
        title_two: "特色功能介绍",
        desc: "灵狐云到家服务系统不仅给客户带来优质的购物体验，更以用户的营销需求为出发点，满足诸多特色功能",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "多种登录方式",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "多城市切换",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "多种支付方式",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "自定义广告位",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "热搜词推荐",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "LBS定位",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "一键拨打电话",
            desc: "",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "消息推送",
            desc: "",
          },
        ],
      },
      threeData: {
        title: "解决方案",
        title_two: "家政、维修等服务行业整体解决方案",
        desc: "灵狐云到家服务系统可帮助您快速搭建一套完善的在线预约上门预约平台，满足家政、维修、搬家拉货等几乎所有上门预约行业需求。提供多渠道获客引流、精细化营销管理方案，并且提供多种下单入口，用户在线预约，平台师傅或入驻商家一键接单并提供上门预约。",
        list: [
          {
            img: require("../../../assets/image/product/cityHome/section3_1.png"),
            name: "家政保洁",
            desc: "支持服务项目多规格差异化定价",
          },
          {
            img: require("../../../assets/image/product/cityHome/section3_2.png"),
            name: "家电维修",
            desc: "维修配件库、师傅现场报价下单",
          },
          {
            img: require("../../../assets/image/product/cityHome/section3_3.png"),
            name: "推拿按摩",
            desc: "技师信息展示，在线预约上门",
          },
          {
            img: require("../../../assets/image/product/cityHome/section3_4.png"),
            name: "更多行业",
            desc: "几乎满足所有预约上门预约场景",
          },
        ],
      },
      fiveData: {
        title: "",
        title_two: "调度后台",
        img: require("../../../assets/image/store_fresh_img_1.png"),
        list_one: [
          {
            name: "订单状态监控",
            desc: "实时监控订单服务状态，订单服务地址、服务时间等信息一目了然，可通过订单号和用户手机号查询订单，查看订单详情",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "师傅状态监控",
            desc: "随时查看师傅状态、接单状况、实时位置，可一键查看师傅当前所有服务订单的服务地点地图位置，方便后台运营人员协调派单",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "平台订单指派",
            desc: "若订单长时间没有师傅/商家抢单，后台调度中心可进行指派师傅或商家；后台也可以关闭抢单模式，所有订单均由后台统一派单调度",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "师傅审核/管理",
            desc: "调度管理后台可新增、查看、修改配师傅信息或对师傅账号进行禁用，并对提交申请入驻的师傅身份信息进行审核验证",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "师傅考核/评价",
            desc: "平台可通过调度管理后台查看每个师傅的接单量、综合评分、服务时长及顾客评价等信息协助运营管理员进行师傅业绩考核，提高整体服务质量",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "平台订单改派",
            desc: "若师傅在接单后开始服务前遇到突发状况无法完成服务时，可自行转到订单池或联系运营管理员，由管理员在调度后台安排其他师傅/商家进行服务",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      eightData: {
        title: "",
        title_two: "灵狐云到家服务系统功能展示",
        desc: "",
        functionList: [
          {
            name: "首页",
            icon: require("../../../assets/icon/product_icon/store_icon_1.png"),
            img: require("../../../assets/image/product/cityHome/section8_1.png"),
          },
          {
            name: "分类页",
            icon: require("../../../assets/icon/product_icon/store_icon_2.png"),
            img: require("../../../assets/image/product/storeProduct/2.png"),
          },
          {
            name: "服务列表",
            icon: require("../../../assets/icon/product_icon/store_icon_3.png"),
            img: require("../../../assets/image/product/storeProduct/3.png"),
          },
          {
            name: "服务详情",
            icon: require("../../../assets/icon/product_icon/store_icon_4.png"),
            img: require("../../../assets/image/product/storeProduct/4.png"),
          },
          {
            name: "下单页",
            icon: require("../../../assets/icon/product_icon/store_icon_5.png"),
            img: require("../../../assets/image/product/storeProduct/5.png"),
          },
          {
            name: "订单详情",
            icon: require("../../../assets/icon/product_icon/store_icon_6.png"),
            img: require("../../../assets/image/product/storeProduct/6.png"),
          },
          {
            name: "个人中心",
            icon: require("../../../assets/icon/product_icon/store_icon_7.png"),
            img: require("../../../assets/image/product/storeProduct/6.png"),
          },
          {
            name: "技师工作台",
            icon: require("../../../assets/icon/product_icon/store_icon_8.png"),
            img: require("../../../assets/image/product/cityHome/section8_8.png"),
          },
          {
            name: "数据统计",
            icon: require("../../../assets/icon/product_icon/store_icon_9.png"),
            img: require("../../../assets/image/product/cityHome/section8_9.png"),
          },
          {
            name: "财务管理",
            icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
            img: require("../../../assets/image/product/cityHome/section8_10.png"),
          },
        ],
      },
      nineData: {
        title: "",
        title_two: "系统优势",
        desc: "私有化部署、SAAS两种销售模式可选，根据客户需求灵活选择",
        list: [
          {
            img: require("../../../assets/image/product/storeProduct/section9_1.png"),
            name: "多终端覆盖",
            desc: "涵盖小程序/公众号/APP/H5",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section9_2.png"),
            name: "多城市运营",
            desc: "招募城市代理商多站点运营",
          },
          {
            img: require("../../../assets/image/product/storeProduct/section9_3.png"),
            name: "源码出售",
            desc: "平台拥有系统源码操控权",
          },
					{
            img: require("../../../assets/image/product/storeProduct/section9_3.png"),
            name: "免费升级",
            desc: "一次购买终身免费升级",
          },					
        ],
      },
      tenData: {
        title: "",
        title_two: "多终端覆盖，满足平台多角色需求",
        title_center: "到家服务",
        desc: "灵狐云到家服务系统支持本地商家或个人入驻平台，并配套有独立的操作终端，以及强大的订单调度管理系统，助您轻松运营",
        bgColor_1: "#9FD9FC",
        bgColor_2: "#25D6FC",
        bgColor_3: "#13AAFF",
        list_one: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "用户端",
            desc: "系统涵盖APP、小程序、H5等多终端下单入口，满足多样化需求",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "服务端",
            desc: "自主注册申请，拥有独立服务端APP进行接单、提现",
          },
        ],
        list_two: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "商家端",
            desc: "商家可自主申请入驻平台，拥有独立PC+移动管理后台",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "运营端",
            desc: "负责管理全站整体运营，精细化管理、丰富的营销工具",
          },
        ],
      },
      elevenData: {
        title: "",
        title_two: "丰富的营销互动工具",
        title_three: "营销插件",
        desc: "系统提供多种营销工具及增值应用插件，让用户通过评估丰富的活动提升用户粘性和小费转化，提供平台订单销量",
        desc_two: "线上引流、到店消费；线下获客、线上转化；多种营销模式组合，打通线上线下营销壁垒，形成销售闭环，有效提升销售转化",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "注册领券",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "邀请有奖",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "新客立减",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "优惠券",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "限时折扣",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "满减优惠",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "积分商城",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "裂变分销",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },		
					{
						img: require("../../../assets/image/test_pro_img_1.png"),
						name: "会员特权",
						iconBgColor: "#fffce3",
						icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
					},		
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "更多功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },													
        ],
      },
      footerData: {
        bgColor: "#13AAFF",
        hrefList: [
          {
            name: "运营后台地址：",
            href: "https://yuyue-beta.foxonline.net/admin",
          },
					{
						name: "产品文档中心：",
						href: "https://docs.foxonline.net/docs/",
					}
        ],
        codeList: [
          {
            name: "上门按摩演示",
            img: require("../../../assets/image/product/cityHome/footer_1.png"),
          },
					{
						name: "上门家政保洁",
						img: require("../../../assets/image/product/cityHome/footer_2.png"),
					},
					{
						name: "独立师傅端",
						img: require("../../../assets/image/product/cityHome/footer_3.png"),
					},
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐云到家服务系统适用于多种服务模式", //分享标题
      desc: "快速搭建本地家政、维修等服务行业在线预约平台支持自营+商家入驻，适合多种服务类型", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
			let headerHeight = this.$refs.scrollHeader.offsetHeight;
			let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
			let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
			let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
			let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
			let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
			let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
			let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
			let eightHeight = this.$refs.scrollEight.$el.clientHeight + sevenHeight;
			let footerHeight = this.$refs.scrollFooter.$el.clientHeight + eightHeight;

			if (index == 0) {
				document.documentElement.scrollTop = headerHeight;
				document.body.scrollTop = headerHeight;
			} else if (index == 1) {
				document.documentElement.scrollTop = oneHeight;
				document.body.scrollTop = oneHeight;
			} else if (index == 2) {
				document.documentElement.scrollTop = twoHeight;
				document.body.scrollTop = twoHeight;
			} else if (index == 3) {
				document.documentElement.scrollTop = threeHeight;
				document.body.scrollTop = threeHeight;
			} else if (index == 4) {
				document.documentElement.scrollTop = fourHeight;
				document.body.scrollTop = fourHeight;
			} else if (index == 5) {
				document.documentElement.scrollTop = fiveHeight;
				document.body.scrollTop = fiveHeight;
			} else if (index == 6) {
				document.documentElement.scrollTop = sixHeight;
				document.body.scrollTop = sixHeight;
			} else if (index == 7) {
				document.documentElement.scrollTop = sevenHeight;
				document.body.scrollTop = sevenHeight;
			} else if (index == 8) {
				document.documentElement.scrollTop = eightHeight;
				document.body.scrollTop = eightHeight;
			} else if (index == 9) {
				document.documentElement.scrollTop = footerHeight;
				document.body.scrollTop = footerHeight;
			}
    },
    handleScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (this.$refs.scrollHeader) {
				let headerHeight = this.$refs.scrollHeader.offsetHeight;
				let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
				let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
				let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
				let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
				let fiveHeight = this.$refs.scrollFive.$el.clientHeight + fourHeight;
				let sixHeight = this.$refs.scrollSix.$el.clientHeight + fiveHeight;
				let sevenHeight = this.$refs.scrollSeven.$el.clientHeight + sixHeight;
				let eightHeight = this.$refs.scrollEight.$el.clientHeight + sevenHeight;
				let footerHeight = this.$refs.scrollFooter.$el.clientHeight + eightHeight;
				
				if (scrollTop > headerHeight) {
					this.navTop = true;
					this.current = 0;
				}
				if (scrollTop == 0) {
					this.navTop = false;
					this.current = 0;
				}
				if (scrollTop > oneHeight) {
					this.one_show = true;
					this.current = 1;
				}
				if (scrollTop > twoHeight) {
					this.two_show = true;
					this.current = 2;
				}
				if (scrollTop > threeHeight) {
					this.three_show = true;
					this.current = 3;
				}
				if (scrollTop > fourHeight) {
					this.four_show = true;
					this.current = 4;
				}
				if (scrollTop > fiveHeight) {
					this.five_show = true;
					this.current = 5;
				}
				if (scrollTop > sixHeight) {
					this.six_show = true;
					this.current = 6;
				}
				if (scrollTop > sevenHeight) {
					this.seven_show = true;
					this.current = 7;
				}
				if (scrollTop > eightHeight) {
					this.eight_show = true;
					this.current = 8;
				}
				if (scrollTop > footerHeight) {
					this.footer_show = true;
					this.current = 9;
				}
			}
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    40deg,
    #13AAFF 10%,
    #95D6FC 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-img {
  width: 49%;
}
.banner-img img {
  width: 100%;
}
.banner-items {
  width: 49%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-img h1 {
	font-size: 36px;
	font-weight: 600;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
	font-size: 20px;
	font-weight: bold;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #13AAFF;
  color: #13AAFF;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #13AAFF;
  color: #13AAFF;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    #13AAFF 10%,
    #95D6FC 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #13AAFF;
}

@media only screen and (max-width: 800px) {
	.el-banner-card {
		margin: auto;
		/* global 94%+ browsers support */
		background: linear-gradient(
			40deg,
			#13AAFF 10%,
			#95D6FC 100%
		);
		background-size: cover;
		background-position: center;
		height: 220px;
		width: 100%;
	}
	.banner-items {
		width: 100%;
		height: 420px;
		padding: 35px 0;
		text-align: left;
	}
	.banner-items h1 {
		font-size: 2rem;
		font-weight: bold;
	}
	.banner-items p {
		color: #fff;
		margin: 20px 0;
		font-size: 1.3rem;
	}
	.banner-button {
		margin: 35px 0;
	}
	.banner-button-1 {
		padding: 12px 30px;
		background-color: #fff;
		border: solid 1px #13AAFF;
		color: #13AAFF;
	}
}
</style>