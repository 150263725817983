<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="banner-items">
          <h1 class="el-font-colorfff">同城信息发布系统</h1>
          <p>
            企业系统企业系统企业系统企业系统企业系统企业系统企业系统企业系统
          </p>
          <div class="banner-button el-flex">
            <div class="banner-button-1">前往体验</div>
            <div class="banner-button-2" @click="toastShow = true">
              立即联系
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <div class="section-card-box" ref="scrollOne">
      <div class="el-content-width">
        <div class="slide-bottom">
          <div class="section-title">
            <h3 class="el-font-color333" style="font-weight: 500">适用场景</h3>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">
            适用场景广泛，适用场景广泛适用场景广泛
          </h1>
          <p class="section-text">
            特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
          </p>
        </div>
        <div class="section1-content slide-top">
          <img
            class="section1-img"
            src="../../../assets/image/test_section_1.png"
            alt=""
          />
          <ul class="el-flex-between">
            <li>
              <h3>特色展示</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
            <li>
              <h3>特色展示</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section-card-box" style="background: #f5f5f5" ref="scrollTwo">
      <div class="el-content-width">
        <div :class="one_show ? 'slide-bottom' : ''">
          <div class="section-title">
            <h3 class="el-font-color333" style="font-weight: 500">适用场景</h3>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">
            适用场景广泛，适用场景广泛适用场景广泛
          </h1>
          <p class="section-text">
            特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
          </p>
        </div>
        <div class="section2-content" :class="one_show ? 'slide-top' : ''">
          <ul class="el-flex-between el-flex-wrap">
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_1.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_2.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_3.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_4.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_5.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_6.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_7.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img
                  src="../../../assets/icon/product_icon/special_8.png"
                  alt=""
                />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section-card-box el-section3" ref="scrollThree">
      <div class="el-content-width">
        <div :class="two_show ? 'slide-right' : ''">
          <div class="section-title">
            <h3 class="el-font-color333" style="font-weight: 500">适用场景</h3>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">
            适用场景广泛，适用场景广泛适用场景广泛
          </h1>
          <p class="section-text">
            特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
          </p>
        </div>
        <div class="section3-content" :class="two_show ? 'slide-left' : ''">
          <ul class="el-flex-between-start">
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img src="../../../assets/image/test_section1_1.png" alt="" />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn" style="margin-top: 80px">
              <div class="img-bg">
                <img src="../../../assets/image/test_section1_1.png" alt="" />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn">
              <div class="img-bg">
                <img src="../../../assets/image/test_section1_1.png" alt="" />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
            <li class="el-flex-cloumn" style="margin-top: 80px">
              <div class="img-bg">
                <img src="../../../assets/image/test_section1_1.png" alt="" />
              </div>
              <h3>高级会员</h3>
              <span>特色功能特色功能特色功能特色功能特色功能特色功能</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section-card-box" ref="scrollFour">
      <div class="el-content-width">
        <div :class="three_show ? 'slide-right' : ''">
          <div class="section-title">
            <h3 class="el-font-color333" style="font-weight: 500">功能展示</h3>
            <div class="nav-active" style="width: 80px"></div>
          </div>
          <h1 class="el-font-color333">
            适用场景广泛，适用场景广泛适用场景广泛
          </h1>
        </div>
        <div
          class="section4-content el-flex-between"
          :class="three_show ? 'slide-left' : ''"
        >
          <div class="section4-img">
            <img src="../../../assets/image/test_bg_1.png" alt="" />
          </div>
          <ul>
            <li>
              <h3>功能一</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
            <li>
              <h3>功能一</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
            <li>
              <h3>功能一</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
            <li>
              <h3>功能一</h3>
              <p>
                特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能特色功能
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <div class="section-title">
          <h2 class="el-font-color333">演示地址</h2>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <div class="section5-content">
          <div class="el-flex el-align-center">
            <h3>后台管理地址：</h3>
            <span>https://v2.cn.vuejs.org/v2/guide/index.html</span>
          </div>
          <div class="el-flex el-align-center">
            <h3>说明文档：</h3>
            <span>https://v2.cn.vuejs.org/v2/guide/index.html</span>
          </div>
        </div>
      </div>
    </div>
    <Cnav></Cnav>
    <Footer></Footer>
    <toastFrame :toastShow="toastShow" @close="toastShow = false"></toastFrame>
  </div>
</template>
  <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import toastFrame from "@/components/toastFrame/toastFrame.vue";

export default {
  name: "HelloWorld",
  components: { Header, Cnav, Footer, toastFrame },
  data() {
    return {
      navList: ["系统介绍", "系统特色", "功能展示", "服务群体", "相关文档"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      toastShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—同城系统", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.offsetHeight;
      let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.offsetHeight + threeHeight;
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.offsetHeight;
        let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
        let fourHeight = this.$refs.scrollThree.offsetHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
        }
        if (scrollTop == 0) {
          this.navTop = false;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
  <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #ffab0e;
}
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-p {
  width: 60%;
  margin: 0 auto;
  font-size: 15px;
}
.section1-content {
  margin-top: 60px;
}
.section1-content .section1-img {
  width: 100%;
}
.section1-content ul {
  margin: 40px 0;
}
.section1-content ul li {
  width: 40%;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 3px 15px 15px 1px #ffda0741;
  color: #333;
}
.section1-content ul li h3 {
  color: #ffab0e;
  padding-bottom: 15px;
  border-bottom: #ffcb6b 2px solid;
}
.section1-content ul li p {
  margin-top: 15px;
  line-height: 25px;
  font-size: 15px;
}
.section2-content {
  margin: 60px 0;
}
.section2-content li {
  width: 23%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #0000001a;
  margin: 10px 0;
}
.section2-content li .img-bg {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #e3fdff;
  border-radius: 50%;
  padding: 15px;
}
.section2-content li img {
  width: 100%;
  height: 100%;
}
.section2-content li h3 {
  color: #333;
  margin: 20px;
}
.section2-content li span {
  color: #696969;
  font-size: 14px;
}
.el-section3 {
  background: url("../../../assets/image/test_section_bg.jpg");
  background-size: 140%;
  background-repeat: no-repeat;
}
.section3-content {
  margin: 60px 0;
}
.section3-content li {
  width: 23%;
  height: 320px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 30px;
}
.section3-content li img:hover {
  animation: bg-pan-bl 0.43s linear 0s 1 normal forwards;
}

.section3-content li .img-bg {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  background-color: #e3fdff;
  border-radius: 50%;
  padding: 15px;
}
.section3-content li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.section3-content li h3 {
  color: #333;
  margin: 20px;
}
.section3-content li span {
  color: #696969;
  font-size: 14px;
}
@keyframes bg-pan-bl {
  0% {
    background-position: center;
    width: 100%;
    height: 100%;
  }
  100% {
    width: 109%;
    height: 109%;
  }
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}
.section4-content {
  margin-top: 60px;
}
.section4-content ul {
  width: 60%;
  text-align: left;
}
.section4-content ul li {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 15px;
}
.section4-content ul li p {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}
.section4-img {
  width: 30%;
  height: 500px;
}
.section4-img img {
  width: 100%;
  height: 100%;
}
.section5-content {
  width: 48%;
  margin: 20px auto;
  padding: 20px;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 0px #14e8ff1a;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
}
.section5-content div {
  padding: 10px 0;
}
.slide-top {
  animation: slide-in-top 1.2s ease-out 0s 1 normal none;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-180px);
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}
.slide-bottom {
  animation: slide-in-bottom 1.2s ease-out 0s 1 normal none;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(180px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-left {
  animation: slide-in-bck-left 1s ease-out 0s 1 normal none;
}
@keyframes slide-in-bck-left {
  0% {
    transform: translateZ(700px) translateX(-400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
.slide-right {
  animation: slide-in-bck-right 1s ease-out 0s 1 normal none;
}
@keyframes slide-in-bck-right {
  0% {
    transform: translateZ(700px) translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@media only screen and (max-width: 800px) {
  .el-banner-card {
    margin: auto;
    /* global 94%+ browsers support */
    background: linear-gradient(
      90deg,
      rgb(255, 163, 58) 0%,
      rgb(227, 221, 38) 100%
    );
    background-size: cover;
    background-position: center;
    height: 320px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 320px;
    padding: 70px 0;
    text-align: left;
  }
  .banner-items p {
    color: #fff;
    margin: 20px 0;
  }
  .banner-button {
    margin: 70px 0;
  }
  .banner-button-1 {
    padding: 16px 20px;
    background-color: #fff;
    border: solid 1px #ffcb6b;
    color: #ffab0e;
  }
  .banner-button-2 {
    padding: 16px 20px;
    background-color: #fff;
    border: solid 1px #ffcb6b;
    color: #ffab0e;
    margin-left: 20px;
  }
  .el-case-nav {
    padding: 15px 0;
    color: #777777;
    background-color: #fff;
  }
  .nav-bar {
    width: 100%;
    height: 3px;
    margin-top: 10px;
  }
  .nav-active {
    width: 100%;
    height: 3px;
    background: linear-gradient(
      90deg,
      rgb(255, 163, 58) 0%,
      rgb(227, 221, 38) 100%
    );
    margin: 0 auto;
    margin-top: 10px;
  }

  .el-case-nav {
    padding: 15px 0;
    color: #777777;
    background-color: #fff;
  }
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .nav-bar {
    width: 100%;
    height: 3px;
    margin-top: 10px;
  }
  .nav-active {
    width: 100%;
    height: 3px;
    background: linear-gradient(
      90deg,
      rgb(255, 163, 58) 0%,
      rgb(227, 221, 38) 100%
    );
    margin: 0 auto;
    margin-top: 10px;
  }
  .nav-color {
    color: #ffab0e;
  }

  .section1-content {
    margin: 60px 0;
  }
  .section1-content .section0-img {
    width: 100%;
  }
  .section1-content ul {
    margin: 20px 0;
  }
  .section1-content ul li {
    width: 47%;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 3px 15px 15px 1px #e0c52b41;
    color: #333;
    background-color: #fff;
  }
  .section1-content ul li h3 {
    color: #ffab0e;
    padding-bottom: 15px;
    border-bottom: #ffcb6b 2px solid;
  }
  .section1-content ul li p {
    margin-top: 15px;
    line-height: 25px;
    font-size: 10px;
  }
  .section2-content {
    margin: 60px 0;
  }
  .section2-content li {
    width: 23%;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px #0000001a;
    margin: 10px 0;
  }
  .section2-content li .img-bg {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-color: #e3ffff;
    border-radius: 50%;
    padding: 15px;
  }
  .section2-content li img {
    width: 100%;
    height: 100%;
  }
  .section2-content li h3 {
    color: #333;
    margin: 5px;
  }
  .section2-content li span {
    color: #696969;
    font-size: 10px;
  }
  .el-section3 {
    background: url("../../../assets/image/test_section_bg.jpg");
    background-repeat: no-repeat;
  }
  .section3-content {
    margin: 30px 0;
  }
  .section3-content li {
    width: 23%;
    height: 220px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px #0000001a;
    border-radius: 30px;
  }
  .section3-content li img:hover {
    animation: zoom-in-out 2s linear 0s infinite normal none;
  }
  @keyframes zoom-in-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .section3-content li .img-bg {
    width: 100%;
    height: 80px;
    margin: 0 auto;
    background-color: #e3fdff;
    border-radius: 50%;
    padding: 5px;
  }
  .section3-content li img {
    width: 100%;
    border-radius: 50%;
  }
  .section3-content li h3 {
    color: #333;
    margin: 5px;
  }
  .section3-content li span {
    color: #696969;
    font-size: 10px;
  }
  @keyframes bg-pan-bl {
    0% {
      background-position: center;
      width: 100%;
      height: 100%;
    }
    100% {
      width: 109%;
      height: 109%;
    }
  }
  .section4-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section4-content ul {
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
  }
  .section4-content ul li {
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    box-shadow: 0px 5px 15px 0px #0000001a;
    border-radius: 15px;
  }
  .section4-content ul li p {
    font-size: 14px;
    color: #333;
    margin-top: 10px;
    font-size: 10px;
  }
  .section4-img {
    width: 100%;
    height: 220px;
  }
  .section4-img img {
    width: 100%;
    height: 100%;
  }
  .section5-content {
    width: 88%;
    margin: 10px auto;
    padding: 0px;
    color: #fff;
    border-radius: 15px;
    box-shadow: 0px 5px 15px 0px #14e8ff1a;
    background: linear-gradient(
      90deg,
      rgb(255, 163, 58) 0%,
      rgb(227, 221, 38) 100%
    );
  }
  .section5-content div {
    padding: 10px 0;
  }
}
</style>