<template>
	<div>
		<Header :num="4" />
		<div class="el-banner-card">
			<el-carousel indicator-position="inside" trigger="hover" :interval="3000"
				:height="isMobile() ? '200px' : '320px'">
				<el-carousel-item v-for="(item, index) in 1" :key="index">
					<div class="el-banner-items">
						<div class="banner-items">
							<div :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                ">
								<div class="el-banner-content el-flex-column el-align-start">
									<h2 class="el-title-font-size">
										灵狐网络科技功能列表
									</h2>
									<span class="banner-span">性能更强，功能升级</span>
									<!-- <el-button round size="medium">查看更多</el-button> -->
								</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div class="el-nav-box">
				<div class="el-content-width el-case-nav">
					<ul class="el-flex-between">
						<li class="el-flex-column" v-for="(item, index) in navList" :key="index" @click="getCurrent(index)">
							<span :class="navCurrent == index ? 'nav-color' : ''">{{
                item.name
              }}</span>
							<div :class="navCurrent == index ? 'nav-active' : 'nav-bar'"></div>
						</li>
					</ul>
				</div>
				<div class="el-content-width">
					<ul class="el-flex-between el-second-box">
						<li v-for="(item, index) in navList[navCurrent].list" :key="index" @mouseenter="getHoverCurrent(index)"
							:class="current == index ? 'li-hover' : ''">
							{{ item.name }}
						</li>
					</ul>
					<div class="el-table-box">
						<el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
							<el-table-column label="功能模块" align="center">
								<el-table-column label="模块介绍" prop="name" width="120">
								</el-table-column>
								<el-table-column prop="fun_name" label="具体功能" width="320">
								</el-table-column>
							</el-table-column>
							<el-table-column prop="isSupport" label="是否支持" width="80" align="center">
								<template slot-scope="scope">
									<i :class="
                      scope.row.isSupport ? 'el-icon-check' : 'el-red-color'
                    ">{{ scope.row.isSupport ? "" : "暂不支持" }}</i>
								</template>
							</el-table-column>
							<el-table-column prop="descript" label="补充说明">
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
		<Cnav />
		<Footer />
	</div>
</template>
<script>
	import Cnav from "@/components/SideNav/SideNav.vue";
	import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
	import Header from "@/components/LayoutHeader/LayoutHeader.vue";
	export default {
		components: {
			Header,
			Cnav,
			Footer
		},
		data() {
			return {
				input: "",
				current: 0,
				navList: [{
						name: "商城系统",
						list: [{
								name: "零售商城系统",
								dataList: [{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动1",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动2",
									},
									{
										name: "营销活动1",
										fun_name: "哈哈",
										isSupport: false,
										descript: "营销活动营销活动营销活动营销活动营销活动3",
									},
									{
										name: "营销活动1",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动4",
									},
								],
							},
							{
								name: "生鲜商城系统",
								dataList: [{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动1",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动2",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: false,
										descript: "营销活动营销活动营销活动营销活动营销活动3",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动4",
									},
								],
							},
							{
								name: "美业会员系统",
								dataList: []
							},
							{
								name: "社区团购系统",
								dataList: []
							},
						],
					},
					{
						name: "同城系统",
						list: [{
								name: "信息发布系统",
								dataList: []
							},
							{
								name: "招聘系统",
								dataList: [{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动1",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动2",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: false,
										descript: "营销活动营销活动营销活动营销活动营销活动3",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动4",
									},
								],
							},
							{
								name: "外卖跑腿",
								dataList: []
							},
							{
								name: "到家服务",
								dataList: [{
										name: "技术架构",
										fun_name: "Laravel架构",
										isSupport: true,
										descript: "采用laravel架构",
									},
									{
										name: "技术架构",
										fun_name: "支持php8.0",
										isSupport: true,
										descript: "php支持php8.0版本以上，对提高性能有帮助",
									},
									{
										name: "技术架构",
										fun_name: "安全防范",
										isSupport: true,
										descript: "防范CSRF攻击、SQL注入、密码加密算法、前后台的输入验证等方面最大化的加强系统安全系数",
									},
									{
										name: "技术架构",
										fun_name: "负载均衡	",
										isSupport: false,
										descript: "需购买大数据版实施部署费用",
									},
									{
										name: "技术架构",
										fun_name: "系统参数配置",
										isSupport: true,
										descript: "强大的系统参数配置，快速自定义配置",
									},
									{
										name: "系统对接",
										fun_name: "第三方登录",
										isSupport: true,
										descript: "支持微信小程序手机号授权登录",
									},
									{
										name: "系统对接",
										fun_name: "短信支持",
										isSupport: true,
										descript: "目前系统支持阿里云短信，其他短信通道陆续开发中",
									},
									{
										name: "系统对接",
										fun_name: "API开放接口",
										isSupport: true,
										descript: "开放接口供所需人员使用",
									},
									{
										name: "通用功能",
										fun_name: "账号密码登录",
										isSupport: true,
										descript: "使用手机账号+密码登录",
									},
									{
										name: "通用功能",
										fun_name: "短信验证码登录",
										isSupport: true,
										descript: "使用手机号码+短信验证码登录",
									},
									{
										name: "通用功能",
										fun_name: "微信手机号授权",
										isSupport: true,
										descript: "使用微信手机号授权登录",
									},
									{
										name: "通用功能",
										fun_name: "协议文本",
										isSupport: true,
										descript: "显示各种协议文本",
									},
									{
										name: "运营概况",
										fun_name: "数据概况",
										isSupport: true,
										descript: "用户注册量、店铺入驻量、信息发布量、付费发布统计等",
									},
									{
										name: "运营概况",
										fun_name: "快捷方式",
										isSupport: false,
										descript: "快速进入系统功能",
									},
								]
							},
						],
					},
					{
						name: "企业系统",
						list: [{
								name: "CRM系统",
								dataList: [{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动1",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动2",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: false,
										descript: "营销活动营销活动营销活动营销活动营销活动3",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动4",
									},
								],
							},
							{
								name: "进销存系统",
								dataList: []
							},
							{
								name: "办公OA系统",
								dataList: []
							},
							{
								name: "财务系统",
								dataList: []
							},
						],
					},
					{
						name: "常用功能",
						list: [{
								name: "SAAS租用",
								dataList: []
							},
							{
								name: "品牌定制",
								dataList: [{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动1",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动2",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: false,
										descript: "营销活动营销活动营销活动营销活动营销活动3",
									},
									{
										name: "营销活动",
										fun_name: "哈哈",
										isSupport: true,
										descript: "营销活动营销活动营销活动营销活动营销活动4",
									},
								],
							},
							{
								name: "功能定制",
								dataList: []
							},
							{
								name: "独立部署",
								dataList: []
							},
						],
					},
				],
				tableData: [{
						name: "营销活动",
						fun_name: "哈哈",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动1",
					},
					{
						name: "营销活动",
						fun_name: "哈哈",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动2",
					},
					{
						name: "营销活动",
						fun_name: "哈哈",
						isSupport: false,
						descript: "营销活动营销活动营销活动营销活动营销活动3",
					},
					{
						name: "营销活动",
						fun_name: "哈哈",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动4",
					},
					{
						name: "营销活动",
						fun_name: "哈哈",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "商家入驻",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "商家入驻",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "商家入驻",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "商家入驻",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "商家入驻",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "营销活动",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "营销活动",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "营销活动",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
					{
						name: "营销活动",
						isSupport: true,
						descript: "营销活动营销活动营销活动营销活动营销活动",
					},
				],
				mergeObj: {}, // 用来记录需要合并行的下标
				mergeArr: ["name"], // 表格中的列名
				navCurrent: 0,
			};
		},
		mounted() {
			document.documentElement.scrollTop = 0;
			document.body.scrollTop = 0;
			this.getSpanArr(this.tableData);
			var obj = {
				title: "灵狐网络科技—专注本地生活服务平台打造—功能列表", //分享标题
				desc: "", //分享内容
				linkurl: location.href, //分享链接
				img: "", //分享内容显示的图片
			};
			this.wxShare(obj);
		},
		methods: {
			getCurrent(index) {
				this.navCurrent = index;
			},
			getHoverCurrent(index) {
				this.current = index;
				this.tableData = this.navList[this.navCurrent].list[this.current].dataList;
				this.getSpanArr(this.tableData);
			},
			getSpanArr(data) {
				return this.mergeArr.forEach((key) => {
					let count = 0; // 用来记录需要合并行的起始位置
					this.mergeObj[key] = []; // 记录每一列的合并信息
					data.forEach((item, index) => {
						// index == 0表示数据为第一行，直接 push 一个 1
						if (index === 0) {
							this.mergeObj[key].push(1);
						} else {
							// 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
							if (item[key] === data[index - 1][key]) {
								this.mergeObj[key][count] += 1;
								this.mergeObj[key].push(0);
							} else {
								// 如果当前行和上一行其值不相等
								count = index; // 记录当前位置
								this.mergeObj[key].push(1); // 重新push 一个 1
							}
						}
					});
				});
			},
			objectSpanMethod({
				column,
				rowIndex
			}) {
				if (this.mergeArr.indexOf(column.property) !== -1) {
					// 判断其值是不是为0
					if (this.mergeObj[column.property][rowIndex]) {
						return [this.mergeObj[column.property][rowIndex], 1];
					} else {
						// 如果为0则为需要合并的行
						return [0, 0];
					}
				}
			},
		},
		onSubmit() {},
	};
</script>
<style scoped>
	.el-banner-items {
		margin: auto;
		background: url("../../../assets/image/about_us_3.jpg");
		background-size: cover;
		background-position: center;
		height: 520px;
		width: 100%;
	}

	.banner-items {
		width: 100%;
		height: 520px;
		background-color: rgb(0, 0, 0, 0.2);
	}

	.el-banner-content {
		width: 50%;
		height: 300px;
		margin: auto 0;
		text-align: left;
		color: #fff;
		animation-name: slide-in-right;
		animation-duration: 1.2s;
		animation-timing-function: ease-out;
		animation-delay: 0s;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-fill-mode: none;

		/* shorthand
              animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
	}

	.banner-span {
		margin: 30px 0;
		font-size: 14px;
	}

	.nav-box {
		padding: 20px 0;
		box-shadow: 5px 5px 15px 5px #7d88c11a;
		color: #333;
	}

	.nav-box li:hover {
		color: #f39b43;
	}

	.el-table-box {
		margin-bottom: 100px;
	}

	.el-second-box {
		width: 80%;
		margin: 60px auto;
		font-size: 15px;
	}

	.el-second-box li {
		padding: 10px 60px;
		border: 1px solid #ccc;
		color: #333;
	}

	.el-second-box .li-hover {
		background: linear-gradient(90deg,
				rgb(255, 178, 90) 0%,
				rgb(255, 133, 84) 100%);
		color: #fff;
		border: 0;
	}

	.el-icon-check {
		color: rgb(3, 196, 132);
		font-size: 20px;
		font-weight: 700;
	}

	.el-red-color {
		color: #f76d58;
	}

	.el-case-nav {
		padding: 15px 0;
		color: #777777;
		background-color: #fff;
	}

	.nav-bar {
		width: 100%;
		height: 3px;
		margin-top: 10px;
	}

	.nav-active {
		width: 100%;
		height: 3px;
		background: linear-gradient(90deg,
				rgb(255, 163, 58) 0%,
				rgb(255, 133, 84) 100%);
		margin: 0 auto;
		margin-top: 10px;
	}

	.nav-color {
		color: #ffab0e;
	}
</style>