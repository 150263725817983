<template>
  <div>
    <Header :num="0" :mobNum="0" />
    <div>
      <div class="el-banner-card">
        <el-carousel
          indicator-position="inside"
          trigger="hover"
          :interval="3000"
          :height="isMobile() ? '220px' : '600px'"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <div class="el-banner-items items-1">
              <div class="banner-items">
                <div
                  :class="
                    isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                  "
                >
                  <!-- <div class="el-banner-content el-flex-column el-align-start">
                    <h2 class="el-title-font-size">
                      最新动态最新动态最新动态最新动态
                    </h2>
                    <span class="banner-span"
                      >最新动态最新动态最新动态最新动态最新动态最新动态最新动态最新动态</span
                    >
                    <el-button round size="medium">查看更多</el-button>
                  </div> -->
                </div>
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.image_url"
                  slot="placeholder"
                  class="image-slot"
                  fit="cover"
                >
                  <div>加载中<span class="dot">...</span></div>
                </el-image>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="el-news-card">
        <div class="el-content-width">
          <div class="el-news-box el-flex-between" v-if="!isMobile()">
            <div class="el-flex">
              <div class="el-align-center el-margin-right20">
                <img src="../../../assets/image/news_icon.png" alt="" />
                <img src="../../../assets/image/news.png" alt="" />
              </div>
              <div
                class="el-news-item el-align-center"
                v-if="newsList.length != 0"
              >
                <img src="../../../assets/image/news-font.png" alt="" />
                <span class="news-date">{{
                  newsList[0] ? newsList[0].release_time.slice(5, 10) : ""
                }}</span>
                <span
                  class="news-font el-overflow-hidden"
                  @click="toDetail(newsList[0], 'dynamic')"
                  >{{ newsList[0] ? newsList[0].title : "" }}
                </span>
              </div>
            </div>
            <div
              class="el-news-item el-align-center"
              v-if="newsList.length > 1"
            >
              <span class="news-date">{{
                newsList[1] ? newsList[1].release_time.slice(5, 10) : ""
              }}</span>
              <span
                class="news-font el-overflow-hidden"
                @click="toDetail(newsList[1], 'dynamic')"
                >{{ newsList[1] ? newsList[1].title : "暂无内容" }}</span
              >
              <span style="margin: 0 10px; color: #999">|</span>
              <div class="el-align-center">
                <span
                  style="color: #999; font-size: 15px"
                  @click="toMore('dynamic')"
                  >查看更多</span
                >
                <i class="el-icon-arrow-right" style="color: #999"></i>
              </div>
            </div>
          </div>
          <div class="el-news-box el-flex-between" v-else>
            <div class="el-align-center">
              <img src="../../../assets/image/news-font.png" alt="" />
              <span
                class="news-date"
                style="font-size: 14px; margin: 2px 5px 0 5px; width: 40px"
                >{{
                  newsList[0] ? newsList[0].release_time.slice(5, 10) : ""
                }}</span
              >
              <span
                class="news-font el-overflow-hidden"
                @click="toDetail(newsList[0], 'dynamic')"
                >{{ newsList[0] ? newsList[0].title : "" }}</span
              >
            </div>
            <div class="el-align-center">
              <span style="margin: 0 5px; color: #999">|</span>
              <span
                style="color: #999; font-size: 14px"
                @click="toMore('dynamic')"
                >查看更多</span
              >
              <i class="el-icon-arrow-right" style="color: #999"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="el-section-card">
        <div class="el-content-width">
          <div class="el-title-box slide-top">
            <h1 class="el-font-color333 el-title-font-size">
              灵狐网络科技—专注本地生活平台打造
            </h1>
            <div class="el-section-title"></div>
            <p class="el-font-color999 font-style">
              由专业的团队提供支持，在保证安全性和可靠性的前提下，提供了更高质量的服务，可以满足企业各类需求。
              包含各种不同的产品和服务，适用于企业不同的需求。企业可以选择根据实际需要购买所需的服务，并灵活调整使用频率和规格,这种定制化能够更好地满足企业在不同时期的需求。
            </p>
          </div>
          <div class="el-flex section1-wrap slide-bottom">
            <div
              class="section1-box"
              v-for="(item, index) in sectionList"
              :key="index"
              :class="
                current_one == index
                  ? 'section1-box-active'
                  : 'section1-box-leave '
              "
              @mouseenter="getCurrentOne(index, 1)"
              @mouseleave="mouseLeave"
            >
              <div class="el-flex-between section1-icon">
                <h3 class="el-font-color333">{{ item.title }}</h3>
                <img :src="item.icon" alt="" />
              </div>
              <img :src="item.image" alt="" />
              <div
                class="item-content el-font-color333 font-style"
                style="line-height: 25px"
              >
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-section-card el-section2" ref="scrollOne">
        <div class="section2-bg"></div>
        <div class="el-content-width">
          <h1
            class="el-font-color333 el-title-font-size"
            :class="one_show ? 'text-animate' : ''"
          >
            产品体系
          </h1>
          <div class="el-section-title"></div>
          <div
            class="section2-content"
            :class="one_show ? 'section2-animate' : ''"
          >
            <ul class="section2-nav el-flex-around">
              <li
                :class="proCurrent == index ? 'section2-li-hover' : ''"
                v-for="(item, index) in productList"
                :key="index"
                @mouseenter="getProCurrent(item, index)"
              >
                {{ item.name }}
              </li>
            </ul>
            <div class="el-flex-between-start section2-items">
              <img :src=" productList[proCurrent].image" alt="" />
              <div class="section2-msg">
                <h1>{{ productList[proCurrent].name }}</h1>
                <span class="font-style"
                  >&nbsp;&nbsp;{{ productList[proCurrent].desc }}</span
                >
                <ul class="el-flex-between">
                  <li
                    class="el-flex-column"
                    v-for="(item, index) in productList[proCurrent].list"
                    :key="index"
                  >
                    <div class="img">
                      <img :src="item.icon" alt="" />
                    </div>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="el-section3" ref="scrollTwo">
        <div class="el-content-width">
          <h1
            class="el-font-color333 el-title-font-size"
            :class="two_show ? 'text-animate' : ''"
          >
            客户案例
          </h1>
          <div class="el-section-title"></div>
          <div
            class="section3-content"
            :class="two_show ? 'section3-animate' : ''"
          >
            <div class="el-flex-between nav-border">
              <h3>客户案例</h3>
              <ul class="el-flex section3-nav el-font-color777">
                <li
                  v-for="(item, index) in caseList"
                  :key="index"
                  @mouseenter="getCurrentOne(index, 2)"
                  @mouseleave="mouseLeave"
                  :class="current_two == index ? 'nav-active' : ''"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="section3-case">
              <div class="case-head el-spacing el-flex-between">
                <div>
                  <img :src="caseList[current_two].data.image" alt="" />
                </div>
                <div class="case-head-msg">
                  <h3>{{ caseList[current_two].name }}</h3>
                  <p class="el-margin-top30 el-font-color777 font-style">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ caseList[current_two].data.desc }}
                  </p>
                  <div class="el-margin-top60 el-flex">
                    <div class="case-button-1">查看详情</div>
                    <div class="case-button-2">前往体验</div>
                  </div>
                </div>
              </div>
              <!-- <ul class="el-flex-around">
              <li>
                <img src="" alt="" />
                <span></span>
              </li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul> -->
            </div>
          </div>
        </div>
      </div>
      <div class="el-section4" ref="scrollThree">
        <div class="el-content-width">
          <div :class="three_show ? 'slide-left' : ''">
            <h1 class="el-font-color333 el-title-font-size">
              灵狐科技提供一站式IT解决方案
            </h1>
            <div class="section4-text el-margin-top30 el-font-color999">
              选择灵狐网络科技，让你的企业快速迈向互联网+时代
            </div>
          </div>
          <ul class="el-flex" :class="three_show ? 'slide-right' : ''">
            <li class="el-flex-column-start section4-items">
              <img src="../../../assets/image/adv_1.png" alt="" />
              <h3>高效性</h3>
              <span>提供便捷、快捷解决方案</span>
            </li>
            <li class="el-flex-column-start section4-items">
              <img src="../../../assets/image/adv_2.png" alt="" />
              <h3>专业性</h3>
              <span>多年开发经验专业技术人员支持</span>
            </li>
            <li class="el-flex-column-start section4-items">
              <img src="../../../assets/image/adv_3.png" alt="" />
              <h3>安全性</h3>
              <span>安全、可靠、放心服务</span>
            </li>
            <li class="el-flex-column-start section4-items">
              <img src="../../../assets/image/adv_4.png" alt="" />
              <h3>稳定性</h3>
              <span>保障产品的稳定性</span>
            </li>
            <li class="el-flex-column-start section4-items">
              <img src="../../../assets/image/adv_5.png" alt="" />
              <h3>灵活性</h3>
              <span>根据需求灵活变动</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="el-section5">
        <div class="section2-bg"></div>
        <div class="el-content-width">
          <h1 class="el-font-color333 el-title-font-siz">荣誉资质</h1>
          <div class="el-section-title"></div>
        </div>
        <div class="section5-content el-flex">
          <div>
            <img
              src="https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/certifications.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/certifications.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="el-section-card" ref="scrollFour">
        <div class="el-content-width el-section7">
          <div :class="four_show ? 'slide-bottom' : ''">
            <h1 class="el-font-color333 el-title-font-size">我们的合作伙伴</h1>
            <div class="el-section-title"></div>
          </div>
          <p class="font-style">
            {{ partnerList.desc }}
          </p>
          <div class="section7-content" :class="four_show ? 'slide-top' : ''">
            <ul class="el-flex-between el-flex-wrap">
              <li v-for="(item, index) in partnerList.list" :key="index">
                <img :src="item.icon" :alt="item.title" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="el-section-card el-section6">
        <div class="el-content-width">
          <h1 class="el-font-color333 el-title-font-siz">相关资讯</h1>
          <div class="el-section-title"></div>
          <div class="el-flex-around el-margin-top60">
            <div
              class="section6-items"
              v-for="(item, index) in infoList"
              :key="index"
            >
              <div>
                <div
                  class="section6-items-title"
                  :style="{ backgroundImage: 'url(' + item.img + ')' }"
                >
                  <h3>{{ item.name }}</h3>
                  <p class="el-margin-top30">{{ item.desc }}</p>
                </div>
                <ul>
                  <li
                    v-for="(items, idx) in item.list"
                    :key="idx"
                    @click="toDetail(items, item.key)"
                  >
                    <i class="el-icon-disc">·</i> {{ items.title }}
                  </li>
                </ul>
                <div class="case-button-3" @click="toMore(item.key)">
                  查看更多
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cnav />
      <Footer />
    </div>
  </div>
</template>

<script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";

import axios from "axios";
export default {
  name: "HelloWorld",
  components: { Header, Cnav, Footer },
  data() {
    return {
      sectionList: [
        {
          title: "定制化",
          content:
            "建立在灵狐网络科技成熟的软件技术开发流程之上，并通过不断的迭代更新之后，定制出客户真正需要、真正喜欢的个性化产品",
          image: require("../../../assets/image/advs/advs_1.png"),
          icon: require("../../../assets/image/test_advs_1.png"),
        },
        {
          title: "专业化",
          content:
            "灵狐网络科技专注于互联网信息技术及软件系统技术服务，旨在为各大行业领域及商家提供优质技术产品服务，保障解决各商家技术难题，让平台、让商家拥有获取更多流量的技术奠基能力",
          image: require("../../../assets/image/advs/advs_2.png"),
          icon: require("../../../assets/image/test_advs_2.png"),
        },
        {
          title: "灵活性",
          content:
            "灵狐网络科技始终提供高效率、稳定化、个性化的技术服务，为客户提供全新的和创新性的途径来吸引消费者，同时提高在基础技术服务和定制服务的灵活性",
          image: require("../../../assets/image/advs/advs_3.png"),
          icon: require("../../../assets/image/test_advs_3.png"),
        },
        {
          title: "售后服务",
          content:
            "灵狐网络科技提供终身售后服务，让您安心的把项目交托给我们,免除“后顾之忧”。",
          image: require("../../../assets/image/advs/advs_4.png"),
          icon: require("../../../assets/image/test_advs_4.png"),
        },
      ],
      productList: [
        {
          name: "商城系统",
          desc: "构建企业级全终端+全场景电商解决方案，商城系统全渠道多终端覆盖，完善技术架构支撑企业级服务，支持开发+源码部署，打造数字化商业闭环，构建产业生态链",
					image: require("../../../assets/image/product_1.png"),
          list: [
            {
              name: "零售商城",
              icon: require("../../../assets/icon/function_marg_1.png"),
            },
            {
              name: "生鲜配送",
              icon: require("../../../assets/icon/function_marg_2.png"),
            },
            {
              name: "生鲜配送",
              icon: require("../../../assets/icon/function_marg_3.png"),
            },
            {
              name: "美业会员",
              icon: require("../../../assets/icon/function_marg_4.png"),
            },
          ],
        },
        {
          name: "同城系统",
          desc: "依托微信与抖音强大的流量体系和社交推广属性打造丰富的本地信息资讯等互动平台，赋能平台生活",
					image: require("../../../assets/image/product_2.png"),
          list: [
            {
              name: "外卖跑腿",
              icon: require("../../../assets/icon/function_marg_2.png"),
            },
            {
              name: "信息发布",
              icon: require("../../../assets/icon/function_marg_4.png"),
            },
            {
              name: "同城招聘",
              icon: require("../../../assets/icon/function_marg_3.png"),
            },
            {
              name: "家政服务",
              icon: require("../../../assets/icon/function_marg_1.png"),
            },
            {
              name: "到家服务",
              icon: require("../../../assets/icon/function_marg_5.png"),
            },
          ],
        },
        {
          name: "企业系统",
          desc: "致力于为中小企业提供互联网应用开发、微信支付宝小程序、公众号、CRM、ERP等系统研发，助力企业数字化转型",
					image: require("../../../assets/image/product_3.png"),
          list: [
            {
              name: "CRM系统",
              icon: require("../../../assets/icon/function_marg_4.png"),
            },
            {
              name: "进销存系统",
              icon: require("../../../assets/icon/function_marg_5.png"),
            },
            {
              name: "办公系统",
              icon: require("../../../assets/icon/function_marg_1.png"),
            },
            {
              name: "财务系统",
              icon: require("../../../assets/icon/function_marg_3.png"),
            },
            {
              name: "流程审批",
              icon: require("../../../assets/icon/function_marg_2.png"),
            },
          ],
        },
        {
          name: "SAAS服务",
          desc: "多元化服务类型全面满足用户，对前期创业、中期进阶、后期拓展所有阶段都提供有不同的价格、品牌化的针对性服务，让你拥有匹配你发展阶段的服务，拥有独立、自主、品牌发展空间更大",
					image: require("../../../assets/image/product_4.png"),
          list: [
            {
              name: "SAAS租用",
              icon: require("../../../assets/icon/function_marg_1.png"),
            },
            {
              name: "OEM品牌定制",
              icon: require("../../../assets/icon/function_marg_3.png"),
            },
            {
              name: "特殊功能定制",
              icon: require("../../../assets/icon/function_marg_4.png"),
            },
            {
              name: "独立部署",
              icon: require("../../../assets/icon/function_marg_2.png"),
            },
            {
              name: "高并发高负载",
              icon: require("../../../assets/icon/function_marg_5.png"),
            },
          ],
        },
      ],
      caseList: [
				{ 
					name: "云商城",
					data: {
						image: require("../../../assets/image/case/2.png"),
						desc: "系统适用于B2C、单商户、自营商城场景，完美契合私域流量，打造交易闭环。支持SAAS模式即多开商城可以实现只部署一套程序代码，同时使用多套商城，同时支持丰富的营销玩法，拼团、秒杀、优惠券、会员积分、分销等功能"
					}
				},
				{
					name: "生鲜商城",
					data: {
						image: require("../../../assets/image/case/3.png"),
						desc: "系统适用于自营商城系统，生鲜果蔬、海鲜水产、干货油粮、卤肉熟食、农贸等专卖行业。集合了多门店管理、支持多元化配送、统一管理为一体， 专门针对生鲜商户特殊定制的一款便捷、高效、快速的商城系统"
					}
				},
				{
					name: "信息发布",
					data: {
						image: require("../../../assets/image/case/4.png"),
						desc: "同城信息发布系统，求职招聘、二手交易、商家入驻、广告发布、信息分类、新闻资讯、会员体系。系统不仅为用户提供租用服务，还提供源码和独立部署服务，为创业者提供多种运营方案，从中选择适合自己的系统"
					}
				},
				{
					name: "无人小巴",
					data: {
						image: require("../../../assets/image/case/5.png"),
						desc: "为城市而生，给你带来不一样的出行体验，将无人驾驶带进现实。用户注册、扫码乘车、行程查看、乘车券发放、个人资料、意见反馈"
					}
				},
				{
					name: "一茶一友",
					data: {
						image: require("../../../assets/image/case/6.png"),
						desc: "为品牌会员提供优质丰富的天然生态茶叶，精美的茶叶周边配套产品。同时在一线城市核心商圈建立实体会所，为会员提供招待友人及精品展示赏析的场所"
					}
				},
				{ 
					name: "校友会",
					data: {
						image: require("../../../assets/image/case/1.png"),
						desc: "张家口学院校友会小程序专为张院校友交流学习打造的小程序,校友认证、找校友、校园风光、校友企业、新闻中心、校友风采、校友留言、组织活动等"
					}
				},
      ],
      partnerList: {
        desc: "实力出众的合作伙伴，给你带来稳定技术支撑，为您的创业之路保价护航",
        list: [
          {
            title: "阿里云",
            icon: require("../../../assets/image/partner/par_1.png"),
          },
          {
            title: "腾讯云",
            icon: require("../../../assets/image/partner/par_2.png"),
          },
          {
            title: "华为云",
            icon: require("../../../assets/image/partner/par_3.png"),
          },
          {
            title: "微信支付",
            icon: require("../../../assets/image/partner/par_4.png"),
          },
          {
            title: "支付宝",
            icon: require("../../../assets/image/partner/par_5.png"),
          },
          {
            title: "财付通",
            icon: require("../../../assets/image/partner/par_6.png"),
          },
          {
            title: "中国银联",
            icon: require("../../../assets/image/partner/par_7.png"),
          },
          {
            title: "美团开放平台",
            icon: require("../../../assets/image/partner/par_8.png"),
          },
          {
            title: "百度",
            icon: require("../../../assets/image/partner/par_9.png"),
          },
          {
            title: "顺风速运",
            icon: require("../../../assets/image/partner/par_10.png"),
          },
          {
            title: "尊云",
            icon: require("../../../assets/image/partner/par_11.png"),
          },
          {
            title: "西部数码",
            icon: require("../../../assets/image/partner/par_12.png"),
          },
        ],
      },
      bannerList: [
        // "https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/banners/1.png",
        // "https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/banners/2.png",
      ],
      current_one: -1,
      current_two: 0,
      proCurrent: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      newsList: [],
      infoList: [
        {
          name: "最新动态",
          img: require("../../../assets/image/test_bg_1.jpeg"),
          key: "dynamic",
          desc: "新闻动态，实时更新公司最新动态",
          list: [],
        },
        {
          name: "运营干货",
          img: require("../../../assets/image/test_bg_2.jpeg"),
          key: "operate",
          desc: "新媒体运营心得，满满的干货，等你来拿",
          list: [],
        },
        {
          name: "行业资讯",
          img: require("../../../assets/image/test_bg_3.jpeg"),
          key: "information",
          desc: "带你快速了解各色行情",
          list: [],
        },
      ],
      pageIndex: 1,
    };
  },
  mounted() {
    if (this.isMobile()) {
      this.infoList = [
        {
          name: "最新动态",
					img: require("../../../assets/image/test_bg_1.jpeg"),
          desc: "新闻动态，实时更新公司最新动态",
          key: "dynamic",
          list: [],
        },
      ];
    }
    this.getBanner();
    this.getNewsList();
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造", //分享标题
      desc: "", //分享内容
      linkurl: location.href.split("#")[0], //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getBanner() {
      axios
        .get("/banners/home_top")
        .then((res) => {
          this.bannerList = res.data.data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getNewsList() {
      this.infoList.forEach((item) => {
        axios
          .get("/articles", {
            params: { key: item.key, limit: 5, page: this.pageIndex },
          })
          .then((res) => {
            if (item.key == "dynamic") {
              this.newsList = res.data.data.list;
            }
            item.list = res.data.data.list;
          })
          .catch((error) => {
            alert(error);
          });
      });
    },
    toDetail(item, key) {
      axios.post("/articles/" + item.id + "/browse").catch((error) => {
        alert(error);
      });
      if (item.article_url) {
        window.open(item.article_url);
        return false;
      }
      this.$router.push({
        path: "/infoDetail",
        query: { id: item.id, key: key },
      });
    },
    toMore(key) {
      this.$router.push({ path: "/tradeInfo", query: { key: key } });
    },
    getCurrentOne(index, value) {
      if (value == 1) {
        this.current_one = index;
      } else if (value == 2) {
        this.current_two = index;
      }
    },
    getProCurrent(item, index) {
      this.selectProData = item;
      this.proCurrent = index;
    },
    mouseLeave() {
      this.current_one = -1;
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollOne) {
        let oneHeight = this.$refs.scrollOne.offsetHeight;
        let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.offsetHeight + threeHeight + 700;
        if (scrollTop > oneHeight) {
          this.one_show = true;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-banner-items {
  margin: auto;
  height: 600px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 600px;
  background-color: #11181e;
}
.el-banner-content {
  width: 50%;
  height: 300px;
  margin: auto 0;
  text-align: left;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
		animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.el-news-card {
  width: 100%;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
}
.el-news-box {
  width: 100%;
  height: 60px;
  margin: auto 0;
}
.el-news-box img {
  height: 26px;
  margin: auto 0;
}
.el-news-item {
  text-align: left;
  cursor: pointer;
}
.news-font {
  display: inline-block;
  width: 190px;
  font-size: 14px;
  font-weight: 700;
}
.news-date {
  width: 50px;
  margin: 2px 8px 0 8px;
  font-size: 16px;
  color: #999;
}
.el-section-card {
  width: 100%;
  padding: 60px 0;
}
.el-title-box p {
  margin: 10px;
  line-height: 25px;
}
.el-section-title {
  width: 130px;
  height: 6px;
  margin: 30px auto;
  background: linear-gradient(
    28deg,
    rgb(251, 148, 79) 18%,
    rgb(249, 170, 66) 100%
  );
}
.font-style {
  line-height: 30px;
  margin: 30px 0;
  font-size: 15px;
}
.section1-box {
  width: 25%;
  padding: 20px;
  margin: 10px;
  box-shadow: 0px 5px 15px 0px #7d88c11a;
  text-align: left;
}
.section1-box img {
  width: 100%;
  height: 150px;
  margin-top: 30px;
}
.section1-box .item-content {
  margin: 20px 0;
}
.section1-icon img {
  width: 40px;
  height: 40px;
  margin-top: 0;
}
.section1-icon {
  padding-bottom: 20px;
  border-bottom: #dae2f2 solid 1px;
}
.section1-box-active {
  animation-name: slide-top;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;

  /* shorthand
		animation: slide-top 1s linear 0s 1 normal none;*/
}
.section1-box-leave {
  animation-name: slide-bottom;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;

  /* shorthand
		animation: slide-bottom 1s ease-out 0s 1 normal forwards;*/
}
.el-section2 {
  position: relative;
  margin-top: 80px;
}

.el-section2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
  background: linear-gradient(
    28deg,
    rgb(255 217 80 / 75%) 18%,
    rgba(255, 154, 81, 0.72) 100%
  );
}
.section2-content {
  margin: 40px auto;
  width: 100%;
  padding: 35px 35px 60px 35px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 5px 5px 15px 5px #c1ac7d3c;
}
.section2-nav li {
  width: 18%;
  height: 50px;
  line-height: 50px;
  background-color: #ffedcd;
  color: #333;
  border-radius: 40px;
}
.section2-nav .section2-li-hover {
  background-color: #ffbd4c;
  font-weight: 700;
  color: #fff;
}
.section2-items {
  margin-top: 50px;
}
.section2-items img {
  width: 40%;
  height: 330px;
}
.section2-msg {
  text-align: left;
  width: 55%;
}

.section2-msg span {
  display: block;
  line-height: 30px;
  color: #333;
  margin: 30px 0;
  font-size: 15px;
}
.section2-msg ul {
  margin-top: 60px;
}
.section2-msg ul span {
  display: block;
  font-size: 15px;
  margin: 10px 0;
  color: #666;
}
.section2-msg li .img {
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffd2715e;
}
.section2-msg img {
  width: 100%;
  height: 100%;
}
.el-section3 {
  padding: 30px 0 60px 0;
  background: url("../../../assets/image/test_section_bg.jpg");
  background-size: cover;
  background-position: center;
}
.section3-content {
  width: 100%;
  margin: 30px auto;
}
.nav-border {
  margin-top: 60px;
  padding-bottom: 15px;
  border-bottom: #cfcfcf solid 2px;
}
.section3-nav {
  width: 50%;
}
.section3-nav ul {
  width: 50%;
}
.section3-nav li {
  position: relative;
  width: 25%;
  font-size: 15px;
  font-weight: 700;
}
.section3-case {
  width: 100%;
  margin-top: 30px;
  box-shadow: 5px 5px 15px 5px #7d88c11a;
  background-color: #fff;
}
.nav-active {
  color: #f08519;
  /* opacity: 0.5; */
  transition: all 1s ease;
}
.case-head-msg {
  padding: 20px 20px;
  text-align: left;
  width: 60%;
}
.case-button-2 {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  color: #fff;
  background: linear-gradient(
    28deg,
    rgb(251, 148, 79) 18%,
    rgb(249, 170, 66) 100%
  );
  margin-left: 20px;
}
.case-button-1 {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  color: #999;
  border: #999 solid 1px;
}
.case-head img {
  width: 100%;
}
.el-section4 {
  padding: 130px 0;
  box-shadow: 5px 5px 15px 5px #7d88c11a;
}
.el-section4 ul {
  margin-top: 60px;
}
.section4-text {
  width: 80%;
  line-height: 30px;
  margin: 30px auto;
}
.section4-items {
  width: 25%;
  padding: 10px;
}
.section4-items img {
  width: 80px;
  height: 80px;
}
.section4-items h3 {
  padding: 10px;
}
.section4-items span {
  font-size: 15px;
  color: #999;
}
.el-section5 {
  background-color: #f0f4fc;
  overflow: hidden;
  padding: 100px 0 40px 0;
}
.section5-content {
  padding: 50px 0;
  width: 10000px;
  animation-duration: 100s;
  animation-name: slide-in-left;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
.section5-content img {
  height: 320px;
}
.section5-content:hover {
  animation: 100s slide-in-left linear infinite paused;
}
.el-section6 {
  background-color: #f0f4fc;
}
.section6-items {
  width: 30%;
  padding-bottom: 30px;
  box-shadow: 5px 5px 15px 5px #7d88c11a;
  text-align: left;
  background: #fff;
}
.section6-items ul {
  padding: 20px;
  height: 200px;
}
.section6-items ul li {
  width: 88%;
  margin: 10px 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #3f3f3f;
}
.section6-items ul li:hover {
  color: #f08519;
}
.el-icon-disc {
  color: #ccc;
  font-size: 20px;
  font-weight: 900;
}
.section6-items-title {
  color: #fff;
  background: url("../../../assets/image/test_bg_1.jpeg");
  background-size: cover;
  background-position: center;
  padding: 30px;
}
.section7-content ul li {
  width: 20%;
  height: 80px;
  margin: 20px;
}
.section7-content ul li img {
  width: 100%;
  height: 100%;
  /* border: #c8c8c8 solid 1px; */
}
.el-section7 p {
  width: 60%;
  font-size: 15px;
  color: #666;
  margin: 30px auto;
}
.case-button-3 {
  width: 120px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
  color: #fff;
  background: linear-gradient(
    28deg,
    rgb(251, 148, 79) 18%,
    rgb(249, 170, 66) 100%
  );
  margin: auto;
  cursor: pointer;
}
.text-animate {
  -webkit-animation: tracking-in-contract 1.2s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.section2-animate {
  -webkit-animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.section3-animate {
  -webkit-animation: slide-in-elliptic-bottom-fwd 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-bottom-fwd 1.2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
    transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
    transform-origin: 50% -1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
    transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
    transform-origin: 50% -1400px;
    opacity: 1;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateZ(0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(20px);
  }
}
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes slide-in-blurred-right {
  0% {
    transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-top {
  animation: slide-in-top 1.2s ease-out 0s 1 normal none;
}
@keyframes slide-in-top {
  0% {
    transform: translateY(-180px);
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}
.slide-bottom {
  animation: slide-in-bottom 1.2s ease-out 0s 1 normal none;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(180px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-left {
  animation: slide-in-bck-left 1s ease-out 0s 1 normal none;
}
@keyframes slide-in-bck-left {
  0% {
    transform: translateZ(700px) translateX(-400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
.slide-right {
  animation: slide-in-bck-right 1s ease-out 0s 1 normal none;
}
@keyframes slide-in-bck-right {
  0% {
    transform: translateZ(700px) translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@media only screen and (max-width: 800px) {
  .el-banner-items {
    margin: auto;
    background-size: cover;
    background-position: center;
    height: 220px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 220px;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .banner-items img {
    width: 100%;
    height: 100%;
  }
  .el-banner-content {
    width: 100%;
    height: 20px;
    margin: auto 0;
    text-align: left;
    padding: 20px;
    color: #fff;
    animation-name: slide-in-right;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
  }
  .el-news-card {
    width: 100%;
    box-shadow: 0px 5px 15px 0px #7d88c11a;
  }
  .news-font {
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
  }
  .el-section-card {
    width: 100%;
    padding: 40px 0;
  }
  .section1-wrap {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .section1-box {
    width: 45%;
    padding: 10px;
    margin: 0;
  }
  .section1-box img {
    width: 100%;
    height: 100px;
    margin-top: 15px;
  }
  .section1-box .item-content {
    margin: 18px 0;
  }
  .section1-icon {
    padding-bottom: 10px;
  }
  .section1-icon h3 {
    font-size: 15px;
  }
  .section1-icon img {
    width: 26px;
    height: 26px;
    margin-top: 5px;
  }
  .section2-content {
    margin: 20px auto;
    width: 100%;
    height: 550px;
    padding: 15px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 5px 5px 15px 5px #7d88c11a;
  }
  .el-flex-between-start {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section2-nav li {
    width: 23%;
    height: 25px;
    line-height: 25px;
  }
  .section2-items {
    margin-top: 20px;
  }
  .section2-items img {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }
  .section2-msg {
    width: 100%;
  }
  .section2-msg ul {
    margin-top: 10px;
  }
  .section2-msg ul span {
    display: block;
    font-size: 11px;
    margin: 10px 0;
    color: #666;
  }
  .section2-msg li .img {
    width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    background-color: #ffd2715e;
  }
  .section2-msg li img {
    width: 100%;
    height: 100%;
  }
  .section2-msg span {
    margin: 10px 0;
    line-height: 20px;
    font-size: 11px;
  }
  .el-section3 {
    background: url("../../../assets/image/test_section_bg.jpg");
    background-size: cover;
    background-position: center;
    margin-top: 10px;
  }
  .section3-content {
    width: 100%;
    margin: 30px auto;
  }
  .section3-nav {
    width: 75%;
  }
  .section3-nav li {
    font-size: 12px;
  }
  .el-section-title {
    width: 90px;
    height: 4px;
    margin: 20px auto;
  }
  .section3-content .nav-border {
    margin-top: 20px;
  }
  .section3-case .el-flex-between {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .case-head {
    padding: 20px 10px;
  }
  .case-head img {
    width: 100%;
    height: 200px;
  }
  .case-head-msg {
    width: 100%;
  }
  .case-head-msg .el-flex {
    justify-content: space-around;
  }
  .case-button-1 {
    width: 80px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
  .case-button-2 {
    width: 80px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }
  .el-section4 {
    padding: 100px 0;
    box-shadow: 5px 5px 15px 5px #7d88c11a;
  }
  .el-section4 ul {
    margin-top: 60px;
    display: flex;
    flex-flow: row wrap;
  }
  .section4-text {
    width: 100%;
    line-height: 30px;
    margin: 10px auto;
  }
  .section4-items {
    width: 33%;
    padding: 10px;
  }
  .section4-items img {
    width: 45px;
    height: 45px;
  }
  .section4-items h3 {
    padding: 10px;
  }
  .section4-items span {
    font-size: 12px;
    color: #999;
  }
  .el-section5 {
    background-color: #f0f4fc;
    overflow: hidden;
    padding: 50px 0;
  }
  .section5-content {
    padding: 10px 0;
    width: 10000px;
    animation-duration: 100s;
    animation-name: slide-in-left;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
  }
  .section6-items {
    width: 100%;
    box-shadow: 5px 5px 15px 5px #7d88c11a;
    text-align: left;
    background: #fff;
  }
  .section6-items {
    padding-bottom: 20px;
  }
  .section6-items-title h3 {
    font-size: 20px;
  }
  .section6-items-title p {
    font-size: 14px;
  }

  .section7-content ul li {
    width: 31%;
    height: 80px;
    margin: 3px;
  }
  .section7-content ul li img {
    width: 100%;
    height: 100%;
    /* border: #c8c8c8 solid 1px; */
  }
  .el-section7 p {
    width: 100%;
    font-size: 11px;
    color: #666;
    margin: 30px auto;
  }
  .font-style {
    line-height: 30px;
    margin: 30px 0;
    font-size: 12px;
  }
}
</style>
