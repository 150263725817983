//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
// 引入路由组件
import webHome from '@/page/tabbarPage/webHome/webHome.vue'
import customerCase from '@/page/tabbarPage/customerCase/customerCase.vue'
import enterpriseProduct from '@/page/productPage/enterpriseProduct/enterpriseProduct.vue'
import enterpriseFinance from '@/page/productPage/enterpriseFinance/enterpriseFinance.vue'
import enterpriseInvoice from '@/page/productPage/enterpriseInvoice/enterpriseInvoice.vue'
import enterpriseWork from '@/page/productPage/enterpriseWork/enterpriseWork.vue'
import storeProduct from '@/page/productPage/storeProduct/storeProduct.vue'
import storeFresh from '@/page/productPage/storeFresh/storeFresh.vue'
import storeVipcard from '@/page/productPage/storeVipcard/storeVipcard.vue'
import storeGroup from '@/page/productPage/storeGroup/storeGroup.vue'
import cityProduct from '@/page/productPage/cityProduct/cityProduct.vue'
import cityHands from '@/page/productPage/cityHands/cityHands.vue'
import cityHome from '@/page/productPage/cityHome/cityHome.vue'
import takeawayProduct from '@/page/productPage/takeawayProduct/takeawayProduct.vue'
import servicePage from '@/page/productPage/servicePage/servicePage.vue'
import aboutUs from '@/page/functionPage/aboutUs/aboutUs.vue'
import tradeInfo from '@/page/tabbarPage/tradeInfo/tradeInfo.vue'
import infoDetail from '@/page/tabbarPage/tradeInfo/infoDetail.vue'
import functionList from '@/page/functionPage/functionList/functionList.vue'
import qustionPage from '@/page/functionPage/qustionPage/qustionPage.vue'
import updateLog from '@/page/functionPage/updateLog/updateLog.vue'
import testPage from '@/page/testPage/testPage/testPage.vue'
//2.使用路由
Vue.use(VueRouter);
// 把VueRouter原型对象push，保存一份
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace

// 重写push|replace
// 第一个参数：告诉原来的push方法，往哪里跳转（传递哪些参数）
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, () => { }, () => { })
  }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject)
  } else {
    originReplace.call(this, location, () => { }, () => { })
  }
}

//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: 'hash',
  //4.配置路由的path和组件
  routes: [
    {
      path: '/',
      name: 'webHome',
      component: webHome
    },
    {
      path: '/testPage',
      name: 'testPage',
      component: testPage
    },
    {
      path: '/customerCase',
      name: 'customerCase',
      component: customerCase
    }, {
      path: '/service',
      name: 'servicePage',
      component: servicePage
    },
    // 重定向,在项目跑起来时，访问/，定向到/home
    {
      path: '*',
      redirect: "/"
    }, {
      path: '/crm',
      name: 'enterpriseProduct',
      component: enterpriseProduct
    }, {
      path: '/fc',
      name: 'enterpriseFinance',
      component: enterpriseFinance
    }, {
      path: '/jxc',
      name: 'enterpriseInvoice',
      component: enterpriseInvoice
    }, {
      path: '/oa',
      name: 'enterpriseWork',
      component: enterpriseWork
    }, {
      path: '/mall',
      name: 'storeProduct',
      component: storeProduct
    }, {
      path: '/fresh',
      name: 'storeFresh',
      component: storeFresh
    }, {
      path: '/group',
      name: 'storeGroup',
      component: storeGroup
    }, {
      path: '/vip',
      name: 'storeVipcard',
      component: storeVipcard
    }, {
      path: '/cityProduct',
      name: 'cityProduct',
      component: cityProduct
    }, {
      path: '/cityHome',
      name: 'cityHome',
      component: cityHome
    }, {
      path: '/job',
      name: 'cityHands',
      component: cityHands
    }, {
      path: '/wm',
      name: 'takeawayProduct',
      component: takeawayProduct
    }, {
      path: '/aboutUs',
      name: 'aboutUs',
      component: aboutUs
    }, {
      path: '/tradeInfo',
      name: 'tradeInfo',
      component: tradeInfo
    }, {
      path: '/infoDetail',
      name: 'infoDetail',
      component: infoDetail
    }, {
      path: '/functionList',
      name: 'functionList',
      component: functionList
    }, {
      path: '/qustionPage',
      name: 'qustionPage',
      component: qustionPage
    }, {
      path: '/updateLog',
      name: 'updateLog',
      component: updateLog
    }
  ]
})
//5.导入路由实例
export default router