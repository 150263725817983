<template>
  <div>
    <Header :num="3" />
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '200px' : '320px'"
      >
        <el-carousel-item v-for="(item, index) in 1" :key="index">
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h2 class="el-title-font-size">
                    最新动态最新动态最新动态最新动态
                  </h2>
                  <span class="banner-span"
                    >最新动态最新动态最新动态最新动态最新动态最新动态最新动态最新动态</span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{ infoTitle }}</el-breadcrumb-item>
          <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
          <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="section-content" v-if="articles">
          <h1>
            {{ articles.title }}
          </h1>
          <div class="el-algin-center section-title">
            <i class="el-icon-time"></i>
            <span>发布于：{{ getTime(articles.release_time) }}</span>
          </div>
          <div class="el-articles-content" v-html="articles.content"></div>
        </div>
      </div>
    </div>
    <Cnav />
    <Footer />
  </div>
</template>
    <script>
import axios from "axios";
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
export default {
  components: { Header, Cnav, Footer },
  data() {
    return {
      input: "",
      id: 0,
      articles: null,
      infoTitle: "",
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getContent();
    if (this.$route.query.key) {
      this.key = this.$route.query.key;
    }
    if (this.key == "dynamic") {
      this.infoTitle = "最新动态";
    } else if (this.key == "operate") {
      this.infoTitle = "运营干货";
    } else if (this.key == "information") {
      this.infoTitle = "行业资讯";
    }
  },
  methods: {
    getContent() {
      axios
        .get("/articles/" + this.id)
        .then((res) => {
          this.articles = res.data.data;
          console.log(this.articles.title);
          this.articles.content = this.articles.content.replace(
            /<img/g,
            "<img style='max-width:100%;height:auto;margin-top:20px;'"
          );
          let obj = {
            title: this.articles
              ? this.articles.title
              : "灵狐网络科技—专注本地生活服务平台打造", //分享标题
            desc: "", //分享内容
            linkurl: location.href, //分享链接
            img: this.articles
              ? this.articles.thumb_image
              : "https://foxonline-static.oss-cn-beijing.aliyuncs.com/website/public/logo.png", //分享内容显示的图片
          };
          this.wxShare(obj);
        })
        .catch((error) => {
          alert(error);
        });
    },
    getTime(string) {
      return string.slice(0, -3);
    },
  },
};
</script>
    <style scoped>
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/about_us_3.jpg");
  background-size: cover;
  background-position: center;
  height: 320px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 320px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 50%;
  height: 300px;
  margin: auto 0;
  text-align: left;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
            animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.section-card-box {
  padding: 80px 0;
}
.section-content {
  margin: 60px 0;
  text-align: left;
}
.section-content h1 {
  color: #333;
}
.section-title {
  margin: 30px 0;
  padding-bottom: 40px;
  font-size: 14px;
  color: #666;
  border-bottom: #e1e1e1 solid 1px;
}
.section-content .section-img {
  width: 100%;
  height: 330px;
  margin-bottom: 20px;
  overflow: hidden;
}
.section-img img {
  width: 100%;
}
.section-content p {
  font-size: 15px;
  color: #666;
  line-height: 30px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .el-banner-items {
    margin: auto;
    background: url("../../../assets/image/about_us_3.jpg");
    background-size: cover;
    background-position: center;
    height: 320px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 320px;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .el-banner-content {
    width: 100%;
    height: 300px;
    padding: 10px;
    margin: auto 0;
    text-align: left;
    color: #fff;
    animation-name: slide-in-right;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
            animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
  }
  .banner-span {
    margin: 30px 0;
    font-size: 14px;
  }
  .section-card-box {
    padding: 30px 0;
  }
  .section-content {
    margin: 60px 0;
    text-align: left;
  }
  .section-content h1 {
    font-size: 15px;
    color: #333;
  }
  .section-title {
    margin: 20px 0;
    padding-bottom: 40px;
    font-size: 12px;
    color: #666;
    border-bottom: #e1e1e1 solid 1px;
  }
  .section-content .section-img {
    width: 100%;
    height: 130px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .section-img img {
    width: 100%;
  }
  .section-content p {
    padding: 0 20px;
    font-size: 12px;
    color: #666;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .section-content p span {
    display: block;
    padding: 10px 0;
    font-weight: 700;
  }
}
</style>