<template>
  <div class="section-card-box">
    <div class="el-content-width">
      <div class="" :class="isShow ? 'slide-bottom' : ''">
        <div class="section-title">
          <h2 class="el-font-color333">{{ content.title }}</h2>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">{{ content.desc }}</p>
      </div>
      <div
        class="section10-center-1 slide-left"
        v-if="isMobile()"
        :style="{ backgroundColor: content.bgColor_1 }"
      >
        <div
          class="section10-center-2"
          :style="{ backgroundColor: content.bgColor_2 }"
        >
          <div
            class="section10-center-3"
            :style="{ backgroundColor: content.bgColor_3 }"
          >
            <h3>{{ content.title_center }}</h3>
          </div>
        </div>
      </div>
      <div
        class="section10-content el-flex-between"
        :class="isShow ? 'slide-top' : ''"
      >
        <ul>
          <li v-for="(item, index) in content.list_one" :key="index">
            <div
              class="section10-img"
              :style="{ backgroundColor: item.iconBgcolor }"
            >
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ item.name }}</h3>
            <p>
              {{ item.desc }}
            </p>
          </li>
        </ul>
        <div
          class="section10-center-1"
          v-if="!isMobile()"
          :style="{ backgroundColor: content.bgColor_1 }"
        >
          <div
            class="section10-center-2"
            :style="{ backgroundColor: content.bgColor_2 }"
          >
            <div
              class="section10-center-3"
              :style="{ backgroundColor: content.bgColor_3 }"
            >
              <h3>{{ content.title_center }}</h3>
            </div>
          </div>
        </div>
        <ul>
          <li v-for="(item, index) in content.list_two" :key="index">
            <div
              class="section10-img"
              :style="{ backgroundColor: item.iconBgcolor }"
            >
              <img :src="item.icon" alt="" />
            </div>
            <h3>{{ item.name }}</h3>
            <p>
              {{ item.desc }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script >
export default {
  name: "sectionTwo",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
};
</script>
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.section-text {
  width: 65%;
  margin: 20px auto;
  font-size: 15px;
  color: #333;
}

.section10-content {
  margin: 60px 0;
}
.section10-content ul {
  width: 28%;
}
.section10-content li {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0px 10px 15px 0px #9c9c9c48;
  border-radius: 25px;
}
.section10-content li .section10-img {
  width: 80px;
  height: 80px;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 10px;
  background-color: #fcffda;
  border-radius: 50%;
}
.section10-content li img {
  width: 100%;
  height: 100%;
}
.section10-content li h3 {
  color: #333;
}
.section10-content li p {
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 15px;
  color: #666;
}
.section10-center-1 {
  width: 300px;
  height: 300px;
  line-height: 300px;
  background-color: #ffd9c6;
  border-radius: 50%;
  box-shadow: 0px 5px 15px 0px #0000001a;
}
.section10-center-2 {
  width: 270px;
  height: 270px;
  line-height: 270px;
  margin: auto auto;
  background-color: #ffbc9b;
  border-radius: 50%;
}
.section10-center-3 {
  width: 220px;
  height: 220px;
  line-height: 220px;
  margin: auto auto;
  background-color: #ffa77a;
  border-radius: 50%;
  font-size: 23px;
  color: #fff;
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .section10-content ul {
    width: 48%;
  }
  .section10-content li {
    width: 100%;
    background-color: #fff;
    margin-bottom: 70px;
    padding: 20px;
    box-shadow: 0px 10px 15px 0px #f78d6d48;
    border-radius: 25px;
  }
  .section10-content li .section10-img {
    width: 80px;
    height: 80px;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
    background-color: #fffde4;
    border-radius: 50%;
  }
  .section10-content li img {
    width: 100%;
    height: 100%;
  }
  .section10-content li h3 {
    color: #333;
  }
  .section10-content li p {
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
    font-size: 10px;
    color: #666;
  }
  .section10-center-1 {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%;
    box-shadow: 0px 5px 15px 0px #0000001a;
  }
  .section10-center-2 {
    width: 170px;
    height: 170px;
    line-height: 170px;
    margin: auto auto;
    border-radius: 50%;
  }
  .section10-center-3 {
    width: 120px;
    height: 120px;
    line-height: 120px;
    margin: auto auto;
    border-radius: 50%;
    font-size: 23px;
    color: #fff;
  }
}
</style>