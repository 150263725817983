import { render, staticRenderFns } from "./sectionThree.vue?vue&type=template&id=7047ea35&scoped=true"
import script from "./sectionThree.vue?vue&type=script&lang=js"
export * from "./sectionThree.vue?vue&type=script&lang=js"
import style0 from "./sectionThree.vue?vue&type=style&index=0&id=7047ea35&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7047ea35",
  null
  
)

export default component.exports