<template>
  <div class="section-card-box el-section3">
    <div class="el-content-width">
      <div :class="isShow ? 'slide-right' : ''">
        <div class="section-title">
          <h3 class="el-font-color333" style="font-weight: 500">
            {{ content.title }}
          </h3>
          <div class="nav-active" style="width: 80px"></div>
        </div>
        <h1 class="el-font-color333">{{ content.title_two }}</h1>
        <p class="section-text">
          {{ content.desc }}
        </p>
      </div>
      <div class="section3-content" :class="isShow ? 'slide-left' : ''">
        <ul class="el-flex-between-start">
          <li
            class="el-flex-cloumn"
            :style="{ marginTop: index % 2 == 0 ? 80 + 'px' : 0 }"
            v-for="(items, index) in content.list"
            :key="index"
          >
            <div class="img-bg">
              <img :src="items.img" alt="" />
            </div>
            <h3>{{ items.name }}</h3>
            <span>{{ items.desc }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  name: "sectionThree",
  props: {
    isShow: { type: Boolean, required: false },
    content: { type: Object, required: {} },
  },
};
</script>
  
  <style scoped>
.section-card-box {
  width: 100%;
  padding: 60px 0;
}
.section-title {
  margin: 20px auto;
  width: 100px;
  text-align: center;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.el-section3 {
  background: url("../../assets/image/test_section_bg.jpg");
  background-size: 190%;
  background-repeat: no-repeat;
}
.section3-content {
  margin: 60px 0;
}
.section3-content li {
  width: 23%;
  height: 370px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 30px;
}
.section3-content li img:hover {
  animation: bg-pan-bl 0.43s linear 0s 1 normal forwards;
}

.section3-content li .img-bg {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  background-color: #e3fdff;
  border-radius: 50%;
  padding: 15px;
}
.section3-content li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.section3-content li h3 {
  color: #333;
  margin: 20px;
}
.section3-content li span {
  color: #696969;
  font-size: 14px;
}
@keyframes bg-pan-bl {
  0% {
    background-position: center;
    width: 100%;
    height: 100%;
  }
  100% {
    width: 109%;
    height: 109%;
  }
}
@media only screen and (max-width: 800px) {
  .section-card-box {
    width: 100%;
    padding: 30px 0;
  }
  .section-title {
    margin: 20px auto;
    width: 100px;
    text-align: center;
  }
  .section-p {
    width: 60%;
    margin: 0 auto;
    font-size: 15px;
  }
  .section-text {
    width: 100%;
    margin: 20px auto;
    font-size: 10px;
    color: #666;
  }
  .el-section3 {
    background: url("../../assets/image/test_section_bg.jpg");
    background-repeat: no-repeat;
  }
  .section3-content {
    margin: 30px 0;
  }
  .section3-content li {
    width: 23%;
    height: 220px;
    padding: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 15px 0px #0000001a;
    border-radius: 30px;
  }
  .section3-content li img:hover {
    animation: zoom-in-out 2s linear 0s infinite normal none;
  }
  @keyframes zoom-in-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .section3-content li .img-bg {
    width: 100%;
    height: 80px;
    margin: 0 auto;
    background-color: #e3fdff;
    border-radius: 50%;
    padding: 5px;
  }
  .section3-content li img {
    width: 100%;
    border-radius: 50%;
  }
  .section3-content li h3 {
    color: #333;
    margin: 5px;
  }
  .section3-content li span {
    color: #696969;
    font-size: 10px;
  }
  @keyframes bg-pan-bl {
    0% {
      background-position: center;
      width: 100%;
      height: 100%;
    }
    100% {
      width: 109%;
      height: 109%;
    }
  }
}
</style>