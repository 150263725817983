<template>
  <div>
    <Header :num="3" :mobNum="2" />
    <div class="el-banner-card">
      <el-carousel
        indicator-position="inside"
        trigger="hover"
        :interval="3000"
        :height="isMobile() ? '200px' : '320px'"
      >
        <el-carousel-item v-for="(item, index) in 3" :key="index">
          <div class="el-banner-items">
            <div class="banner-items">
              <div
                :class="
                  isMobile() ? '.el-mobile-content-width' : 'el-content-width'
                "
              >
                <div class="el-banner-content el-flex-column el-align-start">
                  <h2 class="el-title-font-size">
                    新动态追踪全行业了解科技发展新风向
                  </h2>
                  <span class="banner-span"
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="el-content-width el-case-nav" v-if="isAll">
      <ul class="el-flex-between">
        <li
          class="el-flex-column"
          v-for="(item, index) in navList"
          :key="index"
          @click="getCurrent(index, item.key)"
        >
          <span :class="current == index ? 'nav-color' : ''">{{
            item.name
          }}</span>
          <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
        </li>
      </ul>
    </div>
    <div class="section-card-box">
      <div class="el-content-width">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{ infoTitle }}</el-breadcrumb-item>
          <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="section-content el-flex-between-start">
          <div class="list-box">
            <ul v-if="infoList.length != 0">
              <li
                class="el-flex-between-start"
                v-for="(item, index) in infoList"
                :key="index"
                @click="toDetail(item, key)"
              >
                <el-image
                  style="width: 25%; height: 90px"
                  :src="
                    item.thumb_image
                      ? item.thumb_image
                      : require('../../../assets/image/info_test.png')
                  "
                  slot="placeholder"
                  class="image-slot"
                  fit="cover"
                >
                  <div>加载中<span class="dot">...</span></div>
                </el-image>
                <div class="li-msg">
                  <h2 class="el-overflow-hidden">
                    {{ item.title }}
                  </h2>
                  <p class="el-overflow-hidden" v-if="!isMobile()">
                    {{ item.describe }}
                  </p>
                  <div class="el-flex-between">
                    <div class="el-algin-center">
                      <i class="el-icon-time"></i>
                      <span>{{ getTime(item.release_time) }}</span>
                    </div>
                    <div class="el-algin-center">
                      <i class="el-icon-view"></i>
                      <span>{{ item.browse_count }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <el-empty description="暂时还没有相关资讯哦~" v-else></el-empty>
          </div>
          <div class="search-box" v-if="!isMobile()">
            <div class="el-align-center">
              <el-input
                v-model="search_value"
                placeholder="请输入搜索关键词"
              ></el-input>
              <el-button type="warning" @click="toSearch">搜索</el-button>
            </div>
            <ul v-if="searchList.length != 0">
              <li
                v-for="(item, index) in searchList"
                :key="index"
                @click="toDetail(item, key)"
              >
                {{ item.title }}
              </li>
            </ul>
            <el-empty description="没有搜到相关资讯哦~" v-else></el-empty>
          </div>
        </div>
        <el-pagination
          :background="true"
          layout="prev, pager, next"
          :total="totalPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <Cnav />
    <Footer />
  </div>
</template>
  <script>
import axios from "axios";
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
export default {
  components: { Header, Cnav, Footer },
  data() {
    return {
      search_value: "",
      isAll: false,
      navList: [
        { name: "行业资讯", key: "information" },
        { name: "最新动态", key: "dynamic" },
        { name: "运营干货", key: "operate" },
      ],
      infoList: [],
      current: 0,
      key: "information",
      infoTitle: "行业资讯",
      pageIndex: 1,
      totalPage: 10,
      searchList: [],
    };
  },
  mounted() {
    this.isAll = this.$route.query.isAll ? true : false;
    if (this.$route.query.key) {
      this.key = this.$route.query.key;
    }
    this.checkKey();
    this.getInfoList();
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—资讯列表", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getTime(string) {
      return string.slice(0, -3);
    },
    getInfoList() {
      axios
        .get("/articles", {
          params: { limit: 5, page: this.pageIndex, key: this.key },
        })
        .then((res) => {
          this.infoList = res.data.data.list;
          this.totalPage = Math.ceil(res.data.data.count / 5) * 10;
        })
        .catch((error) => {
          alert(error);
        });
    },
    checkKey() {
      if (this.key == "dynamic") {
        this.infoTitle = "最新动态";
      } else if (this.key == "operate") {
        this.infoTitle = "运营干货";
      } else if (this.key == "information") {
        this.infoTitle = "行业资讯";
      }
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.getInfoList();
    },
    toDetail(item, key) {
      axios.post("/articles/" + item.id + "/browse").catch((error) => {
        alert(error);
      });
      if (item.article_url) {
        window.open(item.article_url);
        return false;
      }
      this.$router.push({
        path: "/infoDetail",
        query: { id: item.id, key: key },
      });
    },
    getCurrent(index, key) {
      this.current = index;
      this.pageIndex = 1;
      this.key = key;
      this.checkKey();
      this.getInfoList();
    },
    toSearch() {
      axios
        .get("/articles", {
          params: {
            limit: 10,
            page: 1,
            key: this.key,
            keyword: this.search_value,
          },
        })
        .then((res) => {
          this.searchList = res.data.data.list;
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
  <style scoped>
.el-banner-items {
  margin: auto;
  background: url("../../../assets/image/about_us_3.jpg");
  background-size: cover;
  background-position: center;
  height: 320px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 320px;
  background-color: rgb(0, 0, 0, 0.2);
}
.el-banner-content {
  width: 50%;
  height: 320px;
  margin: auto 0;
  text-align: left;
  color: #fff;
  animation-name: slide-in-right;
  animation-duration: 1.2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;

  /* shorthand
          animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
}
.banner-span {
  margin: 30px 0;
  font-size: 14px;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    40deg,
    rgb(45, 181, 255) 10%,
    rgb(72, 207, 225) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #6fb4f9;
}
.section-card-box {
  padding: 60px 0 100px 0;
}
.section-content {
  margin: 30px 0;
}
.list-box {
  width: 60%;
}
.list-box li {
  padding: 20px 10px;
  border-bottom: 1px solid #e8e7e7;
}
.list-box li:hover {
  box-shadow: 0px 5px 15px 0px #118bc349;
}
.list-box li img {
  width: 26%;
  height: 90px;
}
.list-box .li-msg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 73%;
  text-align: left;
  color: #666;
  font-size: 14px;
}
.list-box h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #333;
}
.list-box p {
  margin-bottom: 10px;
}
.search-box {
  width: 30%;
}
.search-box ul {
  margin: 20px 0;
}
.search-box ul li {
  margin-bottom: 20px;
  color: #46b4f9;
  text-align: left;
  font-size: 14px;
  list-style-type: disc;
  cursor: pointer;
}
.search-box ul li:hover {
  color: #21aaff;
}
[class^="el-icon-"] {
  margin-right: 4px;
}
@media only screen and (max-width: 800px) {
  .el-banner-items {
    margin: auto;
    background: url("../../../assets/image/about_us_3.jpg");
    background-size: cover;
    background-position: center;
    height: 520px;
    width: 100%;
  }
  .banner-items {
    width: 100%;
    height: 520px;
    background-color: rgb(0, 0, 0, 0.2);
  }
  .el-banner-content {
    width: 100%;
    height: 220px;
    padding: 10px;
    margin: auto 0;
    text-align: left;
    color: #fff;
    animation-name: slide-in-right;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;

    /* shorthand
          animation: slide-in-right 1.2s ease-out 0s 1 normal none;*/
  }
  .banner-span {
    margin: 30px 0;
    font-size: 14px;
  }
  .section-card-box {
    padding: 30px 0;
  }
  .section-content {
    margin: 30px 0;
  }
  .list-box {
    width: 100%;
  }
  .list-box li {
    padding: 10px 10px;
    border-bottom: 1px solid #e8e7e7;
  }
  .list-box li:hover {
    box-shadow: 0px 5px 15px 0px #118bc349;
  }
  .list-box li img {
    height: 60px;
    width: 26%;
  }
  .list-box .li-msg {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 72%;
    text-align: left;
    color: #666;
    font-size: 12px;
  }
  .list-box h2 {
    font-size: 14px;
    font-weight: 400;
    color: #333;
  }
  .list-box p {
    margin-bottom: 5px;
    font-size: 12px;
  }
  .search-box ul {
    margin: 20px 0;
  }
  .search-box ul li {
    margin-bottom: 20px;
    color: #46b4f9;
    text-align: left;
    list-style-type: disc;
  }
  :deep(.el-pagination.is-background .el-pager li) {
    margin: 0 1px;
  }
  :deep(.el-pagination.is-background .el-pager li) {
    min-width: 25px;
  }
}
</style>