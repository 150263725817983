/**
 * 数据处理
 * 
 * @author foxonilne.
 **/
const utils = {
	//去空格
	trim: function (value) {
		return value.replace(/(^\s*)|(\s*$)/g, "");
	},
	//内容替换
	replaceAll: function (text, repstr, newstr) {
		return text.replace(new RegExp(repstr, "gm"), newstr);
	},
	isMobileNumber: function (value) {
		//是否为手机号
		return /^(?:13\d|14\d|15\d|16\d|17\d|18\d|19\d)\d{5}(\d{3}|\*{3})$/.test(value);
	},
	//格式化手机号码
	formatNumber: function (num) {
		return num.length === 11 ? num.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2') : num;
	},
	rgbToHex: function (r, g, b) {
		return "#" + utils.toHex(r) + utils.toHex(g) + utils.toHex(b)
	},
	toHex: function (n) {
		n = parseInt(n, 10);
		if (isNaN(n)) return "00";
		n = Math.max(0, Math.min(n, 255));
		return "0123456789ABCDEF".charAt((n - n % 16) / 16) +
			"0123456789ABCDEF".charAt(n % 16);
	},
	hexToRgb(hex) {
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	},
	processDate(value) {
		var date1 = value;
		var date2 = new Date();
		var date3 = date2.getTime() - new Date(date1).getTime();
		var days = Math.floor(date3 / (24 * 3600 * 1000))
		var leave1 = date3 % (24 * 3600 * 1000)
		var hours = Math.floor(leave1 / (3600 * 1000))
		var leave2 = leave1 % (3600 * 1000)
		var minutes = Math.floor(leave2 / (60 * 1000))

		let title = ''
		if (days <= 0 && hours <= 0 && minutes < 60) {
			title = minutes + '分钟前'
		} else if (days <= 0 && hours < 24) {
			title = hours + '小时前'
		} else if (days > 1 && days < 30) {
			title = days + '天前'
		} else {
			title = value
		}
		return title;
	},
	copyText(text) {
		let inputDom = document.createElement("input"); // 创建一个input元素
		inputDom.setAttribute("readonly", "readonly"); // 防止手机上弹出软键盘
		inputDom.value = text; // 给input元素赋值
		document.body.appendChild(inputDom); // 添加到body
		inputDom.select(); //选中input元素的内容
		document.execCommand("Copy"); // 执行浏览器复制命令
		inputDom.style.display = "none";
		inputDom.remove(); // 移除input元素
	},
}

export default {
	trim: utils.trim,
	replaceAll: utils.replaceAll,
	formatNumber: utils.formatNumber,
	isMobileNumber: utils.isMobileNumber,
	copyText: utils.copyText
}
