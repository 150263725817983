<template>
  <div>
    <Header :num="1" :style="{ display: navTop ? 'none' : 'block' }" />
    <div
      class="el-banner-card"
      ref="scrollHeader"
      :style="{ display: navTop ? 'none' : 'block' }"
    >
      <div class="el-content-width">
        <div class="banner-items">
          <h1 class="el-font-colorfff">同城招聘系统</h1>
          <p>
            企业系统企业系统企业系统企业系统企业系统企业系统企业系统企业系统
          </p>
          <div class="banner-button el-flex">
            <div class="banner-button-1">前往体验</div>
            <div class="banner-button-2">立即联系</div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 40px" v-if="navTop"></div>
    <!-- 导航start -->
    <div class="el-nav-box" :class="navTop ? 'nav-top' : ''" ref="scorllNav">
      <div class="el-content-width el-case-nav">
        <ul class="el-flex-between">
          <li
            class="el-flex-column"
            v-for="(item, index) in navList"
            :key="index"
            @click="getCurrent(index)"
          >
            <span :class="current == index ? 'nav-color' : ''">{{ item }}</span>
            <div :class="current == index ? 'nav-active' : 'nav-bar'"></div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 导航结束 -->
    <sectionOne
      :isShow="one_show"
      ref="scrollOne"
      :content="oneData"
    ></sectionOne>
    <sectionTwo
      :content="twoData"
      :show="two_show"
      ref="scrollTwo"
    ></sectionTwo>
    <sectionThree :content="threeData" :isShow="three_show" ref="scrollThree">
    </sectionThree>
    <sectionFour
      :content="fourData"
      :show="four_show"
      ref="scrollFour"
    ></sectionFour>
    <sectionFive
      :content="fiveData"
      :show="three_show"
      ref="scrollFive"
    ></sectionFive>
    <sectionSix
      :content="sixData"
      :show="three_show"
      ref="scrollSix"
    ></sectionSix>
    <sectionSeven
      :content="sevenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionSeven>
    <sectionEight
      :show="three_show"
      ref="scrollSeven"
      :content="eightData"
    ></sectionEight>
    <sectionNine
      :content="nineData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionNine>
    <sectionTen
      :content="tenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionTen>
    <sectionEleven
      :content="elevenData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionEleven>
    <sectionFooter
      :content="footerData"
      :show="three_show"
      ref="scrollSeven"
    ></sectionFooter>
    <Cnav></Cnav>
    <Footer></Footer>
  </div>
</template>
    <script>
import Cnav from "@/components/SideNav/SideNav.vue";
import Footer from "@/components/LayoutFooter/LayoutFooter.vue";
import Header from "@/components/LayoutHeader/LayoutHeader.vue";
import sectionOne from "@/components/productSection/sectionOne.vue";
import sectionTwo from "@/components/productSection/sectionTwo.vue";
import sectionThree from "@/components/productSection/sectionThree.vue";
import sectionFour from "@/components/productSection/sectionFour.vue";
import sectionFive from "@/components/productSection/sectionFive.vue";
import sectionSix from "@/components/productSection/sectionSix.vue";
import sectionSeven from "@/components/productSection/sectionSeven.vue";
import sectionEight from "@/components/productSection/sectionEight.vue";
import sectionNine from "@/components/productSection/sectionNine.vue";
import sectionTen from "@/components/productSection/sectionTen.vue";
import sectionEleven from "@/components/productSection/sectionEleven.vue";
import sectionFooter from "@/components/productSection/sectionFooter.vue";

export default {
  name: "HelloWorld",
  components: {
    Header,
    Cnav,
    Footer,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
    sectionNine,
    sectionTen,
    sectionEleven,
    sectionFooter,
  },
  data() {
    return {
      navList: ["系统介绍", "系统特色", "功能展示", "服务群体", "相关文档"],
      current: 0,
      one_show: false,
      two_show: false,
      three_show: false,
      four_show: false,
      navTop: false,
      oneData: {
        title: "板块1",
        title_two: "板块1",
        desc: "板块1板块1板块1板块1",
        banner: require("../../../assets/image/test_section_1.png"),
        fontColor: "#ffab0e",
        borderColor: "#ffab0e",
        list: [
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
          { name: "list-title", desc: "list-desc" },
        ],
      },
      twoData: {
        title: "板块2",
        title_two: "板块2",
        desc: "板块2",
        imageBgColor: "#e3fdff",
        list: [
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/special_2.png"),
            name: "功能名称",
            desc: "描述",
          },
        ],
      },
      threeData: {
        title: "板块3",
        title_two: "板块3",
        desc: "板块3板块3板块3板块3板块3板块3",
        list: [
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "标题",
            desc: "描述",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "标题",
            desc: "描述",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "标题",
            desc: "描述",
          },
          {
            img: require("../../../assets/image/test_section1_1.png"),
            name: "标题",
            desc: "描述",
          },
        ],
      },
      fourData: {
        title: "板块4",
        title_two: "板块4",
        img: require("../../../assets/image/test_bg_1.png"),
        desc: "板块4板块4板块4板块4板块4",
        list: [
          { name: "功能", desc: "描述" },
          { name: "功能", desc: "描述" },
          { name: "功能", desc: "描述" },
          { name: "功能", desc: "描述" },
        ],
      },
      fiveData: {
        title: "板块5",
        title_two: "板块5",
        img: require("../../../assets/image/store_fresh_img_1.png"),
        list_one: [
          {
            name: "标题",
            desc: "描述",
            fontColor: "#4a7ee6",
            bgColor: "#e9effb",
          },
          {
            name: "标题",
            desc: "描述",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "标题",
            desc: "描述",
            fontColor: "#fe791e",
            bgColor: "#fbefe6",
          },
        ],
        list_two: [
          {
            name: "标题",
            desc: "描述",
            fontColor: "#39cf8e",
            bgColor: "#e6f7f1",
          },
          {
            name: "标题",
            desc: "描述",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
          {
            name: "标题",
            desc: "描述",
            fontColor: "#f6bb09",
            bgColor: "#faf5e4",
          },
        ],
      },
      sixData: {
        title: "板块6",
        desc: "板块6板块6板块6板块6板块6板块6板块6板块6",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/store_fresh_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/store_fresh_img_1.png"),
            name: "功能一",
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能一",
          },
        ],
      },
      sevenData: {
        title: "板块7",
        title_two: "板块7",
        desc: "板块7",
        list_one: [
          {
            name: "标题",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_1.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
          {
            name: "标题",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_2.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
        ],
        list_two: [
          {
            name: "标题",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_3.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
          {
            name: "标题",
            icon: require("../../../assets/icon/product_icon/store_vip_icon_4.png"),
            desc: "描述描述描述描述描述描述描述描述",
          },
        ],
      },
      eightData: {
        title: "板块8",
        title_two: "板块8",
        desc: "板块8",
        functionList: [
          {
            name: "用户首页",
            icon: require("../../../assets/icon/product_icon/store_icon_1.png"),
            img: require("../../../assets/image/product/storeVipcard/fun_1.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_2.png"),
            img: require("../../../assets/icon/product_icon/store_icon_2.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_3.png"),
            img: require("../../../assets/icon/product_icon/store_icon_3.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_4.png"),
            img: require("../../../assets/icon/product_icon/store_icon_4.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_5.png"),
            img: require("../../../assets/icon/product_icon/store_icon_5.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_6.png"),
            img: require("../../../assets/icon/product_icon/store_icon_6.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_7.png"),
            img: require("../../../assets/icon/product_icon/store_icon_6.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_8.png"),
            img: require("../../../assets/icon/product_icon/store_icon_6.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_9.png"),
            img: require("../../../assets/icon/product_icon/store_icon_9.png"),
          },
          {
            name: "客服售后",
            icon: require("../../../assets/icon/product_icon/store_icon_10.png"),
            img: require("../../../assets/icon/product_icon/store_icon_10.png"),
          },
        ],
      },
      nineData: {
        title: "板块9",
        title_two: "板块9",
        desc: "板块9板块9板块9板块9板块9",
        list: [
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "标题",
            desc: "描述",
          },
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "标题",
            desc: "描述",
          },
          {
            img: require("../../../assets/image/test_bg_2.png"),
            name: "标题",
            desc: "描述",
          },
        ],
      },
      tenData: {
        title: "板块10",
        title_two: "板块10",
        title_center: "外卖跑腿",
        desc: "板块10",
        bgColor_1: "#ffc6fa",
        bgColor_2: "#ff9bf8",
        bgColor_3: "#ff7ae7",
        list_one: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "标题",
            desc: "描述描述描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "标题",
            desc: "描述描述描述",
          },
        ],
        list_two: [
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "标题",
            desc: "描述描述描述",
          },
          {
            icon: require("../../../assets/icon/product_icon/waimai_icon_1.png"),
            iconBgcolor: "#ffd9c6",
            name: "标题",
            desc: "描述描述描述",
          },
        ],
      },
      elevenData: {
        title: "板块11",
        title_two: "板块11-1",
        title_three: "板块11—3",
        desc: "板块11desc",
        desc_two: "板块11——desctwo",
        list: [
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_1.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_2.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_3.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_4.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_5.png"),
          },
          {
            img: require("../../../assets/image/test_pro_img_1.png"),
            name: "功能",
            iconBgColor: "#fffce3",
            icon: require("../../../assets/icon/product_icon/waimai_user_6.png"),
          },
        ],
      },
      footerData: {
        bgColor: "#ff644d",
        hrefList: [
          {
            name: "管理后台地址：",
            href: "https://v2.cn.vuejs.org/v2/guide/index.html",
          },
          {
            name: "管理后台地址：",
            href: "https://v2.cn.vuejs.org/v2/guide/index.html",
          },
        ],
        codeList: [
          {
            name: "标题",
            img: require("../../../assets/image/test_code.png"),
          },
        ],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    var obj = {
      title: "灵狐网络科技—专注本地生活服务平台打造—同城系统", //分享标题
      desc: "", //分享内容
      linkurl: location.href, //分享链接
      img: "", //分享内容显示的图片
    };
    this.wxShare(obj);
  },
  methods: {
    getCurrent(index) {
      this.current = index;
      let headerHeight = this.$refs.scrollHeader.offsetHeight;
      let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
      let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
      let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
      let fourHeight = this.$refs.scrollThree.$el.clientHeight + threeHeight;
      console.log(this.$refs.scrollOne.$el.clientHeight);
      if (index == 0) {
        document.documentElement.scrollTop = headerHeight;
        document.body.scrollTop = headerHeight;
      } else if (index == 1) {
        document.documentElement.scrollTop = oneHeight;
        document.body.scrollTop = oneHeight;
      } else if (index == 2) {
        document.documentElement.scrollTop = twoHeight;
        document.body.scrollTop = twoHeight;
      } else if (index == 3) {
        document.documentElement.scrollTop = threeHeight;
        document.body.scrollTop = threeHeight;
      } else if (index == 4) {
        document.documentElement.scrollTop = fourHeight;
        document.body.scrollTop = fourHeight;
      }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.scrollHeader) {
        let headerHeight = this.$refs.scrollHeader.offsetHeight;
        let oneHeight = this.$refs.scrollOne.$el.clientHeight + headerHeight;
        let twoHeight = this.$refs.scrollTwo.$el.clientHeight + oneHeight;
        let threeHeight = this.$refs.scrollThree.$el.clientHeight + twoHeight;
        let fourHeight = this.$refs.scrollFour.$el.clientHeight + threeHeight;
        if (scrollTop > headerHeight) {
          this.navTop = true;
        }
        if (scrollTop == 0) {
          this.navTop = false;
        }
        if (scrollTop > oneHeight) {
          this.one_show = true;
        }
        if (scrollTop > twoHeight) {
          this.two_show = true;
        }
        if (scrollTop > threeHeight) {
          this.three_show = true;
        }
        if (scrollTop > fourHeight) {
          this.four_show = true;
        }
      }
    },
    destroyed() {
      document.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>
    <style scoped>
.el-banner-card {
  margin: auto;
  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  background-size: cover;
  background-position: center;
  height: 420px;
  width: 100%;
}
.banner-items {
  width: 100%;
  height: 420px;
  padding: 70px 0;
  text-align: left;
}
.banner-items p {
  color: #fff;
  margin: 20px 0;
}
.banner-button {
  margin: 70px 0;
}
.banner-button-1 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
}
.banner-button-2 {
  padding: 16px 40px;
  background-color: #fff;
  border: solid 1px #ffcb6b;
  color: #ffab0e;
  margin-left: 20px;
}
.el-nav-box {
  width: 100%;
  background-color: #fff;
}
.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0px 5px 15px 0px #c1ae7d1a;
}
.el-case-nav {
  padding: 15px 0;
  color: #777777;
  background-color: #fff;
}
.nav-bar {
  width: 100%;
  height: 3px;
  margin-top: 10px;
}
.nav-active {
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    rgb(255, 163, 58) 0%,
    rgb(227, 221, 38) 100%
  );
  margin: 0 auto;
  margin-top: 10px;
}
.nav-color {
  color: #ffab0e;
}
</style>